import { ILogger } from "./ILogger"
import { isLogLevel, LogLevel } from "./LogLevel"

export class ConsoleLogger implements ILogger {
    name = "Default"
    level: LogLevel = 1 // information

    constructor(name: string) {
        if (name != null && name !== "") {
            this.name = name
        }

        const envLogLevel = process.env["NX_LOG_LEVEL"]
        if (isLogLevel(envLogLevel)) {
            this.level = Number(envLogLevel) as LogLevel
        }
    }

    logTrace(message: string, data: any): void {
        if (this.level <= 0) {
            console.trace(this.constructMessage(message), data)
        }
    }
    logInformation(message: string, data: any): void {
        if (this.level <= 1) {
            console.info(this.constructMessage(message), data)
        }
    }
    logDebug(message: string, data: any): void {
        if (this.level <= 2) {
            console.debug(this.constructMessage(message), data)
        }
    }
    logError(message: string, data: any): void {
        if (this.level <= 3) {
            console.error(
                this.constructMessage(
                    message,
                    data && data.response && data.response?.headers ? data.response?.headers["x-trace-id"] : "-"
                ),
                data
            )
        }
    }
    logCritical(message: string, data: any): void {
        if (this.level <= 4) {
            console.error(this.constructMessage(message), data)
        }
    }

    protected constructMessage(message: string, traceID?: string): string {
        return this.name + ": " + `${message}. Trace-id: ${traceID || "-"}`
    }
}

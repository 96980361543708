import { IRegisterRunner, TResponse } from "@dnr/data/models"
import { IRegisterDealer } from "@dnr/data/models"

import { ServiceBase } from "../common-services/ServiceBase"

class RegisterService extends ServiceBase {
    getLoggerName(): string {
        return "RegisterService"
    }

    register(data: IRegisterRunner | IRegisterDealer): Promise<TResponse> {
        return this.http.instance.post("/account/register", data)
    }
}
export default new RegisterService()

import clsx from "clsx"
import React, { useEffect, useMemo, useRef } from "react"
import { RightAsideStore } from "./store"
import { observer } from "mobx-react"

interface RightAsideProps {
    children: React.ReactNode
    title: string
    size?: "base" | "lrg" | "xlrg" | "xxlrg" | "huge"
    isOpenByDefault?: boolean
}

export const RightAside: React.FC<RightAsideProps> = observer((props) => {
    const asideStore = useMemo(() => new RightAsideStore(), [])
    const wrapperRef = useRef(null)

    const useOutsideClick = (ref: any) => {
        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    asideStore.handleAside(true)
                }
            }

            document.addEventListener("mousedown", handleClickOutside)
            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        }, [ref])
    }

    useEffect(() => {
        if (props.isOpenByDefault) asideStore.asideIsOpen = true
    }, [])

    useOutsideClick(wrapperRef)

    return (
        <div
            className={clsx(
                "c-aside c-aside--right",
                props.size ? `c-aside--right--${props.size}` : "c-aside--right--base",
                asideStore.asideIsOpen ? "asideIsOpen" : "asideIsClosed"
            )}
            ref={wrapperRef}
        >
            {asideStore.asideIsOpen && (
                <div className="c-aside__content">
                    <p className="u-type--title u-mb--sml--5">{props.title}</p>
                    {props.children}
                </div>
            )}

            <button
                className="c-btn c-btn--sml c-btn--collapse"
                onClick={() => asideStore.handleAside(asideStore.asideIsOpen)}
            >
                <i
                    className={clsx(
                        "u-icon u-icon--sml u-icon--arrow-next--white",
                        !asideStore.asideIsOpen ? "u-rotate--180" : ""
                    )}
                ></i>
            </button>
        </div>
    )
})

import { createInstance } from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { ILocalizationOptions } from "./models"

const defaultOptions: ILocalizationOptions = {
    fallbackLanguage: "en",
}

export function createLocalization(options?: ILocalizationOptions) {
    options = Object.assign(defaultOptions, options)

    const i18n = createInstance({
        ns: [
            // TODO: add namespaces
            "common",
            "main",
            "form",
            "error",
        ],
        defaultNS: "common",
        load: "currentOnly",
        preload: [options.fallbackLanguage],
        fallbackLng: options.fallbackLanguage,
        backend: {
            // NOTE: this must be set up in the project.json file of your app otherwise localization won't work. See project readme for details
            loadPath: "/assets/resources/{{lng}}/{{ns}}.json",
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],
            lookupLocalStorage: "culture",
            lookupCookie: "culture",
            caches: ["localStorage", "cookie"],
        },
    })

    i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init()

    return i18n
}

import React, { useEffect, useState } from "react"

import { useRequest } from "@dnr/hooks"
import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { useTranslate } from "@dnr/localization"
import { productsBrandService } from "@dnr/data/services"
import { IFilter } from "@dnr/data/models"

export interface ProductBrandsProps {
    selectedValue?: number
    onUpdate: (selected: number) => void
    onReset?: () => void
    placeholder?: string
    filter?: IFilter
    error?: string
    reset?: number
    size?: string
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
    label?: string
}

export const ProductBrandsDropdown = (props: ProductBrandsProps) => {
    const { t } = useTranslate()

    const [productBrandsOptions, setProductBrandsOptions] = useState<DropdownOption[]>([])
    const [selectedDisplayText, setSelectedDisplayText] = useState<string>(t.common("PRODUCT.SELECT_BRAND"))

    useEffect(() => {
        if (props.placeholder) {
            setSelectedDisplayText(props.placeholder!)
        }
    }, [props.placeholder])

    const { loading } = useRequest(async (options) => {
        const { result: brands } = await productsBrandService.getAllCached(
            {
                pageNumber: 1,
                pageSize: 1000,
            },
            {
                abortController: options.abortController,
            }
        )

        if (brands?.items != null && brands.items.length > 0) {
            const brandOptions = brands.items
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((m) => {
                    return {
                        key: m.brandId.toString(),
                        value: m.displayName || m.name,
                        displayText: m.displayName || m.name,
                    } as DropdownOption
                })
            setProductBrandsOptions(brandOptions)
            if (props.selectedValue !== undefined) {
                const selectedOption = brandOptions.filter((f) => f.key === props.selectedValue?.toString())
                if (selectedOption != null) {
                    setSelectedDisplayText(selectedOption[0].displayText)
                }
            }
        }
    }, [])

    return loading ? (
        <div className="c-loader">Loading...</div>
    ) : (
        <>
            {props.error ? (
                <div className="c-input__error u-mb--sml--1 u-mt--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__error__message">{props.error}</span>
                </div>
            ) : null}
            <Dropdown
                label={props.label}
                resetStates={props.reset}
                iserror={props.error ? true : false}
                id="select-brands"
                placeholder={selectedDisplayText}
                selectedValue={props.selectedValue?.toString()}
                onChange={(option) => props.onUpdate(Number(option.key))}
                options={productBrandsOptions}
                size={props.size}
                width={props.width}
                env={props.env}
                fullWidth={props.fullWidth}
                isFilter={props.isFilter}
                resetFilter={props.onReset}
            />
        </>
    )
}

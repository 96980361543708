/* eslint-disable @typescript-eslint/no-unused-vars */
import { ILogger } from "./ILogger"

export class VoidLogger implements ILogger {
    logTrace<T>(message: string, data: T): void {
        return
    }
    logInformation<T>(message: string, data: T): void {
        return
    }
    logDebug<T>(message: string, data: T): void {
        return
    }
    logError<T>(message: string, data: T): void {
        return
    }
    logCritical<T>(message: string, data: T): void {
        return
    }
}

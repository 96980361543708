import { TokenFlow } from "@dnr/data/models"

import { ServiceBase } from "../common-services/ServiceBase"

class TokenFlowService extends ServiceBase {
    getLoggerName(): string {
        return "TokenFlowService"
    }

    async refreshToken(data: TokenFlow): Promise<TokenFlow> {
        const res = await this.http.instance.post("account/refresh-token", data)
        return res.data
    }
}

export default new TokenFlowService({ skipRequestInterceptor: true })

import React, { useMemo } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

interface TopicStatusDropdownProps {
    placeholder: string
    selectedValue?: string
    category?: string
    onChange: (selected: DropdownOption) => void
    label?: string
    reset?: number
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    onReset?: () => void
    isFilter?: boolean
}

export const TopicStatusDropdown = (props: TopicStatusDropdownProps) => {
    const { t } = useTranslate()
    const topicStatus = lookupService.getTopicStatusId()
    const topicStatusId = (abrv: string) => lookupService.topicStatusIds[abrv].id

    const topicCategoryOptions = useMemo(() => {
        const lookupEntries = [] as DropdownOption[]
        for (const key in topicStatus) {
            lookupEntries.push({
                displayText: topicStatus[key].display,
                key: topicStatus[key].id.toString(),
                value: topicStatus[key].id.toString(),
                icon: topicStatus[key].icon,
            })
        }
        lookupEntries.splice(2, 0, {
            displayText: t.common("TICKETS.NEW_PROCESSING_STATUS"),
            key: `${topicStatusId("processing")},${topicStatusId("new")}`,
            value: "newAndProcessing",
        })
        return lookupEntries
    }, [])

    return (
        <div className={`${props.env == "backoffice" ? "u-display--flex-col-fill" : ""}`}>
            {props.label != null ? (
                <div className="u-mb--sml--1">
                    <label className="c-input__label">{t.form(props.label)}</label>
                </div>
            ) : null}
            <Dropdown
                resetStates={props.reset}
                selectedValue={props.selectedValue}
                id="topic-category"
                type="select"
                size="base"
                width={props.width}
                placeholder={props.placeholder}
                onChange={(option) => props.onChange(option)}
                options={topicCategoryOptions}
                fullWidth={props.fullWidth}
                isFilter={props.isFilter}
                resetFilter={props.onReset}
            />
        </div>
    )
}

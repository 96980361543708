import React, { useState, useCallback } from "react"
import LoaderWrapper from "./LoaderWrapper"
import ListTitleLoader from "./ListTitleLoader"

export const BalanceOverviewLoader = ({ className, removeTitle }: { className?: string; removeTitle?: boolean }) => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div ref={ref} className={className ? className : "c-section--balance"}>
            {!removeTitle && <ListTitleLoader loaderWidth={loaderWidth} />}

            <LoaderWrapper height={84} width={loaderWidth}>
                <rect x="0" y="0" rx="6" ry="6" width="189" height="84" />
                <rect x="200" y="0" rx="6" ry="6" width="340" height="84" />
            </LoaderWrapper>
        </div>
    )
}

export default BalanceOverviewLoader

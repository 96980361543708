const logLevels = [
    // trace
    0,
    // information
    1,
    // debug
    2,
    // error
    3,
    // critical
    4,
] as const
export type LogLevel = (typeof logLevels)[number]

export function isLogLevel(value?: string): LogLevel {
    const v = logLevels.find((valid) => valid === Number(value))

    if (v != null) {
        return v
    }

    throw new Error(`Invalid log level ${value}. Possible values are: ${logLevels.join(",")}`)
}

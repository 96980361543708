import clsx from "clsx"
import React from "react"

import { BaseButton, BaseButtonProps } from "./BaseButton"

export type OutlineButtonProps = {} & Omit<BaseButtonProps, "className">

export const OutlineButton: React.FC<OutlineButtonProps> = (props) => (
    <BaseButton {...props} className={clsx("outline")} />
)

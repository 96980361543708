import { useTranslate } from "@dnr/localization"

interface BasicBannerProps {
    closeBannerHandler?: () => void
    type?: string
    text: string | JSX.Element
    margin?: string
    textMargin?: string
    title?: string
}

export const BasicBanner: React.FC<BasicBannerProps> = ({
    closeBannerHandler,
    type,
    text,
    title,
    margin,
    textMargin,
}) => {
    const { t } = useTranslate()

    return (
        <div className={`c-banner c-banner--${type} ${margin ? `u-mb--sml--${margin}` : ""}`}>
            <div className="u-display--flex u-display--flex--gap--base u-display--flex--ai--center">
                {type == "warning" ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        width={"16px"}
                        height={"16px"}
                        viewBox="0 0 16 16"
                    >
                        <g
                            stroke="#e4690c"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            clip-path="url(#a)"
                        >
                            <path d="M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334ZM8 5.333V8M8 10.667h.007" />
                        </g>
                        <defs>
                            <clipPath id="a">
                                <path fill="#fff" d="M0 0h16v16H0z" />
                            </clipPath>
                        </defs>
                    </svg>
                ) : null}

                {type == "info" ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        width={"16px"}
                        height={"16px"}
                        viewBox="0 0 16 16"
                    >
                        <g
                            stroke="#326BFF"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            clip-path="url(#a)"
                        >
                            <path d="M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334ZM8 10.667V8M8 5.333h.006" />
                        </g>
                        <defs>
                            <clipPath id="a">
                                <path fill="#fff" d="M0 0h16v16H0z" />
                            </clipPath>
                        </defs>
                    </svg>
                ) : null}

                {title ? (
                    <div className="c-banner__container">
                        {" "}
                        <p className="c-banner__title u-display--flex--fill u-mr--sml--10">{title} </p>
                        <p
                            className={`c-banner__message u-display--flex--fill ${
                                textMargin ? `u-mr--sml--${textMargin}` : "u-mr--sml--10"
                            }`}
                        >
                            {text}{" "}
                        </p>
                    </div>
                ) : (
                    <p
                        className={`c-banner__message u-display--flex--fill ${
                            textMargin ? `u-mr--sml--${textMargin}` : "u-mr--sml--10"
                        }`}
                    >
                        {text}{" "}
                    </p>
                )}
            </div>
            {/* <a onClick={closeBannerHandler} className="c-banner__dismiss">
                {t.common("GENERAL.DISMISS")}{" "}
            </a> */}
            {closeBannerHandler != null ? (
                <div className="c-btn c-btn--close c-btn--close--secondary" onClick={closeBannerHandler}>
                    <i className="u-icon u-icon--base u-icon--close"></i>
                </div>
            ) : null}
        </div>
    )
}

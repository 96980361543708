import clsx from "clsx"
import React from "react"

import { BaseTag, BaseTagProps } from "./BaseTag"

export type IconTagProps = {
    iconType: string
    iconSize?: string
    tagtitle?: string
    tagtype?: string
    extendedClassName?: string
}

export const IconTag: React.FC<IconTagProps> = (props) => {
    return (
        <div className={`c-tag c-tag--icon c-tag--${props.tagtype} ${props.extendedClassName ? props.extendedClassName : null}`}>
            <i
                className={`u-icon u-icon--${props.iconSize ? props.iconSize : "sml"}
                u-icon--${props.iconType} u-mr--sml--1 u-mb--sml--05`}
            />
            {props.tagtitle}
        </div>
    )
}

import clsx from "clsx"
import React from "react"

export type BasePlaceholderProps = {
    type?: string
    size: number
    category?: "profile" | "other"
}

export const BasePlaceholder: React.FC<BasePlaceholderProps> = (props) => {
    return (
        <img
            src={clsx(`../../../../assets/img/placeholders/avatar-placeholder.svg`)}
            className={`u-mb--sml--2 c-empty--${props.size}`}
            width={props.size}
            height={props.size}
        />
    )
}

import React from "react"
import ContentLoader from "react-content-loader"

interface LoaderWrapperProps {
    children: React.ReactNode
    width: number
    height: number
    backgroundColor?: string
    foregroundColor?: string
}

const LoaderWrapper = ({ width, height, children, backgroundColor, foregroundColor }: LoaderWrapperProps) => {
    return (
        <ContentLoader
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            backgroundColor={backgroundColor ? backgroundColor : "#F5F9FF"}
            foregroundColor={foregroundColor ? foregroundColor : "#DBEAFE"}
            speed={2}
        >
            {children}
        </ContentLoader>
    )
}

export default LoaderWrapper

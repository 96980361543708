import { forwardRef, useCallback, useState } from "react"

import { SnackbarContent, CustomContentProps, useSnackbar } from "notistack"

import { BaseButton } from "@dnr/ui/buttons"

type Props = {
    details: string
    hideIcon?: boolean
    callback?: () => void
} & CustomContentProps

export const DetailedWarningNotification = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <SnackbarContent
            ref={ref}
            style={props.style}
            className="c-snackbar__container u-display--flex u-display--flex--fd--column"
        >
            <div className="notistack-snackbar">
                <div className="u-display--flex u-display--flex--gap--sml">
                    {" "}
                    {!props.hideIcon ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                            width={"16"}
                            height={"16"}
                            className="u-mt--sml--05"
                        >
                            <g id="warning-triangle--frame-alert-warning-triangle-exclamation-caution">
                                <path
                                    id="Subtract"
                                    fill="#fff"
                                    fill-rule="evenodd"
                                    d="M6.21769.226033c.23555-.1439857.50627-.22017362.78234-.22017362.27607 0 .54678.07618792.78233.22017362.23555.143986.42679.350183.55267.595886l.00223.004362-.00002.000011L13.8365 11.8249l.0004.0007c.1148.2281.1695.4819.1588.7371-.0107.2555-.0865.504-.2203.7219-.1338.2179-.321.398-.544.5232-.2229.1252-.4742.1913-.7299.1921l-.0015 0H1.50003l-.00152 0c-.25569-.0008-.506947-.0669-.729898-.1921-.22295-.1252-.410196-.3053-.543955-.5232-.1337584-.2179-.2095892-.4664-.22029075-.7219-.01068905-.2552.04395355-.5089.15874275-.737l.000404-.0008L5.66281.826292 5.665.821908l.00003.000011c.12587-.245703.31712-.4519.55266-.595886ZM7 4.125c.41421 0 .75.33579.75.75v3.25c0 .41421-.33579.75-.75.75s-.75-.33579-.75-.75v-3.25c0-.41421.33579-.75.75-.75Zm1 6.75c0 .5523-.44772 1-1 1s-1-.4477-1-1 .44772-1 1-1 1 .4477 1 1Z"
                                    clip-rule="evenodd"
                                ></path>
                            </g>
                        </svg>
                    ) : null}
                    <div>
                        <h3 className="c-snackbar__title c-snackbar--white">{props.message}</h3>
                        <div className="u-mt--sml--1 c-snackbar--white">{props.details}</div>

                        {props.callback ? (
                            <div className="u-display--flex u-display--flex--gap--xsml u-type--color--negative">
                                <div onClick={props.callback} className="c-anchor c-anchor--white u-width--unset">
                                    Click here
                                </div>
                                to see more.
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </SnackbarContent>
    )
})

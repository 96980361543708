import { ApiResponseBaseModel, ApiResponseModel, IPagedCollection, IRequestOptions, LookupEntry } from "@dnr/data/models"
import { ServiceBase, commonService, helperService } from "@dnr/data/services"
import { CreateTopic, Topic, UpdateTopic } from "../models"
import { TopicFilter } from "../models/TopicFilter"

import { CreateTopicMessage, TopicMessage, TopicMessageFilter, SendLastMessageToEmailDto } from "../models/TopicMessage"

export class TopicService extends ServiceBase {
    getLoggerName(): string {
        return "TopicService"
    }

    async getTopics(
        topicFilter: TopicFilter,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<Topic>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<Topic>>>("/topic", {
            signal: options?.abortController.signal,
            params: topicFilter,
        })
        return response.data
    }

    async getTopicById(id: string, options?: IRequestOptions): Promise<ApiResponseModel<Topic>> {
        const response = await this.http.instance.get<ApiResponseModel<Topic>>(`/topic/${id}`, {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async getLatestMessages(
        topicId: string,
        options?: IRequestOptions,
        filter?: TopicMessageFilter
    ): Promise<ApiResponseModel<TopicMessage[]>> {
        const response = await this.http.instance.get<ApiResponseModel<TopicMessage[]>>(`/topic-messages/${topicId}`, {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async sendNewMessage(data: FormData): Promise<ApiResponseModel<TopicMessage>> {
        const response = await this.http.instance.post<ApiResponseModel<TopicMessage>>("/topic-messages", data)
        return response.data
    }

    async sendLatestResponse(data: SendLastMessageToEmailDto): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.post<ApiResponseBaseModel>("/topic-messages/emails/send-last-response", data)
        return response.data
    }

    async createTopic(topicData: CreateTopic): Promise<ApiResponseModel<Topic>> {
        const response = await this.http.instance.post<ApiResponseModel<Topic>>("/topic", topicData)
        return response.data
    }

    async deleteTopic(topicIds: string[]): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.delete<ApiResponseBaseModel>("/topic", {
            data: topicIds,
        })
        return response.data
    }

    async updateTopic(topic: UpdateTopic): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.patch<ApiResponseBaseModel>("/topic", topic)
        return response.data
    }

    async downloadTicketsReport(filter: TopicFilter, type: LookupEntry, options?: IRequestOptions | null): Promise<void> {
        return commonService.generateReport(`/topic/report/${type.id}`, type, filter, options)
    }
}

export default new TopicService()

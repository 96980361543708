import {
    ApiResponseBaseModel,
    ApiResponseModel,
    IFilter,
    IPagedCollection,
    IRequestOptions,
    LatestDeal,
    LookupEntry,
} from "@dnr/data/models"
import { ServiceBase, commonService, helperService } from "@dnr/data/services"
import { DealFilter } from "../models"

import {
    CreateDeal,
    Deal,
    DealPayment,
    DealUpdate,
    DealUpdatePaymentCalculation,
    LatestDealsParams,
    MaxAllowedQty,
    SendDealEmailSingle,
    SendDealsEmail,
} from "../models/Deal"

export class BaseDealsService extends ServiceBase {
    getLoggerName(): string {
        return "DealsService"
    }

    async find(filter: DealFilter, options?: IRequestOptions): Promise<IPagedCollection<Deal>> {
        const response = await this.http.instance.get<IPagedCollection<Deal>>("/deals", {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async post(newDeal: CreateDeal): Promise<ApiResponseModel<Deal>> {
        const response = await this.http.instance.post<ApiResponseModel<Deal>>("/deals", newDeal)
        return response.data
    }

    async sendDealsEmail(data: SendDealsEmail): Promise<void> {
        const response = await this.http.instance.post<void>("/deals/send-deals-email", data)
        return response.data
    }

    async sendDealEmailSingle(data: SendDealEmailSingle): Promise<void> {
        const response = await this.http.instance.post<void>("/deals/send-single-deal-email", data)
        return response.data
    }

    async updateDraft(draftDeal: CreateDeal): Promise<ApiResponseModel<Deal>> {
        const response = await this.http.instance.put<ApiResponseModel<Deal>>(
            `/deals/update-draft/${draftDeal.dealId}`,
            draftDeal
        )
        return response.data
    }

    async calculatePayment(newDeal: CreateDeal): Promise<ApiResponseModel<DealPayment>> {
        const response = await this.http.instance.post<ApiResponseModel<DealPayment>>(
            "/deals/calculate-payment",
            newDeal
        )
        return response.data
    }

    async recalculatePayment(deal: DealUpdate): Promise<ApiResponseModel<DealUpdatePaymentCalculation>> {
        const response = await this.http.instance.post<ApiResponseModel<DealUpdatePaymentCalculation>>(
            "/deals/recalculate-payment",
            deal
        )
        return response.data
    }

    async updateOfferedPrice(dealId: string, offeredPrice: number): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.patch<ApiResponseBaseModel>("/deals/update-offered-price", {
            dealId: dealId,
            offeredPricePerItem: offeredPrice,
        })
        return response.data
    }

    async updateStatus(dealId: string, dealStatusId: number): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.patch<ApiResponseBaseModel>("/deals/update-deal-status", {
            dealId: dealId,
            dealStatusId: dealStatusId,
        })
        return response.data
    }

    async updateValidUntil(dealId: string, validUntil: string): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.patch<ApiResponseBaseModel>("/deals/update-valid-until", {
            dealId: dealId,
            validUntil: validUntil,
        })
        return response.data
    }

    async get(id: string, filter: IFilter, options?: IRequestOptions): Promise<Deal> {
        const response = await this.http.instance.get<Deal>(`/deals/${id}`, {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async getTopDeals(filter: DealFilter, numberOfItems: number, options: IRequestOptions): Promise<Deal[]> {
        const response = await this.http.instance.get<Deal[]>(`/deals/top-deals/${numberOfItems}`, {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async getLatestDeals(
        params: LatestDealsParams,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<LatestDeal[]>> {
        const response = await this.http.instance.get<ApiResponseModel<LatestDeal[]>>(`/deals/latest-deals`, {
            params: params,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async allowedCommitmentQty(runnerLvl: number, options: IRequestOptions): Promise<MaxAllowedQty> {
        const response = await this.http.instance.get<MaxAllowedQty>(
            `/deals/allowed-commitment-quantities/${runnerLvl}`,
            {
                signal: options?.abortController.signal,
            }
        )
        return response.data
    }

    async delete(ids: string[]): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.delete<ApiResponseBaseModel>("/deals", {
            data: ids,
        })
        return response.data
    }

    async update(dealId: string, updatedDeal: Deal): Promise<ApiResponseModel<Deal>> {
        const response = await this.http.instance.put<ApiResponseModel<Deal>>(`/deals/${dealId}`, updatedDeal)
        return response.data
    }

    async downloadDealsReport(filter: DealFilter, type: LookupEntry, options?: IRequestOptions | null): Promise<void> {
        return commonService.generateReport(
            `/deals/report/${type.id}`,
            type,
            filter,
            options
        )
    }
}

export default new BaseDealsService()

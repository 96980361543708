import { makeAutoObservable } from "mobx"

interface ModalDataParams<T> {
    type: string
    modalData?: T
    bodyText?: string | JSX.Element
    styledSubtitle?: string
    headerTitle?: string
    headerSubtitle?: string
    closingText?: string
    confirmText?: string
    modalContent?: JSX.Element
    onPromiseCallback?: {
        callback: (passingData?: any) => Promise<() => void>
        customErrMsg?: string
        customLogMsg?: string
    }
    callback?: (passingData?: any) => void
    onCloseCallback?: () => void
}

export class ModalStore {
    modalContent?: JSX.Element
    modalType?: string
    bodyText?: string | JSX.Element
    styledSubtitle?: string
    modalData?: any
    isOpened?: boolean
    headerTitle?: string
    headerSubtitle?: string
    closingText?: string
    confirmText?: string
    onPromiseCallback?: {
        callback: (passingData?: any) => Promise<() => void>
        customErrMsg?: string
        customLogMsg?: string
    }
    callback?: (passingData?: any) => void
    onCloseCallback?: () => void

    constructor() {
        makeAutoObservable(this)
        window.addEventListener("popstate", () => this.closeModal())
    }

    openModal<T>(data: ModalDataParams<T>) {
        this.modalContent = data.modalContent
        this.setModalData<T>(data)
        this.isOpened = true
    }

    setModalData<T>(data: ModalDataParams<T>) {
        this.bodyText = data.bodyText
        this.styledSubtitle = data.styledSubtitle
        this.modalType = data.type
        this.headerTitle = data.headerTitle
        this.headerSubtitle = data.headerSubtitle
        this.closingText = data.closingText
        this.modalData = data.modalData
        this.confirmText = data.confirmText
        this.callback = data.callback
        this.onPromiseCallback = data.onPromiseCallback
        this.onCloseCallback = data.onCloseCallback
    }

    closeModal() {
        if (this.onCloseCallback) this.onCloseCallback()
        this.isOpened = false

        this.isOpened = undefined
        this.modalType = undefined
        this.headerTitle = undefined
        this.headerSubtitle = undefined
        this.closingText = undefined
        this.confirmText = undefined
        this.callback = undefined
    }

    switchModalContent<T>(data: ModalDataParams<T>) {
        this.modalContent = data.modalContent
        this.setModalData<T>(data)
        this.isOpened = true
    }

    setTitle(text: string) {
        this.headerTitle = text
    }

    setSubtitle(text: string) {
        this.headerSubtitle = text
    }
}

import LoaderWrapper from "./LoaderWrapper"

const ListTitleLoader = ({ loaderWidth }: { loaderWidth: number }) => {
    return (
        <LoaderWrapper width={loaderWidth} height={36} backgroundColor="#b7c6e5">
            <rect x="0" y={0} rx="6" ry="6" width="165" height="18" />
            <rect x={loaderWidth - 130} y={2} rx="6" ry="6" width="100" height="18" />
            <rect x={loaderWidth - 20} y={0} rx="10" ry="10" width="20" height="20" />
        </LoaderWrapper>
    )
}

export default ListTitleLoader

import { enqueueSnackbar } from "notistack"

import { useTranslate } from "@dnr/localization"
import { rootModalStore } from "@dnr/state/modal"
import { DeleteButton, OutlineButton } from "@dnr/ui/buttons"
import { topicService } from "../services"

interface Props {
    displayText: string
    topicId: string
    numericalId: number
    size?: "base" | "xsml" | "sml" | "med" | "lrg"
    width?: "" | "full"
    disabled?: boolean
    setLastUpdated?: (shippingId: string) => void
}

export const TicketRemoveButton = (props: Props) => {
    const { t } = useTranslate()

    const openModal = () => {
        return rootModalStore.modalStore.openModal({
            type: "ConfirmModal",
            headerTitle: t.common("TICKETS.DELETE_SELECTED_TICKET"),
            headerSubtitle: `#${props.numericalId}`,
            closingText: t.common("MODAL.CANCEL"),
            confirmText: t.common("GENERAL.DELETE"),
            onPromiseCallback: {
                callback: removeTopicCallback,
            },
        })
    }

    const removeTopicCallback = async () => {
        await topicService.deleteTopic([props.topicId])
        enqueueSnackbar({
            message: t.common("TICKETS.TICKET_REMOVE_SUCCESS"),
            variant: "success",
        })
        return () => props.setLastUpdated && props.setLastUpdated(props.topicId)
    }

    return (
        <DeleteButton
            disabled={props.disabled}
            onClick={openModal}
            size={props.size}
            width={props.width}
            haslabel
            hasicon
            icontype="delete"
        >
            {props.displayText}
        </DeleteButton>
    )
}

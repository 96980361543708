import { AxiosResponse } from "axios"

import {
    ApiResponseBaseModel,
    IPagedCollection,
    IRequestOptions,
    IUser,
    LookupEntry,
    TCreatedUser,
    UserBasicInfo,
    UserFilter,
} from "@dnr/data/models"
import { ServiceBase, commonService } from "@dnr/data/services"

type levelId = {
    levelId: number
}
class UsersService extends ServiceBase {
    getLoggerName(): string {
        return "UsersService"
    }

    async find(filter: UserFilter, options?: IRequestOptions | null): Promise<IPagedCollection<IUser>> {
        const response = await this.http.instance.get<IPagedCollection<IUser>>("/users", {
            params: filter,
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async createUser(data: UserBasicInfo): Promise<AxiosResponse<TCreatedUser>> {
        const response = await this.http.instance.post("/users", data)
        return response
    }

    async updateUserLevel(userId: string, data: levelId): Promise<void> {
        await this.http.instance.put(`/users/${userId}/level`, data)
        return
    }

    async changeUserAccStatus(userId: string, lockOrUnlock: "lock" | "unlock"): Promise<void> {
        await this.http.instance.put(`/account/user/${userId}/${lockOrUnlock}`)
        return
    }

    async approveAccount(userId: string): Promise<void | ApiResponseBaseModel> {
        const response = await this.http.instance.put(`/account/${userId}/verify`)
        return response.data
    }

    async downloadUserReport(filter: UserFilter, type: LookupEntry, options?: IRequestOptions | null): Promise<void> {
        return commonService.generateReport(`/users/report/${type.id}`, type, filter, options)
    }
}

export default new UsersService()

import { DetailedHTMLProps, InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react"
import { DeepRequired, FieldErrorsImpl, FieldValues, Path, RegisterOptions, useFormContext } from "react-hook-form"

import { useTranslate } from "@dnr/localization"
import { useOutsideClick } from "@dnr/hooks"
import { OutlineButton } from "@dnr/ui/buttons"

export interface FormDropdownOption {
    value: string | number
    label: string
    key: string | number
    displayText: string
    icon?: string
    additionalData?: any
}

export type FormDropdownProps<TForm extends FieldValues> = {
    label: string
    reset?: boolean
    name: Path<TForm>
    errorMessage?: string
    registerOptions?: RegisterOptions<TForm>
    selectedValue?: string | number
    type?: string
    direction?: string
    inputSize?: string
    required?: boolean
    iserror?: boolean
    color?: string
    disabled?: boolean
    disabledMessage?: string
    inputType?: string
    options: FormDropdownOption[]
    onUpdate: (value: FormDropdownOption) => void
    isFilter?: boolean
} & DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

export const FormDropdown = <TForm extends FieldValues>(props: FormDropdownProps<TForm>) => {
    const {
        label,
        name,
        errorMessage,
        registerOptions,
        required,
        options,
        value,
        color,
        disabled,
        onChange,
        onUpdate,
        ...dropdownProps
    } = props
    const { t } = useTranslate()

    const form = useFormContext<TForm>()
    const field = form.register(name, registerOptions)

    const translateErrorMessage = (err?: string) => {
        // TODO: Implement fallback translation's here in case the translation was not found!
        if (err != null) {
            return t.error(err)
        }

        return err
    }
    const [isOpen, setIsOpen] = useState(false)
    const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false)
    const [selectedKey, setIsSelected] = useState<string | number | undefined>(props.selectedValue)
    const [displayText, setDisplayText] = useState<string>()
    const [displayIcon, setDisplayIcon] = useState(
        props.options?.find((item) => item.key === props.selectedValue)?.icon
    )

    const innerRef = useOutsideClick(() => {
        setIsOpen(false)
    })

    const handleChange = (element: FormDropdownOption) => {
        props.onUpdate(element)
        setIsOptionSelected(true)
        setIsSelected(element.key)
        setDisplayText(element.displayText)
        setDisplayIcon(element.icon ? element.icon : "")
    }

    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    const nameSplitted = useMemo(() => {
        return name.split(".")
    }, [name])

    const getErrorMessage = useCallback((errors: FieldErrorsImpl<DeepRequired<TForm>>) => {
        if (Object.keys(errors).length === 0) {
            return null
        }

        if (errors[name]?.message !== undefined) {
            return translateErrorMessage(errors[name]?.message?.toString())
        }
        // In case the error is in the array
        else if (nameSplitted.length > 1) {
            let finalErrorMessage
            let currentObject = undefined as any
            nameSplitted.every((nameSection) => {
                currentObject = currentObject === undefined ? errors[nameSection] : currentObject[nameSection]
                if (currentObject?.message !== undefined) {
                    finalErrorMessage = translateErrorMessage(currentObject?.message.toString())
                    return false
                }
                return true
            })

            if (finalErrorMessage !== undefined) {
                return finalErrorMessage
            }
        }

        return null
    }, [])

    useEffect(() => {
        if (selectedKey) {
            for (const item of props.options) {
                if (item.key === selectedKey) {
                    setDisplayText(item.displayText)
                    setIsOptionSelected(true)
                    break
                }
            }
        } else {
            setDisplayText(props.placeholder!)
        }
    }, [selectedKey, props.options, props.placeholder])

    useEffect(() => {
        if (props.reset) {
            setDisplayText(props.placeholder!)
            setIsSelected(props.selectedValue)
            setDisplayIcon(props.options?.find((item) => item.key === props.selectedValue)?.icon)
        }
    }, [props.reset])

    return (
        <div
            className={`${props.type == "default" ? "" : "u-mb--sml--3"} ${
                props.inputType === "fill" ? "u-display--flex--fill" : ""
            }`}
        >
            {label != null ? (
                <div className="u-mb--sml--1">
                    <label className="c-input__label">
                        {t.form(label)} {required ? <span className="u-type--color--error">*</span> : ""}
                    </label>
                </div>
            ) : null}

            {getErrorMessage(form.formState.errors || field) !== null ? (
                <div className="c-input__error u-mb--sml--1 u-mt--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__error__message">{getErrorMessage(form.formState.errors || field)}</span>
                </div>
            ) : null}

            {props.disabled ? (
                <div className="c-error--warning c-error--xsml u-mb--sml--1">
                    <i className="u-icon u-icon--base u-icon--error u-mr--sml--1"></i>
                    <span className="c-input__warning__message">{props.disabledMessage}</span>
                </div>
            ) : null}

            <div
                className={`c-input c-input--select 
                    ${props.inputSize ? `c-input--${props.inputSize}` : "c-input--base"}
                    ${props.color === "light" ? "c-input--select--light" : null}  
                    ${isOpen ? "isOpen" : ""} 
                    ${disabled ? "disabled" : ""} 
                    ${getErrorMessage(form.formState.errors) !== null ? "c-input--error" : ""}`}
                // @ts-ignore: allowed
                ref={innerRef}
                onClick={() => {
                    if (!props.disabled) {
                        handleIsOpen()
                    }
                }}
            >
                <div
                    className={`${
                        isOptionSelected
                            ? "c-input--select__text c-input--select__text--selected"
                            : "c-input--select__text"
                    }`}
                >
                    <>
                        {displayIcon ? <i className={`u-icon--stock-status u-icon u-icon--${displayIcon}`}></i> : null}{" "}
                        {displayText}
                    </>
                </div>
                <div className={`c-input--select__content ${isOpen ? "isOpen" : ""}`}>
                    <div className="c-input--select__list">
                        {options !== undefined && options.length > 0
                            ? props.options.map((item) => {
                                  return (
                                      <div
                                          className={`c-input--select__option ${
                                              (selectedKey || props.selectedValue) === item.key
                                                  ? "c-input--select__option--active"
                                                  : ""
                                          }`}
                                          key={item.key}
                                          onClick={() => handleChange(item)}
                                      >
                                          <span>
                                              {item.icon ? (
                                                  <i className={`u-icon--stock-status u-icon u-icon--${item.icon}`}></i>
                                              ) : null}{" "}
                                              {item.displayText}
                                          </span>
                                      </div>
                                  )
                              })
                            : t.common("GENERAL.NO_DATA_TO_DISPLAY")}
                    </div>
                    {props.isFilter ? (
                        <>
                            {" "}
                            <hr className="c-separator--color-light u-mt--sml--2 u-mb--sml--2" />
                            <OutlineButton size="xsml" haslabel width="full" onClick={() => {}}>
                                {t.common("GENERAL.RESET_FILTER")}
                            </OutlineButton>
                        </>
                    ) : null}{" "}
                </div>
            </div>
        </div>
    )
}

import { StrictMode } from "react"
import Script from "next/script"
import * as ReactDOM from "react-dom/client"

import { createLocalization } from "@dnr/localization"
import { AppContainer } from "@dnr/features/shared/app"

import * as Sentry from "@sentry/react"

import "./extensions"

import App from "./app/app"

const environment = process.env["NX_ENV"]
if (environment === "production" || environment === "staging") {
    Sentry.init({
        dsn: "https://2b1e008c29341a89d2752fc79d5642cc@o4505514827907072.ingest.sentry.io/4505990879182848",
        environment: environment,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    /^http:\/\/api-staging\.smdunlimited\.com/,
                    /^https:\/\/api\.smdunlimited\.com/,
                ],
            }),
            new Sentry.Replay({
                networkDetailAllowUrls: ["api.smdunlimited.com", "api-staging.smdunlimited.com"],
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.01, // This sets the sample rate at 3%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

createLocalization()
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    // <StrictMode>
    <AppContainer>
        <App />
    </AppContainer>
    // </StrictMode>
)

import { isLoggerType, LoggerType } from "./LoggerType"

import { ConsoleLogger } from "./ConsoleLogger"
import { VoidLogger } from "./VoidLogger"

class LoggerFactory {
    enabled = true
    type: LoggerType = "console"

    constructor() {
        this.enabled = process.env["NX_LOGGING_ENABLED"] === "true"

        const envLoggerType = process.env["NX_LOGGER_TYPE"]
        if (isLoggerType(envLoggerType)) {
            this.type = envLoggerType as LoggerType
        }
    }

    createLogger(name: string) {
        switch (this.type) {
            case "none":
                return new VoidLogger()
            case "console":
            default:
                return new ConsoleLogger(name)
        }
    }
}

export default new LoggerFactory()

import { useMemo } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { lookupService } from "@dnr/data/services"

interface StockStatusDropdownProps {
    placeholder: string
    selectedValue?: string
    category?: string
    onChange: (selected: DropdownOption) => void
    onReset?: () => void
    label?: string
    reset?: number
    size?: string
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
}
export const StockStatusDropdown = (props: StockStatusDropdownProps) => {
    const stockStatuses = lookupService.getProductStockStatuses()

    const stockStatusOptions = useMemo(() => {
        const lookupEntries = [] as DropdownOption[]
        for (const key in stockStatuses) {
            lookupEntries.push({
                displayText: stockStatuses[key].display,
                key: stockStatuses[key].id.toString(),
                value: stockStatuses[key].id.toString(),
                icon: stockStatuses[key].icon,
            })
        }
        return lookupEntries
    }, [])

    return (
        <Dropdown
            label={props.label}
            resetStates={props.reset}
            selectedValue={props.selectedValue}
            id="select-stock-status"
            type="select"
            placeholder={props.placeholder}
            onChange={(option) => props.onChange(option)}
            options={stockStatusOptions}
            size={props.size}
            width={props.width}
            env={props.env}
            fullWidth={props.fullWidth}
            isFilter={props.isFilter}
            resetFilter={props.onReset}
        />
    )
}

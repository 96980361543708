import React, { useState, useCallback } from "react"
import TrackingCardLoader from "./TrackingCardLoader"
import ListTitleLoader from "./ListTitleLoader"

export const LatestScannedTrackingsLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div ref={ref}>
            <ListTitleLoader loaderWidth={loaderWidth} />

            <div className="c-section c-section--cards--sml c-section--cards--horizontal u-display--flex--wrap">
                <TrackingCardLoader />
                <TrackingCardLoader />
                <TrackingCardLoader />
                <TrackingCardLoader />
                <TrackingCardLoader />
                <TrackingCardLoader />
                <TrackingCardLoader />
                <TrackingCardLoader />
            </div>
        </div>
    )
}

export default LatestScannedTrackingsLoader

import {
    ApiResponseModel,
    BalanceStatus,
    IAuthenticatedUser,
    IPagedCollection,
    IRequestOptions,
    LookupEntry,
    OwnerDnrBalanceAccount,
    QueryParam,
    QueryParamByOwner,
} from "@dnr/data/models"

import { ServiceBase, commonService } from "@dnr/data/services"

class UserInfoService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "UserInfoService"
    }

    async getUser(userId: string, options?: IRequestOptions): Promise<IAuthenticatedUser> {
        const res = await this.http.instance.get<IAuthenticatedUser>("/user/profile/" + userId, {
            signal: options?.abortController.signal,
        })

        return res.data
    }

    async getUserBalance(query: QueryParam, options?: IRequestOptions): Promise<ApiResponseModel<BalanceStatus[]>> {
        const res = await this.http.instance.get<ApiResponseModel<BalanceStatus[]>>(`/balance-accounts`, {
            params: query,
            signal: options?.abortController.signal,
        })
        return res.data
    }

    async downloadBalanceByOwnerReport(
        type: LookupEntry,
        query?: QueryParamByOwner,
        options?: IRequestOptions | null
    ): Promise<void> {
        return commonService.generateReport<QueryParamByOwner>(`/balance-accounts/by-owner/report/${type.id}`, type, query, options)
    }

    async getBalanceByOwner(
        query?: QueryParamByOwner,
        options?: IRequestOptions
    ): Promise<ApiResponseModel<IPagedCollection<OwnerDnrBalanceAccount>>> {
        const res = await this.http.instance.get<ApiResponseModel<IPagedCollection<OwnerDnrBalanceAccount>>>(
            "/balance-accounts/by-owner",
            {
                params: query,
                signal: options?.abortController.signal,
            }
        )
        return res.data
    }
}
export default new UserInfoService()

import React, { useState, useCallback } from "react"
import LoaderWrapper from "./LoaderWrapper"
import { TableCellLoader } from "@dnr/features/shared/tables"

export const ReceivingTransactionsViewLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])
    return (
        <div ref={ref}>
            <LoaderWrapper width={loaderWidth} height={110} backgroundColor="#b7c6e5">
                {/* Breadcrumbs */}
                <rect x="0" y={0} rx="6" ry="6" width="100" height="12" />

                <rect x="110" y={0} rx="6" ry="6" width="10" height="12" />
                <rect x="130" y={0} rx="6" ry="6" width="140" height="12" />

                <rect x="280" y={0} rx="6" ry="6" width="10" height="12" />
                <rect x="300" y={0} rx="6" ry="6" width="400" height="12" />

                {/* Title */}
                <rect x="0" y={50} rx="6" ry="6" width="600" height="20" />

                <rect x={loaderWidth - 190} y={30} rx="6" ry="6" width="80" height="40" />
                <rect x={loaderWidth - 100} y={30} rx="6" ry="6" width="100" height="40" />
            </LoaderWrapper>

            <div className="c-form__container u-mb--sml--10">
                <div className="u-display--flex u-display--flex--gap--base">
                    <TransactionCard />
                    <TransactionCard />
                    <TransactionCard />
                    <TransactionCard />
                </div>
            </div>

            <div className="c-form__container">
                <TableCellLoader />
            </div>
        </div>
    )
}

export default ReceivingTransactionsViewLoader

const TransactionCard = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div className="c-cards--stretch" ref={ref}>
            <TitleLoader />

            <div className="c-cards__content u-display--height--full u-mt--sml--0 u-mb--sml--0">
                <LoaderWrapper width={loaderWidth} height={190}>
                    <rect x="0" y={0} rx="6" ry="6" width="100" height="12" />
                    <rect x={loaderWidth - 100} y={0} rx="6" ry="6" width="100" height="12" />

                    <rect x="0" y={50} rx="6" ry="6" width="100" height="12" />
                    <rect x={loaderWidth - 100} y={50} rx="6" ry="6" width="100" height="12" />

                    <rect x="0" y={100} rx="6" ry="6" width="100" height="12" />
                    <rect x={loaderWidth - 100} y={100} rx="6" ry="6" width="100" height="12" />

                    <rect x="0" y={140} rx="6" ry="6" width="100" height="12" />
                    <rect x="0" y={170} rx="6" ry="6" width="100" height="16" />
                </LoaderWrapper>
            </div>
        </div>
    )
}

const TitleLoader = () => {
    return (
        <LoaderWrapper width={150} height={22}>
            <rect x="0" y={0} rx="6" ry="6" width="100" height="14" />
        </LoaderWrapper>
    )
}

import { useContext } from "react"

import { GlobalLoaderActionsContext, GlobalLoaderContext } from "./GlobalLoaderContext"

export function useGlobalLoader() {
    const ctx = useContext(GlobalLoaderContext)

    return ctx
}
export function useGlobalLoaderActions() {
    const ctx = useContext(GlobalLoaderActionsContext)

    return ctx
}

import React from "react"
import { FieldValues, FormProvider, SubmitErrorHandler, SubmitHandler, UseFormReturn } from "react-hook-form"

export type FormContainerProps<TForm extends FieldValues> = {
    form: UseFormReturn<TForm>
    onSubmit: SubmitHandler<TForm>
    onInvalid?: SubmitErrorHandler<TForm>
    children?: React.ReactNode
    className?: string
}

export const FormContainer = <TForm extends FieldValues>(props: FormContainerProps<TForm>) => {
    return (
        <FormProvider {...props.form}>
            <form onSubmit={props.form.handleSubmit(props.onSubmit, props.onInvalid)} className={props.className}>
                {props.children}
            </form>
        </FormProvider>
    )
}

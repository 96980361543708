import { useOutsideClick } from "@dnr/hooks"
import { OutlineButton } from "@dnr/ui/buttons"
import clsx from "clsx"
import React, { useState, useEffect } from "react"

export interface ActionProps {
    displayText: string
    icon?: string
    action: () => void
    abrv?: string
    enabled?: boolean
    id?: string
}

interface ActionDropdownProps {
    actionList: ActionProps[]
    actionName: string
    actionIcon?: string
    additionalClassName?: string
    size?: "sml" | "base"
    tableParams?: any
    disabled?: boolean
    id?: string
}

const ActionDropdown: React.FC<ActionDropdownProps> = ({
    actionList,
    actionName,
    actionIcon,
    additionalClassName,
    size,
    tableParams,
    disabled,
    id,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [dropdownPosition, setDropdownPosition] = useState<"bottom" | "top">("bottom")

    const dropdownRef = useOutsideClick(() => {
        setIsOpen(false)
    })

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleOptionClick = async ({ action }: ActionProps) => {
        action()
        setIsOpen(false)
    }

    useEffect(() => {
        if (tableParams) {
            const tableHeight = tableParams.columnApi.columnModel.gridOptionsWrapper.eGridDiv.clientHeight
            const firstRow = tableParams.node.beans.gridApi.rowRenderer.firstVisibleVPixel

            if (tableHeight) {
                setDropdownPosition(tableParams.node.rowTop + 250 > tableHeight + firstRow ? "top" : "bottom")
            }
        }
    }, [])

    return (
        // @ts-ignore: allowed
        <div className={clsx("c-dropdown", additionalClassName)} ref={dropdownRef}>
            <OutlineButton
                size="sml"
                hasicon
                icontype={actionIcon}
                haslabel
                onClick={toggleDropdown}
                disabled={disabled}
            >
                {actionName}
            </OutlineButton>

            {isOpen && (
                <div
                    className={clsx(
                        "c-dropdown__actions",
                        `c-dropdown__actions--${dropdownPosition}`,
                        tableParams ? "c-dropdown__actions--sml" : "c-dropdown__actions--base"
                    )}
                >
                    {actionList.map((option, index) => (
                        <div key={index} className="c-dropdown__action" onClick={() => handleOptionClick(option)}>
                            {option.icon && <i className={`u-icon u-icon--base u-icon--${option.icon}`}></i>}
                            {option.displayText}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ActionDropdown

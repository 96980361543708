import { useState, useCallback } from "react"
import DealsCardLoader from "./DealsCardLoader"

export const CardsLoader = ({ listLength = 12 }: { listLength?: number }) => {
    const [loaderWidth, setLoaderWidth] = useState(0)
    const dealsCardList = Array(listLength).fill("")

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div>
            <div ref={ref} className="c-grid row--6 row--gap--med">
                {dealsCardList.map(() => {
                    return <DealsCardLoader />
                })}
            </div>
        </div>
    )
}

export default CardsLoader

import clsx from "clsx"
import React from "react"

import { BaseButton, BaseButtonProps } from "./BaseButton"

export type PrimaryButtonProps = {} & Omit<BaseButtonProps, "className">

export const PrimaryButton: React.FC<PrimaryButtonProps> = (props) => (
    <BaseButton {...props} className={clsx("primary")} />
)

import React from "react"
import { SnackbarProvider } from "notistack"

import { GlobalLoader, GlobalLoaderContextProvider } from "@dnr/features/shared/loader"
import {
    DetailedErrorNotification,
    DetailedInfoNotification,
    DetailedWarningNotification,
    ExpandableErrorNotification,
    ExpandableInfoNotification,
    ExpandableWarningNotification,
    LoadingNotification,
} from "@dnr/ui/notifications"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"

type Props = {
    children: React.ReactNode
}

declare module "notistack" {
    interface VariantOverrides {
        detailedError: true
        detailedWarning: true
        detailedInfo: true
        expandableWarning: true
        expandableInfo: true
        expandableError: true
        loading: true
    }
}

export const AppContainer: React.FC<Props> = (props) => {
    return (
        <GlobalLoaderContextProvider>
            <SnackbarProvider
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
                Components={{
                    detailedError: DetailedErrorNotification,
                    detailedWarning: DetailedWarningNotification,
                    detailedInfo: DetailedInfoNotification,
                    expandableWarning: ExpandableWarningNotification,
                    expandableInfo: ExpandableInfoNotification,
                    expandableError: ExpandableErrorNotification,
                    loading: LoadingNotification,
                }}
            >
                <GlobalLoader />
                {props.children}
            </SnackbarProvider>
        </GlobalLoaderContextProvider>
    )
}

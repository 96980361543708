import React, { useState, useCallback } from "react"
import ListTitleLoader from "./ListTitleLoader"
import CommitmentsCardLoader from "./CommitmentsCardLoader"

export const LatestCommitmentsLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div ref={ref} className="c-section--dashboard__item">
            <ListTitleLoader loaderWidth={loaderWidth} />

            <div className="c-section c-section--cards--sml c-section--cards--vertical">
                <CommitmentsCardLoader />
                <CommitmentsCardLoader />
                <CommitmentsCardLoader />
                <CommitmentsCardLoader />
            </div>
        </div>
    )
}

export default LatestCommitmentsLoader

import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react"
import clsx from "clsx"

export type BaseButtonProps = {
    size?: "xsml" | "sml" | "med" | "base" | "lrg"
    width?: "full" | ""
    hasicon?: boolean
    haslabel?: boolean
    icontype?: string
    disabled?: boolean
    loading?: boolean
    title?: string
    btnType?: string
    extendedClassName?: string
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const BaseButton: React.FC<BaseButtonProps> = (props) => {
    const { hasicon, disabled, haslabel, ...otherProps } = props
    return (
        <button
            {...otherProps}
            className={clsx(
                "c-btn",
                `c-btn--${props.size}`,
                `c-btn--${props.className}`,
                `${props.disabled ? `c-btn--disabled` : ""}`,
                `c-btn--${props.width}`,
                `${hasicon && haslabel ? "c-btn--icon" : ""}`,
                `${hasicon && !haslabel ? `c-btn--icon-only` : ""}`,
                `${props.icontype ? `c-btn--icon--${props.icontype}` : ""}`,
                `${props.btnType ? `c-btn--primary--${props.btnType}` : ""}`,
                `${props.extendedClassName}`,
                `${props.loading ? `c-btn--loading` : ""}`
            )}
            disabled={disabled}
            title={props.title}
        />
    )
}

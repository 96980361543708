import React, { useCallback, useEffect, useState } from "react"

import { DropdownOption } from "@dnr/ui/dropdowns"
import { useDidMountEffect, useRequest, useTrigger } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { lookupService, productsService } from "@dnr/data/services"
import { ProductFull } from "@dnr/data/models"
import { SearchDropdownInput } from "@dnr/ui/inputs"

export type ProductsSearchDropdownProps = {
    className?: string
    placeholder?: string
    label?: string
    extraLabel?: string
    selectedValue?: string
    color?: string
    onChange: (value: DropdownOption<ProductFull>) => void
    type?: string
    direction?: string
    isAppending?: boolean
    searchByNumericalId?: boolean
    reset?: number
    size?: string
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
    onReset?: () => void
    publishType?: "draft" | "publish"
}

export interface ProductsByIdentification {
    productName: string
    productId: string
    productIdentification: string
    productIdentificationType: number
}

export const ProductsSearchDropdown: React.FC<ProductsSearchDropdownProps> = (props) => {
    const { t } = useTranslate()
    const { trigger: triggerRequest, forceTrigger } = useTrigger()

    const [searchQuery, setSearchQuery] = useState("")
    const [selectedKey, setIsSelected] = useState(props.selectedValue)
    const [loading, setLoading] = useState(true)

    const [displayText, setDisplayText] = useState(props.placeholder || t.form("TRANSACTIONS.SELECT_PRODUCT"))
    const [dropdownOptions, setDropdownOptions] = useState<DropdownOption<ProductFull>[]>([])
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const handleReset = () => {
        setIsSelected(undefined)
        setDisplayText(props.placeholder || t.form("TRANSACTIONS.SELECT_PRODUCT"))
        setSearchQuery("")
        setDropdownOptions([])
        forceTrigger()
    }

    useDidMountEffect(() => {
        handleReset()
    }, [props.reset])

    const handleChange = (element: DropdownOption<ProductFull>) => {
        props.onChange(element)
        //NOTE: When an item is selected, the form is reset!
        setIsSelected(element.key)
        setDisplayText(element.displayText)
    }

    const { error } = useRequest(
        async (options) => {
            if (isDropdownOpen) {
                setLoading(true)

                const { result: responseData } = await productsService.findFullProducts(
                    {
                        pageNumber: 1,
                        pageSize: 100,
                        publishTypeId: props.publishType
                            ? lookupService.productPublishTypesByAbrv[props.publishType].id
                            : undefined,
                        query: props.searchByNumericalId ? undefined : searchQuery,
                        numericalId: props.searchByNumericalId ? Number(searchQuery) : undefined,
                    },
                    {
                        abortController: options.abortController,
                    }
                )
                const dropdownOptions = responseData.items.map((product) => {
                    return {
                        key: product.productId,
                        value: product.name,
                        displayText: product.name,
                        data: product,
                    } as DropdownOption<ProductFull>
                })

                setLoading(false)
                setDropdownOptions(dropdownOptions)
            }
        },
        [searchQuery, triggerRequest, isDropdownOpen],
        { debounce: 1000 }
    )

    return (
        <SearchDropdownInput
            isOpenedFirstTime={(isOpened) => setIsDropdownOpen(isOpened)}
            displayText={displayText}
            options={dropdownOptions}
            handleChange={handleChange}
            handleReset={() => {
                handleReset()
                if (props.onReset) props.onReset()
            }}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            selectedValue={selectedKey}
            selectedKey="key"
            isLight
            loading={loading}
            error={error}
            {...props}
            isFilter={props.isFilter}
        />
    )
}

export * from "./lib/ILogger"
export * from "./lib/LogLevel"
export * from "./lib/LoggerType"

import loggerFactory from "./lib/loggerFactory"

// default logger
const defaultLogger = loggerFactory.createLogger("default")

export { defaultLogger, loggerFactory }

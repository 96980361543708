import { AxiosResponse } from "axios"

import {
    ApiResponseBaseModel,
    ApiResponseModel,
    PhoneNumValidation,
    ResendConfirmationData,
    TResponse,
    UnsubscribeEmailData,
    VerificationData,
} from "@dnr/data/models"

import { ServiceBase } from "../common-services/ServiceBase"

class AccountVerificationService extends ServiceBase {
    getLoggerName(): string {
        return "AccountVerificationService"
    }

    resendEmail(email: ResendConfirmationData): Promise<TResponse> {
        return this.http.instance.post("/account/emails/resend-account-confirmation", email)
    }

    resendSms(email: ResendConfirmationData): Promise<TResponse> {
        return this.http.instance.post("/account/sms/resend-phone-confirmation", email)
    }

    unsubscribeEmail(data: UnsubscribeEmailData): Promise<ApiResponseBaseModel> {
        return this.http.instance.post("/account/unsubscribe-email", data)
    }

    verifyEmail(data: VerificationData): Promise<AxiosResponse<TResponse>> {
        return this.http.instance.put("/account/verify/email", data)
    }

    verifyPhoneNum(data: VerificationData): Promise<TResponse> {
        return this.http.instance.put("/account/verify/sms", data)
    }

    async validatePhoneNum(phoneNumber: string, excludeUserId?: string): Promise<ApiResponseModel<PhoneNumValidation>> {
        const res = await this.http.instance.put<ApiResponseModel<PhoneNumValidation>>(
            `/account/verify/phone-number?phoneNumber=${phoneNumber}${excludeUserId ? `&excludeUserId=${excludeUserId}` : ""}`
        )
        return res.data
    }
}

export default new AccountVerificationService()

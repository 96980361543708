import axios, { AxiosRequestConfig } from "axios"
import * as qs from "qs"

import { localStorageProvider } from "@dnr/util/storage"

import { IHttpClient } from "./IHttpClient"
import { IHttpClientOptions } from "./IHttpClientOptions"
import { IToken } from "@dnr/data/models"

let refreshSubscribers: any = []
let isDispatched = false

export class HttpClientFactory {
    create(options: IHttpClientOptions): IHttpClient {
        const client = axios.create({
            baseURL: options.baseUrl,
            headers: {
                "Content-Type": "application/json",
                ...options.headers,
            },
            paramsSerializer: (params) =>
                // NOTE: arrayFormat is subject to change
                qs.stringify(params, { arrayFormat: "comma" }),
        })

        !options.skipRequestInterceptor &&
            client.interceptors.request.use(
                (config) => {
                    const tokenItem = options.storage.get<IToken>("token")

                    if (tokenItem.value) {
                        if (tokenItem.value.expireTime && tokenItem.value.expireTime.getTime() <= new Date().getTime()) {
                            !isDispatched && dispatchTokenEvent("tokenExpired")

                            return new Promise((resolve): void => {
                                refreshSubscribers.push((token: string) => {
                                    config.headers = {
                                        ...config.headers,
                                        Authorization: "Bearer " + token,
                                    }
                                    resolve(config)
                                })
                            })
                        }

                        const token = tokenItem.value.token

                        if (config.headers) {
                            config.headers["Authorization"] = "Bearer " + token
                        } else {
                            config.headers = {
                                Authorization: "Bearer " + token,
                            }
                        }

                        config.headers["request-id"] =
                            Date.now().toString(36) + Math.random().toString(8).replace(/\./g, "")
                    }

                    return config
                })

        client.interceptors.response.use(
            async (response) => {
                // Delay the response by 15 seconds
                if (options.throttle) {
                    const min = 25000; // Minimum delay in milliseconds
                    const max = 35000; // Maximum delay in milliseconds
                    const delay = Math.floor(Math.random() * (max - min) + min); // Generate random delay
                    await sleep(delay);
                }
                // Return the response to proceed with the chain
                return response;
            },
            async (error) => {
                if (error.response) {
                    const { status } = error.response

                    switch (status) {
                        case 401:
                            !isDispatched && dispatchTokenEvent("invalidToken")
                            break
                    }
                }
                return error && error !== "" ? Promise.reject(error) : Promise.reject("error response")
            }
        )

        return {
            instance: client,
        }
    }
}

function dispatchTokenEvent(eventType: string) {
    isDispatched = true

    const tokenEvent = new Event(eventType)
    window.dispatchEvent(tokenEvent)

    setTimeout(() => (isDispatched = false), 0)
}

function sleep(ms = 2000): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

window.addEventListener("tokenUpdated", () => {
    isDispatched = false

    const tokenItem = localStorageProvider.get<IToken>("token")

    refreshSubscribers.forEach((subscriber: (token?: string) => AxiosRequestConfig) =>
        subscriber(tokenItem.value?.token)
    )
    refreshSubscribers = []
})

import React, { useCallback, useState } from "react"
import ContentLoader from "react-content-loader"

interface ItemListLoaderProps {
    loaderHeight?: number
    listItem?: number | undefined
    title?: boolean
    listHeight?: number
}

const ListItemLoader = ({ listItem = 6, loaderHeight, title, listHeight }: ItemListLoaderProps) => {
    const [loaderWidth, setLoaderWidth] = useState(0)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    const list = []
    let y = title ? 90 : 0

    for (let i = 0; i < listItem; i++) {
        list.push(
            <React.Fragment key={i}>
                <rect x={0} y={y} rx="6" ry="6" width="100%" height={listHeight ? listHeight : "65"} />
            </React.Fragment>
        )

        y += listHeight ? listHeight + 10 : 80
    }

    let lHeight = 0
    if (listHeight) {
        lHeight = listHeight * listItem + 20
    } else if (loaderHeight) {
        lHeight = loaderHeight
    } else {
        lHeight = listItem * 70
    }

    return (
        <div ref={ref}>
            <ContentLoader
                width={loaderWidth}
                height={"100%"}
                viewBox={`0 0 ${loaderWidth} ${lHeight}`}
                backgroundColor={"#F5F9FF"}
                foregroundColor={"#DBEAFE"}
                speed={2}
            >
                {title && (
                    <>
                        <rect x={0} y={20} rx="6" ry="6" width="140" height="20" />
                        <rect x={loaderWidth - 70} y={12} rx="6" ry="6" width="70" height="40" />
                        <rect x={0} y={65} rx="6" ry="6" width="100%" height="1" />
                    </>
                )}
                {list}
            </ContentLoader>
        </div>
    )
}

export default ListItemLoader

import React, { useCallback, useState } from "react"
import ContentLoader from "react-content-loader"
interface TableCellProps {
    cell?: number
    row?: number
    spaceY?: number
    spaceX?: number
    loaderHeight?: number
}

export const TableCellLoader = ({
    cell = 10,
    row = 6,
    spaceY = 52,
    loaderHeight = 432,
    spaceX = 20,
}: TableCellProps) => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    const cardList = []

    let y = 0
    let x = 0

    for (let i = 0; i < cell; i++) {
        for (let j = 0; j <= row; j++) {
            if (j === row) {
                cardList.push(
                    <React.Fragment key={Math.random() * 1000}>
                        <rect x={loaderWidth - 100} y={y} rx="6" ry="6" width={80} height="16" />
                    </React.Fragment>
                )
            } else {
                cardList.push(
                    <React.Fragment key={Math.random() * 1000}>
                        <rect x={x + spaceX} y={y} rx="6" ry="6" width={Math.abs(loaderWidth / 8)} height="16" />
                    </React.Fragment>
                )
            }
            x += loaderWidth / 6
        }

        x = 0
        y += spaceY
    }

    return (
        <div ref={ref} style={{ width: "100%", height: `${loaderHeight}px` }}>
            <ContentLoader
                width={loaderWidth}
                height={"100%"}
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor={"#F5F9FF"}
                foregroundColor={"#DBEAFE"}
                speed={2}
            >
                {cardList}
            </ContentLoader>
        </div>
    )
}
export default TableCellLoader

import { useState } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { useTranslate } from "@dnr/localization"

interface ItemStatusDropdownProps {
    label?: string
    reset?: number
    placeholder?: string
    selectedValue?: string
    inputSize?: string
    onChange: (selected: DropdownOption) => void
    size?: string
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
    onReset?: () => void
}

export const ItemStatusDropdown = (props: ItemStatusDropdownProps) => {
    const { t } = useTranslate()

    const [issueTypes] = useState<DropdownOption[]>([
        {
            displayText: t.common("GENERAL.ANY"),
            value: "any",
            key: "1",
        },
        {
            displayText: t.common("WAREHOUSE.IN_WAREHOUSE"),
            value: "inWarehouse",
            key: "2",
        },
        {
            displayText: t.common("WAREHOUSE.SHIPPED_OUT"),
            value: "shippedOut",
            key: "3",
        },
        {
            displayText: t.common("FINANCIAL_ACCOUNTS.RESERVED"),
            value: "reserved",
            key: "4",
        },
    ])

    return (
        <Dropdown
            resetStates={props.reset}
            id="warehouse-item-status-dropdown"
            label={props.label}
            type="select"
            placeholder={props.placeholder}
            options={issueTypes}
            selectedValue={props.selectedValue}
            onChange={(option) => props.onChange(option)}
            size={props.size}
            width={props.width}
            env={props.env}
            fullWidth={props.fullWidth}
            isFilter={props.isFilter}
            resetFilter={props.onReset}
        />
    )
}

import { DetailedHTMLProps, InputHTMLAttributes, useMemo } from "react"

import { lookupService } from "@dnr/data/services"
import { FieldValues, Path } from "react-hook-form"
import { FormDropdownOption, FormDropdown } from "@dnr/ui/forms"

interface Props<TForm> extends DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    placeholder: string
    selectedValue?: string
    label: string
    name: Path<TForm>
    color?: string
    onUpdate: (value: FormDropdownOption) => void
    shippingTransaction?: boolean
}

export const ShippingReqStatusFormDropdown = <TForm extends FieldValues>(props: Props<TForm>) => {
    const shippingStatuses = lookupService.getShippingRequestStatus()
    const shippingStatusId = lookupService.shippingRequestStatusIds

    const shippingStatusOptions = useMemo(() => {
        const lookupEntries = [] as FormDropdownOption[]
        for (const key in shippingStatuses) {
            lookupEntries.push({
                label: shippingStatuses[key].display,
                displayText: shippingStatuses[key].display,
                key: shippingStatuses[key].id.toString(),
                value: shippingStatuses[key].id.toString(),
                icon: shippingStatuses[key].icon,
            })
        }
        return props.shippingTransaction
            ? lookupEntries
            : lookupEntries.filter((i) => i.key !== shippingStatusId["shipped-out"].id.toString())
    }, [])

    return (
        <FormDropdown<TForm>
            name={props.name}
            label={props.label}
            selectedValue={props.selectedValue}
            id="select-shipping-status"
            type="select"
            placeholder={props.placeholder}
            onUpdate={(option) => props.onUpdate(option)}
            options={shippingStatusOptions}
            color={props.color}
        />
    )
}

import { IRequestOptions, LookupEntry, SystemVersion } from "@dnr/data/models"
import { ServiceBase } from "./ServiceBase"
import helperService from "./helperService"
import mime from 'mime'

class CommonService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "CommonService"
    }

    async getAllResponseCodes(options?: IRequestOptions): Promise<void> {
        const response = await this.http.instance.get<void>("/common/internal-response-codes", {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async getSystemVersion(options?: IRequestOptions): Promise<SystemVersion[]> {
        const response = await this.http.instance.get<SystemVersion[]>("/common/system-version", {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async generateReport<IFilter>(
        route: string,
        type: LookupEntry,
        filter?: IFilter,
        options?: IRequestOptions | null,
        noParams?: boolean,
    ): Promise<void> {
        return this.http.instance
            .get<File>(route, {
                params: noParams ? undefined : {
                    timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    reportExportType: type.id,
                    timeZoneOffset: helperService.timeZoneOffset,
                    ...filter
                },
                responseType: "blob",
                signal: options?.abortController.signal,
            })
            .then((res) => {
                helperService.downloadReport(res, type.abrv)
            })
    }

    async readFile(
        relativePath: string,
        fileName?: string,
        options?: IRequestOptions | null
    ): Promise<void> {
        return this.http.instance
            .get<File>(`/files/${relativePath}`, {
                params: { fileName },
                responseType: "blob",
                signal: options?.abortController.signal,
            })
            .then((res) => {
                const resType = mime.getExtension(res.data.type)
                if (resType)
                    helperService.fileDownload(res.data, resType, fileName || res.data.name)
            })
    }
}

export default new CommonService()

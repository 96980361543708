import { useTranslate } from "@dnr/localization"
import { defaultLogger } from "@dnr/util/logger"
import { enqueueSnackbar } from "notistack"
import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { FieldValues, FormProvider, SubmitErrorHandler, SubmitHandler, UseFormReturn } from "react-hook-form"

export type ReCaptchaFormContainerProps<TForm extends FieldValues> = {
    form: UseFormReturn<TForm>
    onSubmit: SubmitHandler<TForm>
    onInvalid?: SubmitErrorHandler<TForm>
    children?: React.ReactNode
    className?: string
}

export const ReCaptchaFormContainer = <TForm extends FieldValues>(props: ReCaptchaFormContainerProps<TForm>) => {
    const { t } = useTranslate()

    const clientKey = process.env["NX_RECAPTCHA_KEY"]
    if (!clientKey) {
        defaultLogger.logError("Missing recaptcha key", {})
        return null
    }

    const reCaptchaRef = React.createRef<ReCAPTCHA>()

    const handleSubmit = async (data: TForm) => {
        if (!reCaptchaRef.current) {
            defaultLogger.logError("ReCaptcha ref error", {})
            return
        }
        reCaptchaRef.current.reset()
        const token = await reCaptchaRef.current.executeAsync()
        if (!token) {
            enqueueSnackbar({
                variant: "error",
                message: t.error("GENERAL.RECAPTCHA_ERROR"),
            })
            return
        }

        // @ts-ignore-allowed
        data["reCaptchaToken"] = token

        return await props.onSubmit(data)
    }

    return (
        <FormProvider {...props.form}>
            <form onSubmit={props.form.handleSubmit(handleSubmit, props.onInvalid)} className={props.className}>
                {props.children}

                <ReCAPTCHA
                    ref={reCaptchaRef}
                    size="invisible"
                    sitekey={clientKey}
                    badge="bottomright"
                    onError={(e) => {
                        if (e) {
                            defaultLogger.logError("ReCaptcha error", e)
                        }
                    }}
                />
            </form>
        </FormProvider>
    )
}

import { useState, useCallback } from "react"
import LoaderWrapper from "./LoaderWrapper"
import { TableCellLoader } from "@dnr/features/shared/tables"

export const NewShippingRequestLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div ref={ref}>
            <LoaderWrapper width={loaderWidth} height={42} backgroundColor="#b7c6e5">
                <rect x="0" y={0} rx="6" ry="6" width="100" height="12" />

                <rect x="110" y={0} rx="6" ry="6" width="10" height="12" />
                <rect x="130" y={0} rx="6" ry="6" width="140" height="12" />

                <rect x="280" y={0} rx="6" ry="6" width="10" height="12" />
                <rect x="300" y={0} rx="6" ry="6" width="400" height="12" />
            </LoaderWrapper>

            <div className="c-form__container">
                <div className="u-mb--sml--4">
                    <LoaderWrapper width={loaderWidth} height={110}>
                        <rect x="0" y={0} rx="6" ry="6" width="190" height="24" />
                        <rect x="0" y={50} rx="6" ry="6" width="190" height="20" />
                    </LoaderWrapper>
                </div>

                <TableCellLoader spaceX={0} loaderHeight={380} />

                <hr className="c-separator--color-light u-mt--sml--4 u-mb--sml--4" />

                <div className="u-display--width--base--max">
                    <LoaderWrapper width={400} height={320}>
                        <rect x="0" y={0} rx="6" ry="6" width="190" height="20" />

                        <rect x="0" y={40} rx="6" ry="6" width="160" height="12" />
                        <rect x="0" y={60} rx="6" ry="6" width="100%" height="40" />

                        <rect x="0" y={120} rx="6" ry="6" width="160" height="12" />
                        <rect x="0" y={140} rx="6" ry="6" width="100%" height="40" />

                        <rect x="0" y={200} rx="6" ry="6" width="160" height="12" />
                        <rect x="0" y={220} rx="6" ry="6" width="100%" height="100" />
                    </LoaderWrapper>
                </div>

                <hr className="c-separator--color-light u-mt--sml--4 u-mb--sml--4" />

                <LoaderWrapper width={loaderWidth} height={40}>
                    <rect x="0" y={0} rx="6" ry="6" width="150" height="40" />
                    <rect x={loaderWidth - 150} y={0} rx="6" ry="6" width="150" height="40" />
                </LoaderWrapper>
            </div>
        </div>
    )
}

export default NewShippingRequestLoader

import { forwardRef, useEffect, useMemo, useState } from "react"

import { useOutsideClick } from "@dnr/hooks"
import { Input } from "@dnr/ui/inputs"
import { DropdownOption } from "./Dropdown"
import { OutlineButton } from "@dnr/ui/buttons"
import { useTranslate } from "@dnr/localization"

export interface BasicMultiselectDropdownProps {
    id: string
    label?: string
    options?: DropdownOption[]
    selectedValue?: string
    placeholder: string
    type?: string
    direction?: string
    size?: string
    color?: string
    onChange: (value: string[]) => void
    resetFilter?: () => void
    iserror?: boolean
    required?: boolean
    resetStates?: number
    env?: "portal" | "backoffice"
    fullWidth?: boolean
    isFilter?: boolean
}

export const BasicMultiselectDropdown = forwardRef<HTMLSelectElement, BasicMultiselectDropdownProps>((props) => {
    const { t } = useTranslate()
    const [isOpen, setIsOpen] = useState(false)
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const [displayText, setDisplayText] = useState<string[]>([])
    const [displayIcon] = useState(props.options?.find((item) => item.key === props.selectedValue)?.icon)

    const innerRef = useOutsideClick(() => {
        setIsOpen(false)
    })

    const placeholderText = useMemo(() => {
        if (displayText.length === 0) {
            return props.placeholder
        } else {
            return displayText.join(", ")
        }
    }, [displayText])

    const handleChange = (element: DropdownOption, isChecked: boolean) => {
        if (!isChecked) {
            setSelectedKeys(selectedKeys?.filter((optionKey) => optionKey !== element.key))
            props.onChange(selectedKeys?.filter((optionKey) => optionKey !== element.key))
            setDisplayText(displayText?.filter((optionText) => optionText !== element.displayText))
        } else {
            setSelectedKeys(selectedKeys?.concat(element.key))
            props.onChange(selectedKeys?.concat(element.key))
            setDisplayText(displayText.concat(element.displayText))
        }
    }

    const handleOnClickChange = (item: DropdownOption, ev: any) => {
        if (ev.target.id === `outter-option-div-${item.key}`) {
            if (selectedKeys.includes(item.key)) {
                handleChange(item, false)
            } else {
                handleChange(item, true)
            }
        }
    }

    useEffect(() => {
        if (props.resetStates) {
            setDisplayText([])
            setSelectedKeys([])
        }
    }, [props.resetStates])

    const handleIsOpen = (ev: any) => {
        if (ev.target.id === "outter-div") setIsOpen(!isOpen)
    }

    const handleReset = () => {
        if (props.resetFilter) props.resetFilter()
        setDisplayText([])
        setSelectedKeys([])
        setIsOpen(false)
    }

    return (
        <div
            className={`${props.size == "full" ? "u-display--flex-col-fill" : null} ${
                props.fullWidth ? "fullWidth" : ""
            }`}
        >
            {props.label != null ? (
                <div className="u-mb--sml--1">
                    <label className="c-input__label" htmlFor={props.id}>
                        {props.label} {props.required ? <span className="u-type--color--error">*</span> : ""}
                    </label>
                </div>
            ) : null}

            <div
                id="outter-div"
                className={`c-input
                     ${props.size == "full" ? "c-input--full" : props.size ? `c-input--${props.size}` : "c-input--base"}
                    c-input--select ${props.color === "light" ? "c-input--select--light" : null} ${
                    isOpen ? "isOpen" : ""
                } ${props.iserror ? "c-input--error" : ""}`}
                // @ts-ignore: allowed
                ref={innerRef}
                onClick={(ev) => handleIsOpen(ev)}
            >
                <div
                    id="outter-div"
                    className={`c-input--select__text ${
                        displayText.length > 0 ? " c-input--select__text--selected" : null
                    }`}
                >
                    {displayIcon ? <i className={`u-icon--stock-status u-icon u-icon--${displayIcon}`}></i> : null}{" "}
                    {placeholderText}
                </div>
                <div
                    className={`c-input--select__content ${
                        props.direction == "up" ? "c-input--select__list--top" : "c-input--select__list--bottom"
                    } ${isOpen ? "isOpen" : ""}`}
                >
                    <div className="c-input--select__list">
                        {props.options != null && props.options.length > 0
                            ? props.options.map((item) => {
                                  return (
                                      <div
                                          id={`outter-option-div-${item.key}`}
                                          className={`c-input--select__option ${
                                              (selectedKeys.length || props.selectedValue) === item.key
                                                  ? "c-input--select__option--active"
                                                  : ""
                                          }`}
                                          key={item.key}
                                          onClick={(ev) => handleOnClickChange(item, ev)}
                                      >
                                          <div
                                              id={`outter-option-div-${item.key}`}
                                              className="u-display--flex u-display--flex--ai--center u-display--flex--gap--xsml"
                                          >
                                              <Input
                                                  type="checkbox"
                                                  inputtype="checkbox"
                                                  label={item.displayText}
                                                  inputsize="base"
                                                  inline
                                                  key={item.key}
                                                  name={item.key}
                                                  checked={selectedKeys.includes(item.key)}
                                                  id={item.key}
                                                  onChange={(ev) => handleChange(item, ev.target.checked)}
                                              />
                                          </div>
                                      </div>
                                  )
                              })
                            : null}
                    </div>

                    {props.isFilter ? (
                        <>
                            {" "}
                            <hr className="c-separator--color-light u-mt--sml--2 u-mb--sml--2" />
                            <OutlineButton size="xsml" haslabel width="full" onClick={handleReset}>
                                {t.common("GENERAL.RESET_FILTER")}
                            </OutlineButton>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
})

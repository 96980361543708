import React, { useState } from "react"

import { DropdownOption } from "@dnr/ui/dropdowns"
import { useDidMountEffect, useRequest } from "@dnr/hooks"
import { commitmentsService } from "@dnr/data/services"
import { SearchDropdownInput } from "@dnr/ui/inputs"

export interface CommitmentSearchDropdownProps {
    className?: string
    placeholder?: string
    label?: string
    extraLabel?: string
    selectedValue?: string
    color?: string
    onChange: (value: DropdownOption) => void
    onReset?: () => void
    type?: string
    direction?: string
    isAppending?: boolean
    reset?: number
    size?: string
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
}

export const CommitmentSearchDropdown = (props: CommitmentSearchDropdownProps) => {
    const [searchQuery, setSearchQuery] = useState("")
    const [selectedKey, setIsSelected] = useState(props.selectedValue)
    const [loading, setLoading] = useState(true)

    const [displayText, setDisplayText] = useState(props.placeholder || "Select deal")
    const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([])
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const handleReset = () => {
        setDisplayText(props.placeholder!)
        setSearchQuery("")
        setIsSelected(props.selectedValue)
    }

    useDidMountEffect(() => {
        handleReset()
    }, [props.reset])

    const handleChange = (element: DropdownOption) => {
        props.onChange(element)
        setIsSelected(element.key)
        setDisplayText(element.displayText)
    }

    const { error } = useRequest(
        async (options) => {
            if (isDropdownOpen) {
                setLoading(true)

                const responseData = await commitmentsService.find(
                    {
                        pageNumber: 1,
                        pageSize: 100,
                        query: searchQuery,
                    },
                    {
                        abortController: options.abortController,
                    }
                )

                const dropdownOptions = responseData.items.map((commitment) => {
                    return {
                        key: commitment.commitmentId,
                        value: commitment.dealName,
                        displayText: `${commitment.numericalId} - ${commitment.dealName}`,
                    } as DropdownOption
                })

                setLoading(false)
                setDropdownOptions(dropdownOptions)
            }
        },
        [searchQuery, isDropdownOpen],
        { debounce: 1000 }
    )

    return (
        <SearchDropdownInput
            isOpenedFirstTime={(isOpened) => setIsDropdownOpen(isOpened)}
            displayText={displayText}
            options={dropdownOptions}
            handleChange={handleChange}
            handleReset={() => {
                handleReset()
                if (props.onReset) props.onReset()
            }}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            selectedValue={selectedKey}
            selectedKey="key"
            isLight
            loading={loading}
            error={error}
            {...props}
            isFilter={props.isFilter}
        />
    )
}

import { useTranslate } from "@dnr/localization"

export const UnsubscribeLoading = () => {
    const { t } = useTranslate()

    return (
        <>
            <img
                src="../../assets/img/empty-states/notification-2.png"
                className="c-login__header__img--additional u-mb--sml--6"
            />
            <div className="c-login__title c-login__title--additional">
                <h2 className="u-mb--sml--3">{t.common("GENERAL.UNSUBSCRIBING")}</h2>
                <p className="c-login__subtext u-mb--sml--4">{t.common("GENERAL.DONT_LEAVE_PAGE")}</p>
            </div>{" "}
            <span className="c-loader--secondary u-mb--sml--2"></span>
        </>
    )
}

import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, useState } from "react"

import { useOutsideClick } from "@dnr/hooks"

import { Input } from "./Input"

export type PriceInputProps = {} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

interface DropdownOption {
    key: string
    value: string
    displayText: string
}

const currencies = [
    {
        symbol: "$",
        abrv: "USD",
        name: "Dollar",
    },
    {
        symbol: "€",
        abrv: "EUR",
        name: "Euro",
    },
    {
        symbol: "£",
        abrv: "GBP",
        name: "Pound",
    },
    {
        symbol: "¥",
        abrv: "JPY",
        name: "Yen",
    },
]

export const PriceInput = forwardRef<HTMLInputElement, PriceInputProps>((props, ref) => {
    const [options] = useState<DropdownOption[]>(
        currencies.map((item) => {
            return {
                key: item.abrv,
                value: item.symbol,
                displayText: item.abrv,
            } as DropdownOption
        })
    )
    const [isOpen, setIsOpen] = useState(false)
    const [selectedKey, setIsSelected] = useState<DropdownOption>()
    const innerRef = useOutsideClick(() => {
        setIsOpen(false)
    })
    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }
    const handleChange = (element: DropdownOption) => {
        setIsSelected(element)
    }
    return (
        <div className="c-input__price-wrapper">
            <div className="c-input--price-input__currency">{selectedKey?.value || "$"}</div>
            <Input
                type="price-input"
                name="price-input"
                inputtype="price-input"
                placeholder="Eg. 330.00"
                inputsize="base"
            />
            <div className="u-display--flex u-display--flex--ai--center u-display--flex--gap--xsml">
                <div className="c-input--price__">
                    <div
                        className={`c-input
                    
                    ${
                        props.size ? `c-input--${props.size}` : "c-input--base"
                    } c-input--select c-input--select--secondary ${isOpen ? "isOpen" : ""}`}
                        // @ts-ignore
                        ref={innerRef}
                        onClick={handleIsOpen}
                    >
                        <div
                            className={`c-input--select__list c-input--select__list--secondary ${
                                props.type == "up" ? "c-input--select__list--top" : "c-input--select__list--bottom"
                            } ${isOpen ? "isOpen" : ""}`}
                        >
                            <div className="fix-blur">
                                {options.map((item) => {
                                    return (
                                        <div
                                            className="c-input--select__option c-input--select__option--secondary"
                                            key={item.key}
                                            onClick={() => handleChange(item)}
                                        >
                                            <div className="c-input--select__option--wrapper">
                                                <span>{item.displayText}</span>
                                                <span className="u-type--wgt--medium u-type--color--primary u-type--base">
                                                    {item.value}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

import { useState } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { useTranslate } from "@dnr/localization"

interface RunnerItemIssuesDropdownProps {
    label?: string
    reset?: number
    placeholder?: string
    selectedValue?: string
    inputSize?: string
    onChange: (selected: DropdownOption) => void,
    additionalOnChange?: (selected: DropdownOption) => void
    size?: string
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
    onReset?: () => void
    trackingsFilter?: boolean
}

export const RunerItemIssuesDropdown = (props: RunnerItemIssuesDropdownProps) => {
    const { t } = useTranslate()

    const initialOptions: DropdownOption[] = [
        {
            displayText: t.common("GENERAL.ANY_REASON"),
            value: "anyReason",
            key: "1",
        },
    ]

    const additionalOptions: DropdownOption[] = [
        {
            displayText: t.common("BACKOFFICE.WAREHOUSE.NO_COMMITMENT"),
            value: "noCommitment",
            key: "2",
        },
        {
            displayText: t.common("BACKOFFICE.WAREHOUSE.DAMAGED"),
            value: "damaged",
            key: "3",
        }
    ]

    const [issueTypes] = useState<DropdownOption[]>([
        ...initialOptions,
        ...(!props.trackingsFilter ? additionalOptions : []),
    ])

    return (
        <Dropdown
            resetStates={props.reset}
            id="issue-types-dropdown"
            label={props.label}
            type="select"
            placeholder={props.placeholder}
            options={issueTypes}
            onChange={(option) => props.onChange(option)}
            size={props.size}
            width={props.width}
            env={props.env}
            fullWidth={props.fullWidth}
            isFilter={props.isFilter}
            resetFilter={props.onReset}
            additionalOnChange={(option) => props.additionalOnChange ? props.additionalOnChange(option) : {}}
        />
    )
}

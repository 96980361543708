import { forwardRef, useEffect, useMemo, useState } from "react"

import { useOutsideClick } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import clsx from "clsx"
import { OutlineButton } from "@dnr/ui/buttons"
import { OutlineLink } from "libs/ui/links/src/lib/OutlineLink"

export interface ActionsDropdownOption<TData = void> {
    key: string
    value: string
    displayText: string
    data?: TData
    icon?: string
}

export type ActionsDropdownProps = {
    id: string
    label?: string
    options?: ActionsDropdownOption[]
    direction?: string
    size?: string
    onChange?: (value: ActionsDropdownOption) => void
    fullWidth?: boolean
    resetStates?: number | string
    env?: "portal" | "backoffice"
    tableName: string
    data: any
}

export const ActionsDropdown = forwardRef<HTMLSelectElement, ActionsDropdownProps>((props, ref) => {
    const { t } = useTranslate()
    const [isOpen, setIsOpen] = useState(false)

    const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false)

    const innerRef = useOutsideClick(() => {
        setIsOpen(false)
    })

    const handleChange = (element: ActionsDropdownOption) => {
        // props.onChange(element)
        setIsOptionSelected(true)

        handleIsOpen()
    }

    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        return () => {}
    }, [props.resetStates])

    return (
        <div>
            <div className={clsx(isOpen ? "isOpen" : "")}>
                <OutlineButton
                    size="sml"
                    hasicon
                    icontype="more"
                    // @ts-ignore: allowed
                    ref={innerRef}
                    onClick={() => {
                        handleIsOpen()
                    }}
                />

                <div className={clsx("c-input--select__list", isOpen ? "isOpen" : "")}>
                    <div className="fix-blur">
                        {props.tableName == "ShippingRequestsListDisplay" && props.env == "backoffice" ? (
                            <>
                                <div className={clsx("c-input--select__option")}>
                                    <OutlineLink
                                        size="sml"
                                        hasicon
                                        icontype="view"
                                        haslabel
                                        path={`/app/warehouse/shipping-requests/view/${props.data.shippingId}`}
                                        text={t.common("GENERAL.VIEW")}
                                    />{" "}
                                </div>
                            </>
                        ) : null}

                        {/* {props.options != null && props.options.length > 0
                                ? props.options.map((item) => {
                                      return (
                                          <div
                                              className={clsx(
                                                  'c-input--select__option'
                                              )}
                                              key={item.key}
                                              onClick={() => handleChange(item)}
                                          >
                                              <div className="u-display--flex u-display--flex--ai--center u-display--flex--gap--xsml">
                                                  {item.icon ? (
                                                      <i
                                                          className={`u-icon--stock-status u-icon u-icon--${item.icon}`}
                                                      ></i>
                                                  ) : null}
                                                  <span>
                                                      {item.displayText}
                                                  </span>
                                              </div>
                                          </div>
                                      )
                                  })
                                : t.common('GENERAL.NO_DATA_TO_DISPLAY')} */}
                    </div>
                </div>
            </div>
        </div>
    )
})

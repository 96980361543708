import { lookupService } from "@dnr/data/services"
import { ActionProps, TableDropdown } from "@dnr/ui/dropdowns"
import clsx from "clsx"

interface GenerateProps {
    generateInvoice: (key: string) => void
    excludeTypes?: string[]
    title: string
    disabled?: boolean
    hasIcon?: boolean
    width?: string
    size?: string
}

export const GenerateReportDropdown = (props: GenerateProps) => {
    const reportTypes = lookupService.getShippingReqReportExportTypeId()

    const actionList = () => {
        const lookupEntries = [] as ActionProps[]
        for (const key in reportTypes) {
            if (!(props.excludeTypes != null && props.excludeTypes.includes(reportTypes[key].abrv))) {
                lookupEntries.push({
                    abrv: reportTypes[key].abrv,
                    displayText: reportTypes[key].display,
                    action: () => props.generateInvoice(reportTypes[key].abrv.toString()),
                    enabled: true,
                })
            }
        }

        return lookupEntries
    }

    return (
        <TableDropdown
            list={actionList()}
            itemKey="displayText"
            triggerClassName={clsx(
                `c-btn c-btn--${props.size || "sml"} c-btn--outline`,
                props.hasIcon && "c-btn--icon c-btn--icon--download",
                props.disabled && "c-btn--disabled"
            )}
            actionTrigger={props.title}
            disabled={props.disabled}
            width={props.width}
            itemAbrv="abrv"
        />
    )
}

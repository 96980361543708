import { useState } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"
import { AxiosError } from "axios"
import { enqueueSnackbar } from "notistack"

import { useRequest } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { accountVerificationService } from "@dnr/data/services"
import { ApiResponseBaseModel } from "@dnr/data/models"
import { UnsubscribeError, UnsubscribeLoading, UnsubscribeSuccess } from "@dnr/features/shared/app"

const Unsubscribe = () => {
    const { t } = useTranslate()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [errorData, setErrorData] = useState<string | undefined>()

    const email = searchParams.get("email") as string
    const code = searchParams.get("code") as string
    const groupId = searchParams.get("groupId") as string

    const { error, loading } = useRequest<ApiResponseBaseModel, AxiosError<ApiResponseBaseModel, any>>(
        () => {
            return accountVerificationService.unsubscribeEmail({
                code,
                email,
                groupId: Number(groupId),
            })
        },
        undefined,
        {
            onSuccess: () => {
                setTimeout(() => {
                    navigate("/app/dashboard", {
                        state: {
                            refreshUserData: true,
                        },
                    })
                }, 6000)
            },
            onError: (err) => {
                setErrorData(err.response?.headers["x-trace-id"])
                //@ts-ignore: allowed
                enqueueSnackbar({
                    variant: "detailedError",
                    details: t.common("GENERAL.UNSUBSCRIBE_FAIL_NOTIFICATION"),
                    message: t.common("GENERAL.SUBMIT_FAIL"),
                })
            },
        }
    )

    return (
        <div className="c-login c-login--additional">
            <div className="c-login__aside c-login__aside--additional">
                <div className="c-login__aside__wrapper">
                    <div className="c-login__aside__content c-login__aside__content--additional">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1325 227">
                            <g fill="#fff" fill-rule="evenodd" clip-path="url(#a)" clip-rule="evenodd">
                                <path
                                    d="M405.5-.5h2a189.88 189.88 0 0 1 3.5 37 2059.045 2059.045 0 0 1-1 176 102.324 102.324 0 0 1-2.5 14h-2a47.926 47.926 0 0 1-2.5-12 2041.654 2041.654 0 0 1 0-202 50.294 50.294 0 0 1 2.5-13Z"
                                    opacity=".988"
                                />
                                <path
                                    d="M122.5 57.5c50.334-.167 100.668 0 151 .5a3457.87 3457.87 0 0 1 28.5 60.5 1560.021 1560.021 0 0 0 26-58l2.5-2.5a600.47 600.47 0 0 1 49 0 2538.78 2538.78 0 0 1 1 100.5h-14v-90h-27a1580.51 1580.51 0 0 1-37.5 84 7218.612 7218.612 0 0 1-37.5-83 3800.75 3800.75 0 0 0-151-.5c-8.655.383-16.322 3.216-23 8.5-9.19 21.323-2.19 33.157 21 35.5 18.7.096 37.367.762 56 2 11.355 4.542 14.522 12.375 9.5 23.5-2.778 3.307-6.278 5.474-10.5 6.5-23.667.667-47.333.667-71 0-2.479-3.588-1.812-6.588 2-9l61-1c6.048-.35 7.881-3.183 5.5-8.5a9.454 9.454 0 0 0-2.5-1.5l-45-1c-16.197 1.362-29.698-3.805-40.5-15.5-9.662-22.715-3.162-38.548 19.5-47.5 9.066-1.768 18.066-2.935 27-3.5Z"
                                    opacity=".981"
                                />
                                <path
                                    d="M125.5 73.5c37.336.098 74.669.431 112 1v61h-14v-50c-33.999-.64-67.999-.807-102-.5-7.806 1.78-9.473 5.78-5 12l57 1c28.567 3.325 38.734 18.825 30.5 46.5-8.379 11.019-19.545 16.852-33.5 17.5-28.674.51-57.341.843-86 1-2.942-.94-4.275-2.94-4-6 .11-2.255 1.11-3.922 3-5l82-1c22.881-.536 31.048-11.702 24.5-33.5-6.107-6.152-13.607-8.985-22.5-8.5l-51-1c-16.622-2.905-21.122-11.739-13.5-26.5 6.742-4.974 14.242-7.64 22.5-8Z"
                                    opacity=".987"
                                />
                                <path
                                    d="M1129.5 80.5c10.01-.167 20.01 0 30 .5 21.39 5.07 30.22 18.57 26.5 40.5-2.49 12.019-9.66 19.852-21.5 23.5-15.53 3.608-30.86 2.941-46-2-8.11-4.117-13.28-10.617-15.5-19.5-4.67-23.086 4.17-37.42 26.5-43Zm10 10c26.95-.887 36.11 11.446 27.5 37-3.25 4.659-7.75 7.492-13.5 8.5-30.69 3.312-40.86-9.521-30.5-38.5 4.95-3.811 10.45-6.144 16.5-7Z"
                                    opacity=".994"
                                />
                                <path
                                    d="M435.119 80.063c14.337-.167 28.671 0 43 .5 21.203.868 31.87 11.868 32 32.999.583 17.073-7.417 27.407-24 31-17.33.5-34.663.667-52 .5-.322-21.742.012-43.409 1-65Zm16 11c9.339-.167 18.673 0 28 .5 10.756 2.975 15.422 10.141 14 21.499 1.419 13.037-4.248 20.537-17 22.5-8.327.5-16.66.666-25 .5v-45Z"
                                    opacity=".981"
                                />
                                <path d="M521.979 80h17v65h-17V80Z" />
                                <path
                                    d="M570.079 80.163c2.921-.163 27.423.163 41 2v11a130.713 130.713 0 0 0-30-2.5c-4.27.257-9.744-.83-12 3.5-1.444 2.77-1.333 5.333 0 8a7.293 7.293 0 0 0 3 2 217.296 217.296 0 0 1 37 10.5c8.882 8.374 9.215 17.04 1 26a54.224 54.224 0 0 1-9 4c-16.026 3.074-32.026 2.907-48-.5a72.367 72.367 0 0 1 .5-12c13.265 4.172 26.765 5.005 40.5 2.5 5.366-2.844 6.366-6.844 3-12a822.446 822.446 0 0 0-35-9c-11.237-5.209-13.921-17.5-8.5-24.5 5.421-7 13.579-8.838 16.5-9Z"
                                    opacity=".99"
                                />
                                <path
                                    d="M703.5 80.5a512.454 512.454 0 0 1 53 1.5c15.379 4.592 20.212 14.425 14.5 29.5-3.383 4.613-7.883 7.613-13.5 9l4.5 5a189.534 189.534 0 0 0 11.5 19.5 54.557 54.557 0 0 1-18-.5l-10-17a26.091 26.091 0 0 0-5-3.5c-6.658-.5-13.325-.666-20-.5v22h-17v-65Zm17 11c9.672-.166 19.339 0 29 .5 9.268 5.01 10.268 11.344 3 19a20.64 20.64 0 0 0-6 2c-8.66.5-17.327.666-26 .5v-22Z"
                                    opacity=".997"
                                />
                                <path
                                    d="M818.5 80.5c16.67-.167 33.337 0 50 .5 16.831 1.8 22.665 10.633 17.5 26.5-1.637 2.31-3.804 3.81-6.5 4.5 12.264 4.876 15.431 13.376 9.5 25.5-3.421 3.757-7.588 6.257-12.5 7.5-19.33.5-38.664.667-58 .5v-65Zm17 10c9.339-.166 18.673 0 28 .5 7.883.68 10.716 4.847 8.5 12.5a11.532 11.532 0 0 1-4.5 3.5 210.638 210.638 0 0 1-32 1.5v-18Zm0 27a225.5 225.5 0 0 1 34 1.5c3.691 1.365 5.358 4.032 5 8 .017 4.321-1.983 7.321-6 9-10.995.5-21.995.667-33 .5v-19Z"
                                    opacity=".992"
                                />
                                <path
                                    d="M621.5 80.5h72c.289 3.606-.044 7.106-1 10.5-8.994.5-17.994.666-27 .5v54h-16v-54c-9.006.166-18.006 0-27-.5-.956-3.394-1.289-6.894-1-10.5Z"
                                    opacity=".972"
                                />
                                <path d="M786.5 80.5h17v65h-17v-65Z" />
                                <path
                                    d="M903.5 80.5h16a386.356 386.356 0 0 0 1.5 47c2.746 4.819 6.913 7.653 12.5 8.5 9.181 1.622 17.847.289 26-4a11.532 11.532 0 0 0 3.5-4.5 386.356 386.356 0 0 0 1.5-47h16c.167 15.67 0 31.337-.5 47-2.341 8.012-7.507 13.512-15.5 16.5-15 4.667-30 4.667-45 0-8.02-3.014-13.186-8.514-15.5-16.5-.5-15.663-.667-31.33-.5-47Z"
                                    opacity=".983"
                                />
                                <path
                                    d="M990.5 80.5h72c.29 3.606-.04 7.106-1 10.5-8.99.5-17.99.666-27 .5v54h-16v-54c-9.01.166-18.01 0-27-.5-.956-3.394-1.289-6.894-1-10.5Z"
                                    opacity=".966"
                                />
                                <path d="M1073.5 80.5h16v65h-17c-.32-21.742.01-43.409 1-65Z" />
                                <path
                                    d="M1199.5 80.5a98.4 98.4 0 0 1 14 .5 1367.284 1367.284 0 0 0 43.5 44.5c.33-14.667.67-29.334 1-44a70.938 70.938 0 0 1 16.5-1v65c-5.34.166-10.68-.001-16-.5a2561.575 2561.575 0 0 0-42-43.5 485.59 485.59 0 0 0-1 44h-16v-65Z"
                                    opacity=".998"
                                />
                                <path
                                    d="M247.5 81.5c4.93-.303 9.763.03 14.5 1a7539.144 7539.144 0 0 0 34.5 74.5c-2.47 1.114-5.136 1.614-8 1.5a30.399 30.399 0 0 1-7.5-1 68108.222 68108.222 0 0 1-19-40 838.418 838.418 0 0 1-1.5 40.5c-20.331.5-40.664.667-61 .5v-3c2.516-.255 4.85-1.089 7-2.5a21.53 21.53 0 0 1 6-5c11.328-.5 22.662-.667 34-.5-.322-22.075.011-44.075 1-66Z"
                                    opacity=".977"
                                />
                                <path
                                    d="M343.5 81.5h13v77h-14a840.27 840.27 0 0 0-.5-41c-5.846 13.689-11.679 27.355-17.5 41h-15a1310.293 1310.293 0 0 1 34-77Z"
                                    opacity=".975"
                                />
                                <path d="M46.5 135.5a60.93 60.93 0 0 1 11 .5c3.045 2.448 3.545 5.281 1.5 8.5-4.45 1.3-8.95 1.3-13.5 0-1.363-3.187-1.03-6.187 1-9Z" />
                                <path
                                    d="M67.5 135.5c7.008-.166 14.008 0 21 .5 4.693 2.689 5.027 5.689 1 9-8 .667-16 .667-24 0-3.034-4.026-2.367-7.193 2-9.5Z"
                                    opacity=".999"
                                />
                                <path
                                    d="M-.5 161.5v-8l3-1.5c7-.667 14-.667 21 0 4.247 4.163 3.914 7.829-1 11-7.838 1.018-15.505.518-23-1.5Z"
                                    opacity=".994"
                                />
                                <path
                                    d="M32.5 151.5a60.93 60.93 0 0 1 11 .5c2.004 2.841 2.504 6.007 1.5 9.5-3.918 2.274-8.084 2.607-12.5 1-2.651-3.692-2.651-7.359 0-11Z"
                                    opacity=".973"
                                />
                                <path d="M53.5 151.5c7.008-.166 14.008 0 21 .5 4.248 4.163 3.914 7.829-1 11a66.397 66.397 0 0 1-22-1c-2.96-4.265-2.294-7.765 2-10.5Z" />
                            </g>
                            <defs>
                                <clipPath id="a">
                                    <path fill="#fff" d="M0 0h1325v227H0z" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>

            <div className="c-login__main c-login__main--additional">
                <div className="u-type--center">
                    {loading ? (
                        <UnsubscribeLoading />
                    ) : error ? (
                        <UnsubscribeError errorData={errorData} />
                    ) : (
                        <UnsubscribeSuccess />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Unsubscribe

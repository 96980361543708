import { useGlobalLoaderActions } from "@dnr/features/shared/loader"
import { defaultLogger } from "@dnr/util/logger"
import { AxiosError } from "axios"
import React, { useCallback, useEffect, useRef, useState } from "react"

export type Options = {
    abortController: AbortController
}

export type RequestOptions<TData, TError> = {
    /**
     * Used for handling success response.
     * Good place for displaying notifications
     */
    onSuccess?: (response: TData) => void
    /**
     * Used for handling error response.
     * Good place for displaying notifications
     */
    onError?: (err: TError) => void

    /**
     * Used for debouncing request. In ms.
     *
     * Request will be debounced if value is greater than 0
     */
    debounce?: number
}

/**
 * Used for sending requests
 * @param callback callback to invoke. It receives abortController through parameters. Use it to cancel pending request in case component unmounts.
 * @param deps If present, effect will only activate if the values in the list change.
 * @param subscribeToChannel If present, request will subscribe to a given broadcast channel name.
 * @returns loading and initialized state
 */
export function useRequest<TData, TError extends AxiosError>(
    callback: (options: Options) => Promise<TData>,
    deps: React.DependencyList = [],
    requestOptions: RequestOptions<TData, TError> = {}
) {
    const [response, setResponse] = useState<TData>()
    const [error, setError] = useState<TError>()
    const [initialized, setInitialized] = useState(false)
    const [loading, setLoading] = useState(false)

    const debounceRef = useRef<any>()

    const abortControllerRef = useRef<AbortController>(new AbortController())

    const abortRequest = useCallback(() => {
        abortControllerRef.current.abort()
        abortControllerRef.current = new AbortController()
    }, [abortControllerRef])

    const { setLoading: setGlobalLoading } = useGlobalLoaderActions()

    const run = useCallback(async () => {
        setLoading(true)
        setGlobalLoading(true)

        try {
            const response = await callback({
                abortController: abortControllerRef.current,
            })

            setResponse(response)

            if (requestOptions.onSuccess) {
                requestOptions.onSuccess(response)
            }
        } catch (err: any) {
            setError(err)
            defaultLogger.logError("error", err)
            if (requestOptions.onError) {
                requestOptions.onError(err)
            }
        } finally {
            setLoading(false)
            setGlobalLoading(false)
            setInitialized(true)
        }
    }, deps)

    useEffect(() => {
        if (requestOptions.debounce != null && requestOptions.debounce > 0) {
            if (debounceRef.current != null) {
                clearTimeout(debounceRef.current)
                debounceRef.current = null
            }

            debounceRef.current = setTimeout(async () => {
                await run()
                debounceRef.current = null
            }, requestOptions.debounce)
        } else {
            // just run function straight away
            run()
        }

        return () => {
            abortRequest()
            setInitialized(false)
            setLoading(false)
            setError(undefined)
            setResponse(undefined)
        }
    }, [...deps])

    return {
        initialized,
        loading,
        response,
        error,
    }
}

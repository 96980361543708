import { DetailedHTMLProps, InputHTMLAttributes, useMemo } from "react"

import { FieldValues, Path } from "react-hook-form"

import { FormDropdown, FormDropdownOption } from "@dnr/ui/forms"
import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

interface Props<TForm> extends DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    placeholder?: string
    name: Path<TForm>
    selectedValue?: string
    onUpdate: (value: FormDropdownOption) => void
    disabled?: boolean
}

export const TopicCategoryFormDropdown = <TForm extends FieldValues>(props: Props<TForm>) => {
    const topicCategories = lookupService.getTopicCategoryId()
    const topicCategoryId = lookupService.topicCategoryIds
    const { t } = useTranslate()

    const topicCategoryOptions = useMemo(() => {
        const lookupEntries = [] as FormDropdownOption[]
        for (const key in topicCategories) {
            lookupEntries.push({
                displayText: topicCategories[key].display,
                key: topicCategories[key].id.toString(),
                value: topicCategories[key].id.toString(),
                icon: topicCategories[key].icon,
                label: topicCategories[key].display,
            })
        }
        return lookupEntries
    }, [])

    const getDefaultValue = useMemo(() => {
        const filteredOptions = topicCategoryOptions.filter((i) => i.key === topicCategoryId["undefined"].id.toString())
        return filteredOptions[0]
    }, [topicCategoryOptions])

    return (
        <FormDropdown<TForm>
            key={props.name}
            disabled={props.disabled}
            label={t.common("PRODUCT.CATEGORY")}
            options={topicCategoryOptions}
            color="light"
            selectedValue={props.selectedValue || getDefaultValue.key.toString()}
            {...props}
            placeholder={props.placeholder || getDefaultValue.displayText}
            required={props.required}
        />
    )
}

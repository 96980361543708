import React from "react"
import { useGlobalLoader } from "./useGlobalLoader"

export const GlobalLoader: React.FC = () => {
    const { loading } = useGlobalLoader()

    if (!loading) {
        return null
    }

    return <div className="c-loader__main">Loading...</div>
}

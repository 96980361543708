import { createPortal } from "react-dom"
import { BaseButton } from "@dnr/ui/buttons"

interface Action {
    label: string
    btnType: string
    action: (e: any) => void
    disabled?: boolean
}

interface FloatingCardProps {
    itemsSelected: number
    actionReset?: () => void
    actions: Action[]
    locationOfCard?: 'left' | 'right'
}

export const FloatingCard = (props: FloatingCardProps) => {
    const layoutWrapper = document.querySelector(".c-container--base")

    const cardStyle: React.CSSProperties = {
        left: props.locationOfCard === 'left' ? '35%' : "65%",
        bottom: "40px",
        maxWidth: "435px"
    };

    return createPortal(
        <div
            className="c-floating-card"
            style={props.locationOfCard ? cardStyle : undefined}
        >
            <div className="u-display--flex u-display--flex--ai--center u-display--flex--gap--sml">
                <p className="u-type--sml">
                    {props.itemsSelected} {props.itemsSelected > 1 ? "items" : "item"} selected
                </p>
                {props.actionReset ? (
                    <i onClick={props.actionReset} className="u-icon u-icon--sml u-icon--close u-cursor--pointer"></i>
                ) : null}
            </div>

            <div className="u-display--flex u-display--flex--gap--sml">
                {props.actions.map((action: Action) => {
                    return (
                        <BaseButton
                            size="sml"
                            className={action.btnType}
                            onClick={action.action}
                            disabled={action.disabled}
                        >
                            {action.label}
                        </BaseButton>
                    )
                })}
            </div>
        </div>,
        layoutWrapper ? layoutWrapper : document.body
    )
}



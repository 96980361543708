import { useState } from "react"

import { Deal } from "@dnr/features/shared/deals"
import { useTranslate } from "@dnr/localization"
import { DealGridItemBase } from "@dnr/data/models"
import { DealerDealGridItemBase } from "@dnr/features/portal/deals"

import { IconTag } from "./IconTag"
import { BaseTag } from "./BaseTag"
import clsx from "clsx"

interface TagGroupProps {
    isGridItemView?: boolean
    deal: Deal | DealGridItemBase | DealerDealGridItemBase

    isGridRunnerView?: boolean
    isGridDealerView?: boolean
    isSingleView?: boolean
}

type Tags = {
    tagAbrv: string
    show: boolean
    displayText: string
}[]

export const TagGroup = ({ deal, isGridItemView, isGridDealerView, isGridRunnerView, isSingleView }: TagGroupProps) => {
    const { t } = useTranslate()

    const [tags] = useState<Tags>([
        { tagAbrv: "hot", show: isGridItemView ? deal.isHot : false, displayText: "DEAL.HOT" },
        { tagAbrv: "outbid", show: deal.isOutbid, displayText: "GENERAL.OUTBID" },
        { tagAbrv: "sale", show: deal.onSaleNow, displayText: "DEAL.ON_SALE" },
        { tagAbrv: "profit", show: deal.heavyProfit, displayText: "DEAL.HEAVY_PROFIT" },
        { tagAbrv: "coupon", show: deal.couponRequired, displayText: "DEAL.COUPON_REQ" },
        { tagAbrv: "membership", show: deal.membershipRequired, displayText: "DEAL.MEMBERSHIP_REQ" },
    ])

    return (
        <>
            {deal.isHot && !isGridItemView ? (
                <div className="c-cards__badge c-cards__badge--tl">
                    <IconTag tagtype="hot" iconType="hot" iconSize="base" tagtitle={t.common("DEAL.HOT")} />
                </div>
            ) : null}
            {deal.isOutbid && !isGridItemView ? (
                <div
                    className={clsx(
                        "c-cards__badge",
                        deal.isHot ? "c-cards__badge--tl--tertiary" : "c-cards__badge--tl"
                    )}
                >
                    <BaseTag tagtype="other-3" tagtitle="Outbid" />
                </div>
            ) : null}

            {tags.some((tag) => tag.show) ? (
                <div
                    className={`u-display--flex u-display--flex--gap--xsml u-mt--sml--2 u-display--flex--wrap ${
                        isGridItemView ? "" : "c-cards__badge c-cards__badge--bl"
                    }`}
                >
                    {tags.map((tag) => {
                        if ((isGridRunnerView || isGridDealerView) && tag.show) {
                            return (
                                <div
                                    key={tag.displayText}
                                    className={`c-tag c-tag--icon ${
                                        tag.tagAbrv === "hot"
                                            ? "c-tag--hot"
                                            : tag.tagAbrv === "outbid"
                                            ? "c-tag--other-3"
                                            : "c-tag--other"
                                    }`}
                                >
                                    {tag.tagAbrv != "hot" ? null : (
                                        <i
                                            className={`u-icon u-icon--sbase u-icon--${tag.tagAbrv} u-mr--sml--1 u-mb--sml--05`}
                                        />
                                    )}

                                    {t.common(tag.displayText)}
                                </div>
                            )
                        }
                        if (isSingleView && tag.show && tag.tagAbrv != "hot" && tag.tagAbrv != "outbid") {
                            return (
                                <div key={tag.displayText} className={`c-tag c-tag--icon c-tag--other`}>
                                    {t.common(tag.displayText)}
                                </div>
                            )
                        } else return null
                    })}
                </div>
            ) : null}
        </>
    )
}

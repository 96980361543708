import { createContext, useContext } from "react"

export const PortalEnvContext = createContext<"smd" | "main-portal" | undefined>(undefined)

/**
 * Used for consuming portal environemnt context.
 * @returns app context
 */
export const usePortalEnvContext = () => {
    const app = useContext(PortalEnvContext)

    if (!app) {
        throw new Error("usePortalEnvContext must be used with a PortalEnvContext")
    }

    return app
}
import clsx from "clsx"
import React from "react"

import { AccentButton, PrimaryButton, SecondaryButton } from "@dnr/ui/buttons"

import { useTranslate } from "@dnr/localization"

export type TableEmptyStateProps = {
    size?: string
    isInnerTable?: boolean
}

export const TableEmptyState: React.FC<TableEmptyStateProps> = (props) => {
    const { t } = useTranslate()
    return (
        <div
            className={clsx(
                `c-empty c-empty--np u-mt--sml--15 ${props.isInnerTable ? "u-mb--sml--20" : "u-mb--sml--3"}`
            )}
        >
            <img src={clsx(`../../../../assets/img/empty-states/deals.png`)} className={`u-mb--sml--2 c-empty--sml`} />
            <div className="u-mb--sml--6">
                <p className="c-empty__title">Oops!</p>

                <p className="c-empty__text">Looks like there is no data to display!</p>
            </div>
        </div>
    )
}

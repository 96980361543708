import { useEffect, useState } from "react"
import { RangeSlider } from "rsuite"

export type MinMaxValues = { min: number; max: number }

interface SliderProps {
    minMaxValues: MinMaxValues
    defaultValues: [number, number]
    handleChange: (values: number[]) => void
}

export const Slider = ({ minMaxValues, handleChange, defaultValues }: SliderProps) => {
    const [values, setValues] = useState<[number, number]>([minMaxValues.min, minMaxValues.max])

    useEffect(() => {
        setValues([minMaxValues.min, minMaxValues.max])
    }, [minMaxValues])

    return (
        <RangeSlider
            min={defaultValues[0]}
            max={defaultValues[1]}
            onChange={(value) => {
                setValues(value)
                handleChange(value)
            }}
            value={values}
        />
    )
}

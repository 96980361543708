import { observer } from "mobx-react"
import { PrivateRoute } from "@dnr/ui/navigation"
import { AuthStore } from "@dnr/state/membership"

interface AuthenticationGuardProps {
    children: JSX.Element
    authStore: AuthStore
}

export const AuthenticationGuard = observer(({ children, authStore }: AuthenticationGuardProps) => (
    <PrivateRoute authStore={authStore} navigateTo="/">
        {children}
    </PrivateRoute>
))

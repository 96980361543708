import { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { FieldValues, Path } from "react-hook-form"

import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"
import { FormDropdown, FormDropdownOption } from "@dnr/ui/forms"

export type WarehouseTypeFormProps<TForm> = {
    onUpdate: (selected: FormDropdownOption) => void
    name: Path<TForm>
} & DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

export const WarehouseTypeFormDropdown = <TForm extends FieldValues>(props: WarehouseTypeFormProps<TForm>) => {
    const { t } = useTranslate()

    const wType = lookupService.warehouseTypeByAbrv
    const warehouseTypes = () => {
        const options: FormDropdownOption[] = []
        for (const key in wType) {
            options.push({
                key: wType[key].id,
                displayText: wType[key].display,
                value: wType[key].id,
            } as FormDropdownOption)
        }
        return options
    }

    return (
        <div className="u-mb--sml--3">
            <FormDropdown<TForm>
                key={props.name}
                label="WAREHOUSE_MANAGEMENT.TYPE"
                type="default"
                placeholder={t.form("WAREHOUSE_MANAGEMENT.TYPE_PLACEHOLDER")}
                options={warehouseTypes()}
                color="light"
                {...props}
                onUpdate={(option) => props.onUpdate(option)}
                required={props.required || false}
            />
        </div>
    )
}

import React from "react"
import { observer } from "mobx-react"
import { IsLoggedIn } from "@dnr/ui/navigation"
import { rootStore } from "../../stores"

type Props = {
    children: JSX.Element
}

export const IsLoggedInGuard: React.FC<Props> = observer((props) => (
    <IsLoggedIn isAuthenticated={rootStore.authStore.isAuthenticated} navigateTo="/app/dashboard">
        {props.children}
    </IsLoggedIn>
))

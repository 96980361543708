import { useEffect, useMemo, useRef, useState } from "react"

import { DropdownProps } from "@dnr/ui/dropdowns"
import { useOutsideClick } from "@dnr/hooks"
import { Input, MinMaxValues, Slider } from "@dnr/ui/inputs"
import { ButtonGroup, OutlineButton, PrimaryButton } from "@dnr/ui/buttons"
import { useTranslate } from "@dnr/localization"

interface CommissionFilterDropdownProps extends DropdownProps {
    appliedFilter: (minMaxValues: { min: number; max: number; type: "$" | "%" }) => void
    reset?: number
    onReset?: () => void
}

export const CommissionFilterDropdown = (props: CommissionFilterDropdownProps) => {
    const { t } = useTranslate()
    const [isOpen, setIsOpen] = useState(false)
    const [sliderType, setType] = useState<"$" | "%">("%")
    const [displayText, setDisplayText] = useState(props.placeholder)

    const [currentPercentValues, setCurrentPercentValues] = useState({ min: -100, max: 100 })
    const [currentCurrencytValues, setCurrentCurrencyValues] = useState({ min: -1000, max: 1000 })

    const [minValue, setMinValue] = useState<string>()
    const [maxValue, setMaxValue] = useState<string>()

    const [reset, triggerReset] = useState<boolean>()
    const [isError, setError] = useState<boolean>(false)

    useEffect(() => {
        handleReset()
    }, [props.reset])

    useEffect(() => {
        if (minValue === undefined || maxValue === undefined) return
        setError(
            parseMinValue(minValue) > parseMaxValue(maxValue) || parseMinValue(minValue) === parseMaxValue(maxValue)
        )
    }, [minValue, maxValue])

    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    const minInputRef = useRef<HTMLInputElement>(null)
    const maxInputRef = useRef<HTMLInputElement>(null)

    const innerRef = useOutsideClick(() => {
        setIsOpen(false)
    })

    const valueDisplayer = (type: "$" | "%", value: number) => {
        return `${type === "$" ? type : ""} ${value} ${type === "%" ? type : ""}`
    }

    const setSliderType = (type: "$" | "%") => {
        setError(false)
        if (minInputRef.current !== null && maxInputRef.current !== null) {
            minInputRef.current.value = valueDisplayer(
                type,
                type === "%" ? currentPercentValues.min : currentCurrencytValues.min
            )
            maxInputRef.current.value = valueDisplayer(
                type,
                type === "%" ? currentPercentValues.max : currentCurrencytValues.max
            )
            setMinValue(
                valueDisplayer(sliderType, type === "%" ? currentPercentValues.min : currentCurrencytValues.min)
            )
            setMaxValue(
                valueDisplayer(sliderType, type === "%" ? currentPercentValues.max : currentCurrencytValues.max)
            )
        }
        setType(type)
    }

    const getMinMaxValues = useMemo<MinMaxValues>(() => {
        return sliderType === "%"
            ? { min: currentPercentValues.min, max: currentPercentValues.max }
            : { min: currentCurrencytValues.min, max: currentCurrencytValues.max }
    }, [sliderType, reset])

    const getDefaultValues = useMemo<[number, number]>(() => {
        return sliderType === "%" ? [-100, 100] : [-1000, 1000]
    }, [sliderType])

    const handleChange = (values: number[]) => {
        if (sliderType === "%") setCurrentPercentValues({ min: values[0], max: values[1] })
        if (sliderType === "$") setCurrentCurrencyValues({ min: values[0], max: values[1] })

        if (minInputRef.current !== null && maxInputRef.current !== null) {
            minInputRef.current.value = valueDisplayer(sliderType, values[0])
            setMinValue(valueDisplayer(sliderType, values[0]))
            maxInputRef.current.value = valueDisplayer(sliderType, values[1])
            setMaxValue(valueDisplayer(sliderType, values[1]))
        }
    }

    const placeholderText = useMemo(() => {
        if (displayText.length === 0 || displayText == props.placeholder) {
            return props.placeholder
        } else {
            return displayText
        }
    }, [displayText])

    const applyFilter = () => {
        if (isError) return
        props.appliedFilter({
            min: Number(minInputRef.current?.value.replace(/[^0-9.-]/g, "")),
            max: Number(maxInputRef.current?.value.replace(/[^0-9.-]/g, "")),
            type: sliderType,
        })
        setDisplayText(`From ${minValue} to ${maxValue}`)
        handleIsOpen()
    }

    const handleReset = () => {
        triggerReset(!reset)
        setDisplayText(props.placeholder)
        if (props.onReset) props.onReset()
        if (sliderType === "%") setCurrentPercentValues({ min: -100, max: 100 })
        if (sliderType === "$") setCurrentCurrencyValues({ min: -1000, max: 1000 })

        if (minInputRef.current !== null && maxInputRef.current !== null) {
            minInputRef.current.value = valueDisplayer(sliderType, sliderType === "%" ? -100 : -1000)
            setMinValue(valueDisplayer(sliderType, sliderType === "%" ? -100 : -1000))
            maxInputRef.current.value = valueDisplayer(sliderType, sliderType === "%" ? 100 : 1000)
            setMaxValue(valueDisplayer(sliderType, sliderType === "%" ? 100 : 1000))
        }
        setIsOpen(false)
    }

    const parseMinValue = (value: string) => {
        const parsedMinValue = value.replace(/\s/g, "").match(/-?\d+(\.\d+)?/)
        const result = parsedMinValue !== null ? Number(parsedMinValue[0]) : 0
        return result
    }

    const parseMaxValue = (value: string) => {
        const parsedMinValue = value.replace(/\s/g, "").match(/-?\d+(\.\d+)?/)
        const result = parsedMinValue !== null ? Number(parsedMinValue[0]) : 0
        return result
    }

    return (
        <div
            className={`u-position--rel ${
                props.width === "full" && props.env === "backoffice" ? "u-display--flex-col-fill" : null
            } ${props.fullWidth ? "fullWidth" : ""}`}
            //@ts-ignore: allowed
            ref={innerRef}
        >
            {props.label != null ? (
                <div className="u-mb--sml--1">
                    <label className="c-input__label">{props.label} </label>
                </div>
            ) : null}
            <div
                className={`c-input c-input--${props?.size} c-input--${props?.width} c-input--select ${
                    props.color === "light" ? "c-input--select--light" : null
                }`}
                onClick={handleIsOpen}
            >
                <div
                    className={`c-input--select__text ${
                        displayText != props.placeholder ? " c-input--select__text--selected" : null
                    }`}
                >
                    {placeholderText}
                </div>
            </div>{" "}
            <div
                className={`c-input--select__content ${
                    props.type === "up" ? "c-input--select__list--top" : "c-input--select__list--bottom"
                } ${isOpen ? "isOpen" : ""}`}
            >
                <div className="c-input--select__list u-display--flex u-display--flex--fd--column u-display--flex--gap--med u-display--flex--ai--center">
                    <div className="c-tabs c-tabs--tertiary">
                        <div
                            className={`c-tabs__item ${
                                sliderType === "$" ? "active" : ""
                            } c-tabs__item--full c-tabs__item--xsml`}
                            onClick={() => setSliderType("$")}
                        >
                            $
                        </div>

                        <div
                            className={`c-tabs__item ${
                                sliderType === "%" ? "active" : ""
                            } c-tabs__item--full c-tabs__item--xsml`}
                            onClick={() => setSliderType("%")}
                        >
                            %
                        </div>
                    </div>

                    <Slider
                        defaultValues={getDefaultValues}
                        handleChange={(value) => handleChange(value)}
                        minMaxValues={getMinMaxValues}
                    />

                    <ButtonGroup>
                        <Input
                            defaultValue={`${sliderType} ${getMinMaxValues.min}`}
                            inputsize="xxsml"
                            iserror={isError}
                            onChange={(ev) => setMinValue(valueDisplayer(sliderType, parseMinValue(ev.target.value)))}
                            ref={minInputRef}
                        />
                        -{" "}
                        <Input
                            defaultValue={`${sliderType} ${getMinMaxValues.max}`}
                            iserror={isError}
                            inputsize="xxsml"
                            onChange={(ev) => setMaxValue(valueDisplayer(sliderType, parseMaxValue(ev.target.value)))}
                            ref={maxInputRef}
                        />
                    </ButtonGroup>
                    <hr className="c-separator--color-light u-mt--sml--0 u-mb--sml--0" />
                    <div className="u-display--width--full">
                        <ButtonGroup style="separated">
                            <OutlineButton onClick={handleReset} size="xsml">
                                {t.common("GENERAL.RESET_FILTER")}
                            </OutlineButton>
                            <PrimaryButton disabled={isError} onClick={applyFilter} size="xsml">
                                {t.common("GENERAL.APPLY")}
                            </PrimaryButton>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}

import { Path, useFormContext } from "react-hook-form"
import * as yup from "yup"

import { FormInput } from "./FormInput"

import { useTranslate } from "@dnr/localization"
import { useDebounceEffect } from "@dnr/hooks"
import { accountVerificationService } from "@dnr/data/services"
import { RegisterBaseModel } from "@dnr/data/models"

import { FormCheckbox } from "./FormCheckbox"

interface FormPhoneNumberInputProps {
    name: Path<RegisterBaseModel>
    userToExclude?: string
    phoneNumberTerms?: {
        name: Path<any>
    }
    checkNotRequired?: boolean
}

export const phoneNumberRegex = /^\+(\(\d{1,4}\)\d+|\d{1,4}\d+)$/
export const phoneNumberValidationSchema = yup
    .string()
    .min(12, "REGISTER.PHONE_NUMBER_FORMAT_NOT_VALID")
    .required("GENERAL.REQUIRED")
    .matches(phoneNumberRegex, "REGISTER.PHONE_NUMBER_FORMAT_NOT_VALID")
    .when("isPhoneNumberInvalid", {
        is: true,
        then: yup.string().test({
            name: "isValid",
            message: "REGISTER.PHONE_NUMBER_NOT_VALID",
            test: () => false,
        }),
    })

export const FormPhoneNumberInput = ({
    name,
    userToExclude,
    phoneNumberTerms,
    checkNotRequired,
}: FormPhoneNumberInputProps) => {
    const { t } = useTranslate()
    const form = useFormContext<RegisterBaseModel>()

    useDebounceEffect(
        async () => {
            if (checkNotRequired) return
            form.setValue("isPhoneNumberInvalid", false)
            const phoneNumber = form.watch("phoneNumber")
            if (phoneNumber.length > 1 && phoneNumber.match(phoneNumberRegex)) {
                try {
                    await accountVerificationService.validatePhoneNum(phoneNumber.replace("+", "%2B"), userToExclude)
                } catch (err) {
                    form.setValue("isPhoneNumberInvalid", true)
                } finally {
                    form.trigger("phoneNumber")
                }
            }
        },
        [form.watch("phoneNumber")],
        1000
    )

    return (
        <div>
            <FormInput<RegisterBaseModel>
                label="REGISTER.PHONE_NUMBER"
                placeholder="Eg. +01123123123"
                name={name}
                inputsize="med"
                inputtype="text"
                required
            />
            {phoneNumberTerms ? (
                <div className="u-mb--sml--5">
                    <FormCheckbox<any>
                        type="checkbox"
                        inputtype="check"
                        label="REGISTER.PHONE_NUMBER_TERMS"
                        name={phoneNumberTerms?.name}
                        required
                    />
                </div>
            ) : null}
            <div className="u-mt--sml--1 c-cards__content c-cards__content--white">
                <ul className="c-cards__list">
                    <li>{t.common("REGISTRATION.PHONE.FORMAT_1")}</li>
                    <li>{t.common("REGISTRATION.PHONE.FORMAT_2")}</li>
                    <li>{t.common("REGISTRATION.PHONE.MIN_CHARACTERS")}</li>
                </ul>
            </div>
        </div>
    )
}

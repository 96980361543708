import { RefObject, useRef } from "react"

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useFocus = (): [RefObject<HTMLInputElement>, () => void] => {
    const htmlElRef = useRef<HTMLInputElement>(null)

    const setFocus = () => {
        if (htmlElRef.current) {
            htmlElRef.current.focus()
        }
    }

    return [htmlElRef, setFocus]
}

import React, { useState } from "react"

import { useOutsideClick } from "@dnr/hooks"
import { Input } from "@dnr/ui/inputs"

export type TreeDropdownProps = {
    name: string
    searchCallback: (query?: string) => void
}

export const TreeDropdown: React.FC<TreeDropdownProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")
    const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false)
    const updateSearchQuery = (query: string) => {
        setSearchQuery(query)
    }

    const innerRef = useOutsideClick(() => {
        setIsOpen(false)
    })

    const handleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div
            className={`c-input c-input--base c-input--select ${isOpen ? "c-input--select--open" : ""}`}
            //@ts-ignore
            ref={innerRef}
        >
            <div
                className={`${
                    isOptionSelected ? "c-input--select__text c-input--select__text--selected" : "c-input--select__text"
                }`}
                onClick={handleIsOpen}
            >
                {props.name}
            </div>
            <div
                className={`c-input--select__list c-input--select__list--bottom c-input--select__list--lrg ${
                    isOpen ? "isOpen" : ""
                }`}
            >
                <div className="fix-blur">
                    <Input
                        type="text"
                        name="searchQuery"
                        value={searchQuery}
                        onChange={(ev) => updateSearchQuery(searchQuery)}
                    />
                </div>
            </div>
        </div>
    )
}

import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-material.css"

import "ag-grid-enterprise"

import { TableEmptyState } from "@dnr/ui/empty-states"


export type MobxTableViewEmptyStateProps = {
    isInnerTable?: boolean
}
export const MobxTableViewEmptyState: React.FC<MobxTableViewEmptyStateProps> = (props) => {
    return (
        <div className="ag-overlay-loading-center">
            <TableEmptyState size="base" isInnerTable={props.isInnerTable}/>
        </div>
    )
}

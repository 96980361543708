import { enqueueSnackbar } from "notistack"

import { ApiResponseModel } from "@dnr/data/models"
import { lookupService } from "@dnr/data/services"
import { Topic, topicService } from "@dnr/features/shared/messaging"
import { useSubmitter } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { Link } from "react-router-dom"

interface UnsubscribeErrorProps {
    errorData?: string
}

export const UnsubscribeError = ({ errorData }: UnsubscribeErrorProps) => {
    const { t } = useTranslate()

    const { onSubmit, loading } = useSubmitter<undefined, ApiResponseModel<Topic>>({
        requestCallback: () => {
            return topicService.createTopic({
                categoryId: lookupService.topicCategoryIds["undefined"].id,
                description: t.common("GENERAL.ERROR_ON_UNSUBSCRIBE"),
                statusId: lookupService.topicStatusIds["new"].id,
                subject: t.common("GENERAL.SUBSCRIPTION_ERROR"),
                title: t.common("GENERAL.SUBSCRIPTION_ERROR"),
                typeId: lookupService.topicTypeIds["problem"].id,
                message: t.common("GENERAL.SUBSCRIPTION_SUPPORT_MESSAGE", { traceId: errorData || "-" }),
            })
        },
        onSuccess: () => {
            enqueueSnackbar({
                variant: "success",
                message: t.common("TICKETS.SUPPORT_TICKET_CREATE_SUCCESS"),
            })
        },
    })

    return (
        <>
            <img src="../../assets/img/empty-states/notification-error.png" className="c-login__header__img" />
            <div className="c-login__title">
                <div className="u-display--flex u-display--flex--ai--center u-display--flex--gap--sml u-display--flex--fd--column">
                    <h2>{t.common("GENERAL.OOPS")}</h2>
                    <h3 className="c-login__subtitle u-mb--sml--3">{t.common("GENERAL.AN_ERROR_HAS_OCCURRED")}</h3>
                </div>

                <p className="c-login__subtext">{t.common("GENERAL.UNSUBSCRIBE_FAIL")}</p>
            </div>{" "}
            <hr className="c-separator--color-light u-mt--sml--5 u-mb--sml--6" />
            <div className="u-display--flex u-display--flex--fd--column u-mt--sml--2">
                <Link to={"/app/dashboard"} className="c-btn c-btn--base c-btn--secondary c-btn--full">
                    {t.common("GENERAL.GO_TO_HOMEPAGE")}
                </Link>

                <button
                    className={`u-mt--sml--3 c-btn c-btn--basic c-btn--basic--secondary c-btn--basic--underline u-type--sml u-display--flex ${
                        loading ? `c-btn--loading` : ""
                    }`}
                    disabled={loading}
                    onClick={() => onSubmit(undefined)}
                >
                    {t.common("GENERAL.CONTACT_SUPPORT")}
                </button>
            </div>
        </>
    )
}

import { useEffect, useState } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { useTranslate } from "@dnr/localization"

interface ConditionDropdownProps {
    label?: string
    reset?: number
    placeholder?: string
    selectedValue?: string
    inputSize?: string
    onChange: (selected: DropdownOption) => void
    size?: string
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
    onReset?: () => void
    trackingsFilter?: boolean
}

export const ConditionDropdown = (props: ConditionDropdownProps) => {
    const { t } = useTranslate()

    const [conditionTypes] = useState<DropdownOption[]>([
        {
            displayText: t.common("BACKOFFICE.WAREHOUSE.NEW"),
            value: "new",
            key: "1",
        },
        {
            displayText: t.common("BACKOFFICE.WAREHOUSE.DAMAGED"),
            value: "damaged",
            key: "2",
        },
        {
            displayText: t.common("BACKOFFICE.WAREHOUSE.BOTH_CONDITIONS"),
            value: "both",
            key: "3",
        }
    ])


    return (
        <Dropdown
            resetStates={props.reset}
            id="condition-types-dropdown"
            label={props.label}
            type="select"
            placeholder={props.placeholder}
            options={conditionTypes}
            onChange={(option) => props.onChange(option)}
            size={props.size}
            selectedValue={props.selectedValue}
            width={props.width}
            env={props.env}
            fullWidth={props.fullWidth}
            isFilter={props.isFilter}
            resetFilter={props.onReset}
        />
    )
}

import React, { useMemo } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

interface TopicCategoryDropdownProps {
    placeholder: string
    selectedValue?: string
    category?: string
    onChange: (selected: DropdownOption) => void
    label?: string
    reset?: number
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
    onReset?: () => void
}

export const TopicCategoryDropdown = (props: TopicCategoryDropdownProps) => {
    const { t } = useTranslate()
    const topicCategories = lookupService.getTopicCategoryId()

    const topicCategoryOptions = useMemo(() => {
        const lookupEntries = [] as DropdownOption[]
        for (const key in topicCategories) {
            lookupEntries.push({
                displayText: topicCategories[key].display,
                key: topicCategories[key].id.toString(),
                value: topicCategories[key].id.toString(),
                icon: topicCategories[key].icon,
            })
        }
        return lookupEntries
    }, [])

    return (
        <div className={`${props.env == "backoffice" ? "u-display--flex-col-fill" : ""}`}>
            {props.label != null ? (
                <div className="u-mb--sml--1">
                    <label className="c-input__label">{t.form(props.label)}</label>
                </div>
            ) : null}
            <Dropdown
                resetStates={props.reset}
                selectedValue={props.selectedValue}
                id="topic-category"
                type="select"
                size="base"
                width={props.width}
                placeholder={props.placeholder}
                onChange={(option) => props.onChange(option)}
                options={topicCategoryOptions}
                fullWidth={props.fullWidth}
                isFilter={props.isFilter}
                resetFilter={props.onReset}
            />
        </div>
    )
}

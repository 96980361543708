import { action, makeObservable, observable } from "mobx"

import { IFilter } from "@dnr/data/models"

import { PagingStore } from "./PagingStore"
import { ColumnApi, GridApi } from "ag-grid-community"

export class TableStore<TFilter extends IFilter> {
    pagingStore = {} as PagingStore
    additionalFilter = {} as TFilter
    lastReloadOn = new Date()
    gridApi?: GridApi<any>
    columnApi?: ColumnApi
    displayedGridItemsCount?: number

    constructor(params?: { pageSize?: number, pageNumber?: number, isPagingDisabled?: boolean | undefined, additionalFilter?: TFilter }) {
        this.pagingStore = new PagingStore(params?.pageNumber || 1, params?.pageSize || 10, params?.isPagingDisabled)
        this.additionalFilter = params?.additionalFilter || {} as TFilter

        makeObservable(this, {
            pagingStore: observable,
            displayedGridItemsCount: observable,
            gridApi: observable,
            columnApi: observable,
            additionalFilter: observable,
            lastReloadOn: observable,
            setPagingStore: action,
            setDisplayedItemsCount: action,
            setAgGridApi: action,
            setFilter: action,
            triggerReload: action,
        })
    }

    setDisplayedItemsCount(value: number) {
        this.displayedGridItemsCount = value
    }

    setPagingStore(value: PagingStore) {
        this.pagingStore = value
    }

    setAgGridApi(gridApi: GridApi<any>, columnApi: ColumnApi) {
        this.gridApi = gridApi
        this.columnApi = columnApi
    }

    setFilter(value: TFilter) {
        this.additionalFilter = value
    }


    triggerReload() {
        this.lastReloadOn = new Date()
    }

    reset() {
        this.pagingStore.reset()
        this.pagingStore = {} as PagingStore
        this.additionalFilter = {} as TFilter
    }
}

import { ServiceBase } from "./ServiceBase"

import { ValueFormatterParams } from "ag-grid-community"

import { DateTime } from "luxon"
import lookupService from "./lookupService"
import { ProductIdentification } from "@dnr/data/models"
import { AxiosResponse } from "axios"

class HelperService extends ServiceBase {
    constructor() {
        super()
    }

    static currencyFormatterMethod = (initialValue?: number, currencyAbrv?: string, locales?: string) => {
        let result = 0
        const formatter = new Intl.NumberFormat(locales ? locales : "en-us", {
            style: "currency",
            currency: currencyAbrv ? currencyAbrv : "USD",
        })
        if (initialValue !== undefined) {
            result = initialValue
        }
        return formatter.format(result)
    }

    get timeZoneOffset() {
        return -Number((new Date().getTimezoneOffset() / 60).toFixed(2))
    }

    isNumber(n: any) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0)
    }

    isUrlValid(url: string) {
        try {
            const newUrl = new URL(url)
            return newUrl.protocol === "http:" || newUrl.protocol === "https:"
        } catch (err) {
            return false
        }
    }

    getLoggerName(): string {
        return "HelperService"
    }

    fileDownload(file: File, fileType: string, fileName: string) {
        const url = window.URL.createObjectURL(new Blob([file]))
        const link = document.createElement("a")

        link.href = url
        link.setAttribute("download", `${fileName}.${fileType}`)
        document.body.appendChild(link)
        link.click()
        link.parentNode?.removeChild(link)
    }

    downloadReport(response: AxiosResponse<File, any>, type: string) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(response.headers["content-disposition"])
        let filename = "report"
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "")
        }
        this.fileDownload(response.data, type, filename)
    }

    combineDateTime(date: Date, timeString: string): Date {
        const timeParts = timeString.match(/^([0-1]?[0-9]|2[0-3]):([0-5][0-9]) (AM|PM)$/i)
        if (!timeParts) {
            throw new Error('Invalid time format. Please use "hh:mmAM/PM" format.')
        }

        let hours = parseInt(timeParts[1])
        const minutes = parseInt(timeParts[2])
        const ampm = timeParts[3].toUpperCase()

        if (ampm === "PM" && hours !== 12) {
            hours += 12
        } else if (ampm === "AM" && hours === 12) {
            hours = 0
        }

        const year = date.getFullYear()
        const month = date.getMonth()
        const day = date.getDate()

        const combinedDate = new Date(year, month, day, hours, minutes)

        return combinedDate
    }

    getDateDiffInDays(startDate?: Date, endDate?: Date) {
        if (startDate && endDate) {
            const end = DateTime.fromJSDate(endDate)
            const start = DateTime.fromJSDate(startDate)

            const diffInDays = end.diff(start, "days")
            return diffInDays.days.toFixed(0)
        }
        return 0
    }

    onClickCopy(textToCopy: string, stateCallback: (checker: boolean) => void) {
        // If the site isn't access over localhost or HTTPS the navigator.clipboard will throw a null exception.
        if (window.isSecureContext === true) {
            navigator.clipboard.writeText(textToCopy)
            stateCallback(true)
            setTimeout(() => {
                stateCallback(false)
            }, 2000)
        }
    }

    replacePlaceholder(str: string, value: string | number) {
        return str.replace("--VALUE--", value.toString())
    }

    getDistinctValues(data: string[]) {
        const distinctValues: string[] = []
        data.forEach((value) => {
            if (!distinctValues.includes(value)) {
                distinctValues.push(value)
            }
        })
        return distinctValues
    }

    getDateDiff(startDate?: Date, endDate?: Date) {
        if (startDate) {
            const end = DateTime.fromJSDate(!endDate ? new Date() : endDate)
            const start = DateTime.fromJSDate(startDate)

            const diffInMinutes = end.diff(start, "minutes").minutes.toFixed(0)
            const diffInHours = end.diff(start, "hours").hours.toFixed(0)
            const diffInDays = end.diff(start, "days").days.toFixed(0)
            const diffInMonths = end.diff(start, "months").months.toFixed(0)

            if (Number(diffInMonths) > 0)
                return Number(diffInMonths) > 1 ? `${diffInMonths} months ago` : `${diffInMonths} month ago`
            if (Number(diffInDays) > 0)
                return Number(diffInDays) > 1 ? `${diffInDays} days ago` : `${diffInDays} day ago`
            if (Number(diffInHours) > 0)
                return Number(diffInHours) > 1 ? `${diffInHours} hours ago` : `${diffInHours} hour ago`
            if (Number(diffInMinutes) > 0)
                return Number(diffInMinutes) > 1 ? `${diffInMinutes} minutes ago` : `${diffInMinutes} minute ago`
            return "Just now"
        }
        return "-"
    }

    formatDate(date?: Date) {
        if (date) {
            const luxonTime = DateTime.fromJSDate(date)
            return luxonTime.setLocale("en-US").toLocaleString(DateTime.DATE_SHORT)
        }
        return "--/--/----"
    }

    fileNameFormatter(fileName?: string) {
        if (fileName) {
            const splitted = fileName.split("/")
            return splitted[splitted.length - 1]
        }
        return fileName
    }

    formatDateAndTime(date?: Date) {
        if (date) {
            const luxonTime = DateTime.fromJSDate(date)
            return luxonTime.setLocale("en-US").toLocaleString(DateTime.DATETIME_SHORT)
        }
        return ""
    }

    formatDateToNewLine(date?: Date, seconds?: boolean) {
        if (date) {
            const luxonTime = DateTime.fromJSDate(date)
            return luxonTime
                .setLocale("en-US")
                .toLocaleString(seconds ? DateTime.DATETIME_SHORT_WITH_SECONDS : DateTime.DATETIME_SHORT)
                .split(",")
                .join(" • ")
        }
        return ""
    }

    getTableDateFormatter<TData>(params: ValueFormatterParams<TData, number>) {
        if (params.value === null || params.value === undefined) {
            return "-"
        }
        const date = new Date(params.value)

        const luxonTime = DateTime.fromJSDate(date)

        return luxonTime.toLocaleString(DateTime.DATE_SHORT)
    }

    convertToDateTimeLocalString(date?: Date) {
        let dateString: string | undefined
        if (date) {
            const year = date.getFullYear()
            const month = (date.getMonth() + 1).toString().padStart(2, "0")
            const day = date.getDate().toString().padStart(2, "0")
            const hours = date.getHours().toString().padStart(2, "0")
            const minutes = date.getMinutes().toString().padStart(2, "0")

            dateString = `${year}-${month}-${day}T${hours}:${minutes}`
        }
        return dateString
    }

    parseIMpbBarcode(barcodeTN: string) {
        let result = barcodeTN
        let isSpecialChar = false
        if (barcodeTN.startsWith("420")) {
            const specialCharIndex = barcodeTN.indexOf("\u001D")
            if (specialCharIndex > -1) {
                result = barcodeTN.substring(specialCharIndex + 1)
                isSpecialChar = true
            }
        }
        return {
            result,
            isSpecialChar,
        }
    }

    getCurrencyFormatter<TData>(params: ValueFormatterParams<TData, number>) {
        const value = params.value || 0

        return HelperService.currencyFormatterMethod(value)
    }

    getTableDateTimeFormatter<TData>(params: ValueFormatterParams<TData, number>) {
        if (params.value === null || params.value === undefined) {
            return "-"
        }

        const date = new Date(params.value)
        const luxonTime = DateTime.fromJSDate(date)

        return luxonTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    }

    getProductIdentification(
        options:
            | {
                get: "id"
                abrv: string
            }
            | {
                get: "display"
                id: number
            }
            | {
                get: "fromArray"
                array: ProductIdentification[]
                abrv: string
            }
    ) {
        switch (options.get) {
            case "id":
                return lookupService.productIdentifications[options.abrv].id
            case "display":
                return lookupService.getProductIdentificationsById()[options.id].display
            case "fromArray":
                for (const prodId of options.array) {
                    if (prodId.identificationTypeId === lookupService.productIdentifications[options.abrv].id) {
                        return prodId.value
                    }
                }
                return "-"
            default:
                return "-"
        }
    }

    getProductIdentificationId(abrv: string) {
        return lookupService.productIdentifications[abrv].id
    }

    getProductIdentificationDisplay(id: number) {
        return lookupService.getProductIdentificationsById()[id].display
    }

    getProductIdentificationAbrv(id: number) {
        return lookupService.getProductIdentificationsById()[id].abrv
    }

    getTotalCommittedPercent(orderedQuantity?: number, totalCommitment?: number) {
        if (!totalCommitment || !orderedQuantity) {
            return 0
        }
        let percent = (totalCommitment / orderedQuantity) * 100
        // #126223 - decrease the percentage by 16%
        percent = percent - percent * 0.16
        return percent.toFixed()
    }

    compareArrays(a1?: number[], a2?: number[]) {
        if (!a1 && !a2) {
            return true
        } else if ((!a1 && a2) || (a1 && !a2)) {
            return false
        }

        if (a1!.length !== a2!.length) {
            return false
        }
        return a1!.every((el, ix) => el === a2![ix])
    }

    /**
     * Used for formatting currencies
     * @param value number to format
     * @param currencyAbrv if used, sets the currency to the defined one; default to 'USD'
     * @param locales if used, formats the number to the localized number format; default to 'en-us'
     * @returns formatted number
     */
    currencyFormatter(initialValue?: number, currencyAbrv?: string, locales?: string) {
        return HelperService.currencyFormatterMethod(initialValue, currencyAbrv, locales)
    }

    handleDrag(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDrop(e: React.DragEvent<HTMLDivElement>, callback: (fileList: FileList) => void) {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            callback(e.dataTransfer.files)
        }
    }

    //#region deal valid until date helpers

    get minValidUntilDate() {
        const tempDate = new Date()
        tempDate.setDate(tempDate.getDate())
        return tempDate
    }

    get defaultValidUntilDate() {
        const tempDate = new Date()
        tempDate.setDate(tempDate.getDate() + 14)
        return tempDate
    }

    createObligatoryDealerDate(date?: Date) {
        if (date) {
            const tempDate = new Date(date)
            tempDate.setDate(tempDate.getDate() + 2)
            return tempDate
        }
        return undefined
    }

    //#endregion deal valid until date helpers
}

export default new HelperService()

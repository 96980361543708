import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import * as yup from "yup"

import { rootModalStore } from "@dnr/state/modal"
import { yupResolver } from "@hookform/resolvers/yup"
import { SecondaryButton } from "@dnr/ui/buttons"
import { FormContainer, FormDropdownOption, FormInput, FormSubmit, TextAreaInput } from "@dnr/ui/forms"
import { useTranslate } from "@dnr/localization"
import { lookupService } from "@dnr/data/services"
import { UserDropdownOption, UserSearchFormDropdown } from "@dnr/features/shared/dropdowns"
import { useSubmitter } from "@dnr/hooks"
import { ApiResponseModel } from "@dnr/data/models"

import { topicService } from "../../services"
import { TopicPriorityFormDropdown } from "../dropdowns/form-dropdowns/TopicPriorityFormDropdown"
import { TopicCategoryFormDropdown } from "../dropdowns/form-dropdowns/TopicCategoryFormDropdown"
import { TopicTypeFormDropdown } from "../dropdowns/form-dropdowns/TopicTypeFormDropdown"
import { Topic } from "../../models"

export interface CreateTicketForm {
    typeId: number
    sendTo: string
    categoryId: number
    title: string
    subject: string
    description: string
    message: string
    priorityId: number
}

interface Props {
    env: "portal" | "backoffice"
}

export const TicketsCreate = observer(({ env }: Props) => {
    const { t } = useTranslate()

    const store = rootModalStore.modalStore

    const { topicStatusIds, topicTypeIds, topicCategoryIds, topicPriorityIds } = lookupService

    const schema = yup.object({
        subject: yup.string().required("GENERAL.REQUIRED"),
        description: yup.string().required("GENERAL.REQUIRED"),
        sendTo: env === "backoffice" ? yup.string().required("GENERAL.REQUIRED") : yup.string(),
    })

    const form = useForm<CreateTicketForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            typeId: topicTypeIds[env === "portal" ? "problem" : "notification"].id,
            categoryId: topicCategoryIds["undefined"].id,
            priorityId: env === "portal" ? undefined : topicPriorityIds["medium"].id,
            sendTo: store.modalData.user ? store.modalData.user.userId : undefined,
        },
    })

    const { onSubmit } = useSubmitter<CreateTicketForm, ApiResponseModel<Topic>>({
        requestCallback: (data) =>
            topicService.createTopic({
                ...data,
                statusId: topicStatusIds["new"].id,
                title: data.subject,
                topicMemberIds: env === "backoffice" ? [data.sendTo] : undefined,
                message: data.description,
            }),
        successMsg: t.common("TICKETS.TICKET_CREATE_SUCCESS"),
        logErrMsg: "Error creating ticket",
        onSuccess: (response) => {
            if (!store.modalData.ignoreNavigate) {
                if (store.callback) store.callback(response?.result.id)
            }
        },
        finalCallback: () => store.closeModal(),
    })

    const topicTypeHandler = (value: FormDropdownOption) => {
        form.setValue("typeId", Number(value.key))
        form.trigger("typeId")
    }

    const topicCategoryHandler = (value: FormDropdownOption) => {
        form.setValue("categoryId", Number(value.key))
        form.trigger("categoryId")
    }

    const topicPriorityHandler = (value: FormDropdownOption) => {
        form.setValue("priorityId", Number(value.key))
        form.trigger("priorityId")
    }

    const topicReceiverHandler = (value: UserDropdownOption) => {
        form.setValue("sendTo", value.userId)
    }

    return (
        <FormContainer onSubmit={(data) => onSubmit(data)} form={form}>
            {env === "backoffice" ? (
                <div className="u-mb--sml--3">
                    <UserSearchFormDropdown<CreateTicketForm>
                        selectedValue={store.modalData.user}
                        name="sendTo"
                        label={t.common("TICKETS.SEND_TO")}
                        onUpdate={(value) => {
                            if (form.formState.errors.sendTo) form.clearErrors("sendTo")
                            topicReceiverHandler(value)
                        }}
                        color="light"
                        required
                    />
                </div>
            ) : null}

            <TopicTypeFormDropdown<CreateTicketForm>
                env={env}
                required
                name="typeId"
                onUpdate={(value) => topicTypeHandler(value)}
            />
            <TopicCategoryFormDropdown<CreateTicketForm>
                required
                name="categoryId"
                onUpdate={(value) => topicCategoryHandler(value)}
            />

            {env === "backoffice" ? (
                <div className="u-mb--sml--3">
                    <TopicPriorityFormDropdown<CreateTicketForm>
                        name="priorityId"
                        onUpdate={(value) => topicPriorityHandler(value)}
                        required
                    />
                </div>
            ) : null}

            <FormInput<CreateTicketForm>
                label={t.common("TICKETS.SUBJECT")}
                placeholder={t.common("TICKETS.ENTER_SUBJECT")}
                inputsize="sml"
                inputtype="text"
                name="subject"
                required
            />
            <TextAreaInput<CreateTicketForm>
                name="description"
                label={t.common("GENERAL.DESCRIPTION")}
                placeholder={t.common("GENERAL.ENTER_DESCRIPTION")}
                inputsize="sml"
                required
            />
            <hr className="c-separator--color-light u-mt--sml--5 u-mb--sml--6" />
            <div className="u-display--separated">
                <SecondaryButton size="base" type="button" onClick={() => store.closeModal()}>
                    {store.closingText}
                </SecondaryButton>
                <FormSubmit displayText="Create ticket" btnSize="base" />
            </div>
        </FormContainer>
    )
})

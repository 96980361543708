import React from "react"
import { RouteObject } from "react-router-dom"

import { RouteLayout } from "@dnr/ui/layouts"
import { PageLoader } from "@dnr/ui/loaders"
import { AuthenticationGuard } from "@dnr/features/shared/app"

import { IsLoggedInGuard } from "./components/navigation/IsLoggedInGuard"
import { rootStore } from "./stores"
import Unsubscribe from "./pages/public/membership/Unsubscribe"

const PublicArea = React.lazy(() => import("./pages/public/PublicArea"))
const MainArea = React.lazy(() => import("./pages/main/MainArea"))

const NotFound = React.lazy(() => import("./pages/NotFound"))
export const routes: RouteObject[] = [
    {
        element: <RouteLayout />,
        children: [
            {
                // Public layout
                element: (
                    <React.Suspense fallback={<PageLoader />}>
                        <IsLoggedInGuard>
                            <PublicArea />
                        </IsLoggedInGuard>
                    </React.Suspense>
                ),
                path: "/*",
            },
            // ---------------------------------
            // PROTECTED AREA
            // ---------------------------------
            {
                element: (
                    <React.Suspense fallback={<PageLoader />}>
                        <AuthenticationGuard authStore={rootStore.authStore}>
                            <MainArea />
                        </AuthenticationGuard>
                    </React.Suspense>
                ),
                path: "app/*", // Note: this can be changed
            },
            // ---------------------------------
            // COMMON AREA
            // ---------------------------------
            {
                element: (
                    <React.Suspense fallback={<PageLoader />}>
                        <Unsubscribe />
                    </React.Suspense>
                ),
                path: "unsubscribe",
            },
            {
                element: (
                    <React.Suspense fallback={<PageLoader />}>
                        <NotFound />
                    </React.Suspense>
                ),
                path: "*",
            },
        ],
    },
    {
        element: (
            <React.Suspense fallback={<PageLoader />}>
                <NotFound />
            </React.Suspense>
        ),
        path: "*",
    },
]

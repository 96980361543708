import { useTranslation, UseTranslationResponse } from "react-i18next"

type TranslateFn = {
    i18n: UseTranslationResponse<"translation", undefined>
    t: {
        common: (key: string, data?: object) => string
        main: (key: string, data?: object) => string
        error: (key: string, data?: object) => string
        form: (key: string, data?: object) => string
    }
}

export function useTranslate(): TranslateFn {
    const trans = useTranslation()

    return {
        i18n: trans,
        t: {
            common: (key: string, data) => trans.t(key, { ns: "common", ...data }),
            main: (key: string, data) => trans.t(key, { ns: "main", ...data }),
            error: (key: string, data) => trans.t(key, { ns: "error", ...data }),
            form: (key: string, data) => trans.t(key, { ns: "form", ...data }),
        },
    }
}

import { forwardRef, useCallback, useState } from "react"

import { SnackbarContent, CustomContentProps, useSnackbar } from "notistack"

type Props = {
    details: string
    callback?: () => void
} & CustomContentProps

export const ExpandableErrorNotification = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const [expanded, setExpanded] = useState(false)
    const { closeSnackbar } = useSnackbar()

    const handleExpandClick = useCallback(() => {
        setExpanded((oldExpanded) => !oldExpanded)
    }, [])

    const handleDismiss = useCallback(() => {
        closeSnackbar(props.id)
    }, [props.id, closeSnackbar])

    return (
        <SnackbarContent
            ref={ref}
            style={props.style}
            className="c-snackbar__container c-snackbar__container--error u-display--flex u-display--flex--fd--column"
        >
            <div className="c-snackbar__header c-snackbar--white">
                <div>{props.message}</div>

                <div className="u-display--flex u-display--flex--gap--sml u-display--flex--ai--center">
                    <i
                        className="u-icon u-icon--med u-icon--arrow-down u-icon--white"
                        onClick={(_) => handleExpandClick()}
                    ></i>
                    <i className="u-icon u-icon--sml u-icon--close u-icon--white" onClick={(_) => handleDismiss()}></i>
                </div>
            </div>
            {expanded ? <div className="c-snackbar__details c-snackbar--white">{props.details}</div> : null}
        </SnackbarContent>
    )
})

import { DependencyList, useEffect } from "react"

export const useDebounceEffect = (action: () => void, deps?: DependencyList, debounce?: number) => {
    useEffect(() => {
        const getData = setTimeout(() => {
            action()
        }, debounce || 1000)

        return () => clearTimeout(getData)
    }, deps)
}

import { LookupEntry } from "@dnr/data/models"

import { ServiceBase } from "./ServiceBase"

class LookupService extends ServiceBase {
    readonly trackingStatusByAbrv = {
        submitted: { abrv: "submitted", display: "Submitted", id: 1 },
        received: { abrv: "received", display: "Received", id: 2 },
        processed: { abrv: "processed", display: "Processed", id: 3 },
    } as Record<string, LookupEntry>

    readonly productStatusByAbrv = {
        new: { abrv: "new", display: "New", id: 1 },
        active: { abrv: "active", display: "Active", id: 2 },
        inactive: { abrv: "inactive", display: "Inactive", id: 3 },
    } as Record<string, LookupEntry>

    readonly productWarehouseStatus = {
        readyToShip: { abrv: "readyToShip", display: "Ready To Ship", id: 1 },
        shipped: { abrv: "shipped", display: "Shipped", id: 2 },
        received: { abrv: "received", display: "Received", id: 3 },
        reserved: { abrv: "reserved", display: "Reserved", id: 4 },
        rollbackDelete: { abrv: "rollbackDelete", display: "Rollback Delete", id: 5 },
    } as Record<string, LookupEntry>

    readonly resultTypeByAbrv = {
        success: { abrv: "Success", display: "Success", id: 1 },
        partialSuccess: {
            abrv: "PartialSuccess",
            display: "Partial Success",
            id: 2,
        },
        fail: { abrv: "Fail", display: "Fail", id: 3 },
    } as Record<string, LookupEntry>

    readonly productPublishTypesByAbrv = {
        draft: { abrv: "draft", display: "Draft", id: 1 },
        publish: { abrv: "publish", display: "Publish", id: 2 },
    } as Record<string, LookupEntry>

    readonly balanceAccountEntryType = {
        credit: { abrv: "credit", display: "Credit", id: 1 },
        debit: { abrv: "debit", display: "Debit", id: 2 },
    } as Record<string, LookupEntry>

    readonly dealerPaymentStatus = {
        notPaid: { abrv: "notPaid", display: "Not Paid", id: 1 },
        paid: { abrv: "paid", display: "Paid", id: 2 },
    } as Record<string, LookupEntry>

    readonly packageType = {
        box: { abrv: "box", display: "Box", id: 1 },
        pallete: { abrv: "pallete", display: "Pallet", id: 2 },
    } as Record<string, LookupEntry>

    readonly runnerPaymentStatus = {
        pending: { abrv: "pending", display: "Pending", id: 1 },
        availableForPayout: {
            abrv: "availableForPayout",
            display: "Available For Payout",
            id: 2,
        },
        paidOut: { abrv: "paidOut", display: "Paid Out", id: 3 },
        refund: { abrv: "refund", display: "Refund", id: 4 },
    } as Record<string, LookupEntry>

    readonly systemTransactionType = {
        newDeal: { abrv: "newDeal", display: "New Deal", id: 1 },
        newCommitment: {
            abrv: "newCommitment",
            display: "New Commitment",
            id: 2,
        },
        systemAccountUpdate: {
            abrv: "systemAccountUpdate",
            display: "System Account Update",
            id: 3,
        },
    } as Record<string, LookupEntry>

    readonly accountStatusesByAbrv = {
        pending: { abrv: "pending", display: "Pending", id: 1 },
        approved: { abrv: "approved", display: "Approved", id: 2 },
        rejected: { abrv: "rejected", display: "Rejected", id: 3 },
    } as Record<string, LookupEntry>

    readonly commitmentStatusesByAbrv = {
        inactive: { abrv: "inactive", display: "Inactive", id: 1 },
        active: { abrv: "active", display: "Active", id: 2 },
        completed: { abrv: "completed", display: "Completed", id: 3 },
        canceled: { abrv: "canceled", display: "Canceled", id: 4 },
    } as Record<string, LookupEntry>

    readonly balanceTypesByAbrv = {
        dnrAccount: { abrv: "dnr-account", display: "D&R Account", id: 1 },
        paidOut: { abrv: "paid-out", display: "Total Paid Out", id: 2 },
    } as Record<string, LookupEntry>

    readonly balanceAccountTypesByAbrv = {
        totalInventoryValue: {
            abrv: "totalInventoryValue",
            display: "Total Inventory Value",
            id: -4,
        },
        totalRunnerBalance: {
            abrv: "totalRunnerBalance",
            display: "Total Runner Balance",
            id: -3,
        },
        totalDealerBalance: {
            abrv: "totalDealerBalance",
            display: "Total Dealer Balance",
            id: -2,
        },
        totalAccount: {
            abrv: "totalAccount",
            display: "Total Account",
            id: -1,
        },
        fulfillment: {
            abrv: "fulfillment",
            display: "Fulfillment Account",
            id: 1,
        },
        fee: { abrv: "fee", display: "Fee Account", id: 2 },
        inventory: { abrv: "inventory", display: "Inventory Account", id: 3 },
        reserved: { abrv: "reserved", display: "Reserved Account", id: 4 },
        runnerBalance: {
            abrv: "runnerBalance",
            display: "Runner Balance",
            id: 5,
        },
        availablePayment: {
            abrv: "available-payment",
            display: "Available for Payment Account",
            id: 6,
        },
    } as Record<string, LookupEntry>

    readonly transactionCategory = {
        deposit: { abrv: "deposit", display: "Deposit", id: 1 },
        disbursement: { abrv: "disbursement", display: "Disbursement", id: 2 },
        refund: { abrv: "refund", display: "Refund", id: 3 },
    } as Record<string, LookupEntry>

    readonly transactionType = {
        inflow: { abrv: "inflow", display: "Inflow", id: 1 },
        outflow: { abrv: "outflow", display: "Outflow", id: 2 },
        system: {
            abrv: "systemTransfer",
            display: "Transfer Within System",
            id: 3,
        },
    } as Record<string, LookupEntry>

    readonly userVerificationStatus = {
        unverified: { abrv: "unverified", display: "Unverified", id: 1 },
        partiallyVerified: { abrv: "partiallyVerified", display: "Partially Verified", id: 2 },
        verified: {
            abrv: "verified",
            display: "Verified",
            id: 3,
        },
    } as Record<string, LookupEntry>

    readonly rolesByAbrv = {
        superAdmin: {
            abrv: "superAdmin",
            display: "Super Admin",
            id: 1,
        },
        backofficeAdmin: {
            abrv: "backofficeAdmin",
            display: "Backoffice Admin",
            id: 2,
        },
        dealer: { abrv: "dealer", display: "Dealer", id: 3 },
        runner: { abrv: "runner", display: "Runner", id: 4 },
        warehouseWorker: {
            abrv: "warehouseWorker",
            display: "Warehouse Worker",
            id: 5,
        },
    } as Record<string, LookupEntry>

    readonly dealStatusesByAbrv = {
        draft: { abrv: "draft", display: "Draft", id: 1 },
        live: { abrv: "live", display: "In Progress", id: 2 },
        outbid: { abrv: "outbid", display: "Outbid", id: 3 },
        paused: { abrv: "paused", display: "Paused", id: 4 },
        closed: { abrv: "closed", display: "Closed", id: 5 },
        ended: { abrv: "ended", display: "Ended", id: 6 },
    } as Record<string, LookupEntry>

    readonly dealTypesByAbrv = {
        normal: { abrv: "normal", display: "Normal", id: 1 },
        bundle: { abrv: "bundle", display: "Bundle", id: 2 },
    } as Record<string, LookupEntry>

    readonly dealCommitmentStatusByAbrv = {
        "no-commitments": {
            abrv: "no-commitments",
            display: "No Commitments",
            id: 1,
        },
        "slow-commitments": {
            abrv: "slow-commitments",
            display: "Slow Commitments",
            id: 2,
        },
        "in-progress": { abrv: "in-progress", display: "In Progress", id: 3 },
        "full-commited": {
            abrv: "full-commited",
            display: "Full Commitment",
            id: 4,
        },
    } as Record<string, LookupEntry>

    readonly warehouseTypeByAbrv = {
        processingWarehouse: {
            abrv: "processingWarehouse",
            display: "Processing warehouse",
            id: 1,
        },
        forwardingWarehouse: {
            abrv: "forwardingWarehouse",
            display: "Forwarding warehouse",
            id: 2,
        },
    } as Record<string, LookupEntry>

    readonly trackingStatusById = {
        1: { abrv: "submitted", display: "Submitted", id: 1 },
        2: { abrv: "received", display: "Received", id: 2 },
        3: { abrv: "processed", display: "Processed", id: 3 },
    } as Record<number, LookupEntry>

    readonly receivingTransactionStatusId = {
        valid: {
            abrv: "valid",
            display: "Valid",
            id: 1,
        },
        invalid: {
            abrv: "invalid",
            display: "Invalid",
            id: 2,
        },
        damaged: {
            abrv: "damaged",
            display: "Damaged",
            id: 3,
        },
    } as Record<string, LookupEntry>

    readonly shippingRequestStatusIds = {
        new: {
            abrv: "new",
            display: "New",
            id: 1,
        },
        packing: {
            abrv: "packing",
            display: "Packing",
            id: 2,
        },
        packed: {
            abrv: "packed",
            display: "Packed",
            id: 3,
        },
        "shipped-out": {
            abrv: "shipped-out",
            display: "Shipped Out",
            id: 4,
        },
    } as Record<string, LookupEntry>

    readonly topicStatusIds = {
        new: {
            abrv: "new",
            display: "New",
            id: 1,
        },
        processing: {
            abrv: "processing",
            display: "Processing",
            id: 2,
        },
        closed: {
            abrv: "closed",
            display: "Closed",
            id: 3,
        },
    } as Record<string, LookupEntry>

    readonly topicPriorityIds = {
        high: {
            abrv: "high",
            display: "High",
            id: 1,
        },
        medium: {
            abrv: "medium",
            display: "Medium",
            id: 2,
        },
        low: {
            abrv: "low",
            display: "Low",
            id: 3,
        },
    } as Record<string, LookupEntry>

    readonly shippingReqReportExportType = {
        pdf: {
            abrv: "pdf",
            display: "PDF",
            id: 1,
        },
        xlsx: {
            abrv: "xlsx",
            display: "Excel",
            id: 2,
        },
        csv: {
            abrv: "csv",
            display: "CSV",
            id: 3,
        },
    } as Record<string, LookupEntry>

    readonly topicTypeIds = {
        problem: {
            abrv: "problem",
            display: "Problem",
            id: 1,
        },
        inquiry: {
            abrv: "inquiry",
            display: "Inquiry",
            id: 2,
        },
        notification: {
            abrv: "notification",
            display: "Notification",
            id: 3,
        },
        "shipping-request": {
            abrv: "shipping-request",
            display: "Shipping Request",
            id: 4,
        },
    } as Record<string, LookupEntry>

    readonly topicCategoryIds = {
        billing: {
            abrv: "billing",
            display: "Billing",
            id: 1,
        },
        shipping: {
            abrv: "shipping",
            display: "Shipping",
            id: 2,
        },
        commitment: {
            abrv: "commitment",
            display: "Commitment",
            id: 3,
        },
        deal: {
            abrv: "deal",
            display: "Deal",
            id: 4,
        },
        catalog: {
            abrv: "catalog",
            display: "Catalog",
            id: 5,
        },
        trackings: {
            abrv: "trackings",
            display: "Trackings",
            id: 6,
        },
        undefined: {
            abrv: "undefined",
            display: "Undefined",
            id: 7,
        },
    } as Record<string, LookupEntry>

    readonly disbursementItemStatus = {
        new: {
            abrv: "new",
            display: "New",
            id: 1,
        },
        completed: {
            abrv: "completed",
            display: "New",
            id: 2,
        },
        failedGeneral: {
            abrv: "failedGeneral",
            display: "Failed General",
            id: 3,
        },
        failedMissingBankAccount: {
            abrv: "failedMissingBankAccount",
            display: "Failed Missing Bank Account",
            id: 4,
        },
    } as Record<string, LookupEntry>

    readonly disbursementStatus = {
        new: {
            abrv: "new",
            display: "New",
            id: 1,
        },
        processing: {
            abrv: "processing",
            display: "Processing",
            id: 2,
        },
        completed: {
            abrv: "completed",
            display: "Completed",
            id: 3,
        },
    } as Record<string, LookupEntry>

    readonly disbursementType = {
        regular: {
            abrv: "regular",
            display: "Regular",
            id: 1,
        },
        early: {
            abrv: "early",
            display: "Early",
            id: 2,
        },
    } as Record<string, LookupEntry>

    readonly productIdentifications = {
        Internal: { abrv: "Internal", display: "Internal Product Code", id: 1 },
        Ean8: { abrv: "Ean8", display: "EAN-8", id: 2 },
        Ean13: { abrv: "Ean13", display: "EAN-13", id: 3 },
        Gtin12: { abrv: "Gtin12", display: "GTIN-12", id: 4 },
        Gtin13: { abrv: "Gtin13", display: "GTIN-13", id: 5 },
        Upca: { abrv: "Upca", display: "UPC-A", id: 6 },
        Upce: { abrv: "Upce", display: "UPC-E", id: 7 },
        Isbn: { abrv: "Isbn", display: "ISBN", id: 8 },
        Issn: { abrv: "Issn", display: "ISSN", id: 9 },
        Asin: { abrv: "Asin", display: "ASIN", id: 10 },
        Jan: { abrv: "Jan", display: "JAN", id: 11 },
        ModelNumber: { abrv: "ModelNumber", display: "Model number", id: 12 },
        PartNumber: { abrv: "PartNumber", display: "Part number", id: 13 },
        SerialNumber: { abrv: "SerialNumber", display: "Serial number", id: 14 },
        Gtin8: { abrv: "Gtin8", display: "GTIN-8", id: 15 },
        Gtin14: { abrv: "Gtin14", display: "GTIN-14", id: 16 },
        Elid: { abrv: "Elid", display: "ELID", id: 17 },
    } as Record<string, LookupEntry>

    constructor() {
        super()
    }

    getLoggerName(): string {
        return "LookupService"
    }

    getDealStatuses(): Record<number, LookupEntry> {
        return {
            1: { abrv: "draft", display: "Draft", id: 1 },
            2: { abrv: "live", display: "Live", id: 2 },
            3: { abrv: "outbid", display: "Outbid", id: 3 },
            4: { abrv: "paused", display: "Paused", id: 4 },
            5: { abrv: "closed", display: "Closed", id: 5 },
            6: { abrv: "ended", display: "Ended", id: 6 },
        }
    }

    getProductStatuses(): Record<number, LookupEntry> {
        return {
            1: { abrv: "new", display: "New", id: 1 },
            2: { abrv: "active", display: "Active", id: 2 },
            3: { abrv: "inactive", display: "Inactive", id: 3 },
        }
    }

    getProductPublishTypes(): Record<number, LookupEntry> {
        return {
            1: { abrv: "draft", display: "Draft", id: 1 },
            2: { abrv: "publish", display: "Published", id: 2 },
        }
    }

    getDealCommitmentStatuses(): Record<number, LookupEntry> {
        return {
            1: { abrv: "no-commitments", display: "No Commitments", id: 1 },
            2: { abrv: "slow-commitments", display: "Slow Commitments", id: 2 },
            3: { abrv: "in-progress", display: "In Progress", id: 3 },
            4: { abrv: "full-commited", display: "Full Commitment", id: 4 },
        }
    }

    getProductStockStatuses(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "Unknown",
                display: "Unknown",
                id: 1,
                icon: "Unknown",
            },
            2: {
                abrv: "InStock",
                display: "In stock",
                id: 2,
                icon: "InStock",
            },
            3: {
                abrv: "LowInStock",
                display: "Low in stock",
                id: 3,
                icon: "LowInStock",
            },
            4: {
                abrv: "OutOfStock",
                display: "Out of stock",
                id: 4,
                icon: "OutOfStock",
            },
        }
    }

    getProductIdentificationsById(): Record<number, LookupEntry> {
        return {
            1: { abrv: "Internal", display: "Internal Product Code", id: 1 },
            2: { abrv: "Ean8", display: "EAN-8", id: 2 },
            3: { abrv: "Ean13", display: "EAN-13", id: 3 },
            4: { abrv: "Gtin12", display: "GTIN-12", id: 4 },
            5: { abrv: "Gtin13", display: "GTIN-13", id: 5 },
            6: { abrv: "Upca", display: "UPC-A", id: 6 },
            7: { abrv: "Upce", display: "UPC-E", id: 7 },
            8: { abrv: "Isbn", display: "ISBN", id: 8 },
            9: { abrv: "Issn", display: "ISSN", id: 9 },
            10: { abrv: "Asin", display: "ASIN", id: 10 },
            11: { abrv: "Jan", display: "JAN", id: 11 },
            12: { abrv: "ModelNumber", display: "Model number", id: 12 },
            13: { abrv: "PartNumber", display: "Part number", id: 13 },
            14: { abrv: "SerialNumber", display: "Serial number", id: 14 },
            15: { abrv: "Gtin8", display: "GTIN-8", id: 15 },
            16: { abrv: "Gtin14", display: "GTIN-14", id: 16 },
            17: { abrv: "Elid", display: "ELID", id: 17 },
        }
    }

    getCommitmentStatuses(): Record<number, LookupEntry> {
        return {
            1: { abrv: "inactive", display: "Inactive", id: 1 },
            2: { abrv: "active", display: "Active", id: 2 },
            3: { abrv: "completed", display: "Completed", id: 3 },
            4: { abrv: "canceled", display: "Canceled", id: 4 },
        }
    }

    getWarehouseType(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "processingWarehouse",
                display: "Processing warehouse",
                id: 1,
            },
            2: {
                abrv: "forwardingWarehouse",
                display: "Forwarding warehouse",
                id: 2,
            },
        }
    }

    getPackageType(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "box",
                display: "Box",
                id: 1,
            },
            2: {
                abrv: "pallete",
                display: "Pallet",
                id: 2,
            },
        }
    }

    getShippingType(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "delivery",
                display: "I will schedule a delivery",
                id: 1
            },
            2: {
                abrv: "pickup",
                display: "I will pick up",
                id: 2,
            },
            3: {
                abrv: "reimburse",
                display: "Please arrange shipping and I will reimburse",
                id: 3,
            },
            4: {
                abrv: "upsdrop",
                display: "D&R should drop it off by a UPS store",
                id: 4,
            },
            5: {
                abrv: "runnerlabel",
                display: "Runner will provide a shipping label",
                id: 5,
            },
            6: {
                abrv: "amazonlabel",
                display: "Amazon will send a return label directly",
                id: 6,
            },
        }
    }

    getServiceResultType(): Record<number, LookupEntry> {
        return {
            1: { abrv: "Success", display: "Success", id: 1 },
            2: { abrv: "PartialSuccess", display: "Partial Success", id: 2 },
            3: { abrv: "Fail", display: "Fail", id: 3 },
        }
    }

    getProductWarehouseStatusId(): Record<number, LookupEntry> {
        return {
            1: { abrv: "ReadyToShip", display: "Ready To Ship", id: 1 },
            2: { abrv: "Shipped", display: "Shipped", id: 2 },
            3: { abrv: "Received", display: "Received", id: 3 },
            4: { abrv: "Reserved", display: "Reserved", id: 4 },
            5: { abrv: "RollbackDelete", display: "Rollback Delete", id: 5 },
        }
    }

    getReceivingTransactionStatusId(): Record<number, LookupEntry> {
        return {
            1: { abrv: "valid", display: "Valid", id: 1 },
            2: { abrv: "invalid", display: "Invalid", id: 2 },
            3: { abrv: "damaged", display: "Damaged", id: 3 },
        }
    }

    getBalanceAccEntryType(): Record<number, LookupEntry> {
        return {
            1: { abrv: "credit", display: "Credit", id: 1 },
            2: { abrv: "debit", display: "Debit", id: 2 },
        }
    }

    getDealerPaymentStatusId(): Record<number, LookupEntry> {
        return {
            1: { abrv: "notPaid", display: "Not Paid", id: 1 },
            2: { abrv: "paid", display: "Paid", id: 2 },
        }
    }

    getRunnerPaymentStatusId(): Record<number, LookupEntry> {
        return {
            1: { abrv: "pending", display: "Pending", id: 1 },
            2: {
                abrv: "availableForPayout",
                display: "Available For Payout",
                id: 2,
            },
            3: { abrv: "paidOut", display: "Paid Out", id: 3 },
            4: { abrv: "refund", display: "Refund", id: 4 },
        }
    }

    getTransactionCategoryId(): Record<number, LookupEntry> {
        return {
            1: { abrv: "inflow", display: "Inflow", id: 1 },
            2: { abrv: "outflow", display: "Outflow", id: 2 },
            3: {
                abrv: "systemTransfer",
                display: "Transfer Within System",
                id: 3,
            },
        }
    }

    getTransactionTypeId(): Record<number, LookupEntry> {
        return {
            1: { abrv: "deposit", display: "Deposit", id: 1 },
            2: { abrv: "disbursement", display: "Disbursement", id: 2 },
            3: { abrv: "refund", display: "Refund", id: 3 },
        }
    }

    getShippingRequestStatus(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "new",
                display: "New",
                id: 1,
            },
            2: {
                abrv: "packing",
                display: "Packing",
                id: 2,
            },
            3: {
                abrv: "packed",
                display: "Packed",
                id: 3,
            },
            4: {
                abrv: "shipped-out",
                display: "Shipped Out",
                id: 4,
            },
        }
    }

    getUserVerificationStatus(): Record<number, LookupEntry> {
        return {
            1: { abrv: "unverified", display: "Unverified", id: 1 },
            2: { abrv: "partiallyVerified", display: "Partially Verified", id: 2 },
            3: {
                abrv: "verified",
                display: "Verified",
                id: 3,
            }
        }
    }

    getBalanceAccountTypeId(): Record<number, LookupEntry> {
        return {
            "-4": {
                abrv: "totalInventoryValue",
                display: "Total Inventory Value",
                id: -4,
            },
            "-3": {
                abrv: "totalRunnerBalance",
                display: "Total Runner Balance",
                id: -3,
            },
            "-2": {
                abrv: "totalDealerBalance",
                display: "Total Dealer Balance",
                id: -2,
            },
            "-1": {
                abrv: "totalAccount",
                display: "Total Account",
                id: -1,
            },
            1: {
                abrv: "fulfillment",
                display: "Fulfillment Account",
                id: 1,
            },
            2: {
                abrv: "fee",
                display: "Fee Account",
                id: 2,
            },
            3: {
                abrv: "inventory",
                display: "Inventory Account",
                id: 3,
            },
            4: {
                abrv: "reserved",
                display: "Reserved Account",
                id: 4,
            },
            5: {
                abrv: "pending",
                display: "Pending Payment Account",
                id: 5,
            },
            6: {
                abrv: "available-payment",
                display: "Available for Payment Account",
                id: 6,
            },
            7: {
                abrv: "available-payout",
                display: "Available for Payout Account",
                id: 7,
            },
        }
    }

    getTopicStatusId(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "new",
                display: "New",
                id: 1,
            },
            2: {
                abrv: "processing",
                display: "Processing",
                id: 2,
            },
            3: {
                abrv: "closed",
                display: "Closed",
                id: 3,
            },
        }
    }

    getAccStatusId(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "pending",
                display: "Pending",
                id: 1,
            },
            2: {
                abrv: "approved",
                display: "Approved",
                id: 2,
            },
            3: {
                abrv: "rejected",
                display: "Rejected",
                id: 3,
            },
        }
    }

    getTopicPriorityId(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "high",
                display: "High",
                id: 1,
            },
            2: {
                abrv: "medium",
                display: "Medium",
                id: 2,
            },
            3: {
                abrv: "low",
                display: "Low",
                id: 3,
            },
        }
    }

    getTopicTypeId(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "problem",
                display: "Problem",
                id: 1,
            },
            2: {
                abrv: "inquiry",
                display: "Inquiry",
                id: 2,
            },
            3: {
                abrv: "notification",
                display: "Notification",
                id: 3,
            },
            4: {
                abrv: "shipping-request",
                display: "Shipping Request",
                id: 4,
            },
        }
    }

    getTopicCategoryId(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "billing",
                display: "Billing",
                id: 1,
            },
            2: {
                abrv: "shipping",
                display: "Shipping",
                id: 2,
            },
            3: {
                abrv: "commitment",
                display: "Commitment",
                id: 3,
            },
            4: {
                abrv: "deal",
                display: "Deal",
                id: 4,
            },
            5: {
                abrv: "catalog",
                display: "Catalog",
                id: 5,
            },
            6: {
                abrv: "trackings",
                display: "Trackings",
                id: 6,
            },
            7: {
                abrv: "undefined",
                display: "Undefined",
                id: 7,
            },
        }
    }

    getDisbursementItemStatus(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "new",
                display: "New",
                id: 1,
            },
            2: {
                abrv: "completed",
                display: "Completed",
                id: 2,
            },
            3: {
                abrv: "failedGeneral",
                display: "Failed General",
                id: 3,
            },
            4: {
                abrv: "failedMissingBankAccountInfo",
                display: "Failed Missing Bank Account Info",
                id: 4,
            },
        }
    }

    getDisbursementStatus(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "new",
                display: "New",
                id: 1,
            },
            2: {
                abrv: "processing",
                display: "Processing",
                id: 2,
            },
            3: {
                abrv: "completed",
                display: "Completed",
                id: 3,
            },
        }
    }

    getShippingReqReportExportTypeId(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "pdf",
                display: "PDF",
                id: 1,
            },
            2: {
                abrv: "xlsx",
                display: "Excel",
                id: 2,
                icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation" />',
            },
            3: {
                abrv: "csv",
                display: "CSV",
                id: 3,
                icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation" />',
            },
        }
    }

    getDealButtonsId(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "orderAgain",
                display: "Order again",
                id: 1,
                icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation" />',
            },
            2: {
                abrv: "resumeDeal",
                display: "Resume deal",
                id: 2,
                icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation" />',
            },
            3: {
                abrv: "editValidityPeriod",
                display: "Edit Final Date for Runners to Commit to Purchase",
                id: 3,
            },
            4: {
                abrv: "upTheOfferedPrice",
                display: "Up the offered price",
                id: 4,
                icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation" />',
            },
            5: {
                abrv: "addNote",
                display: "Add note",
                id: 5,
            },
            6: {
                abrv: "pauseDeal",
                display: "Pause deal",
                id: 6,
            },
            7: {
                abrv: "closeDeal",
                display: "Close deal",
                id: 7,
            },
        }
    }

    getAdminDisbursementButtonsId(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "editLockDate",
                display: "Edit lock date",
                id: 1,
                icon: undefined,
            },
            2: {
                abrv: "markAsCompleted",
                display: "Mark as completed",
                id: 2,
                icon: undefined,
            },
            3: {
                abrv: "getReport",
                display: "Get report",
                id: 3,
                icon: undefined,
            },
        }
    }

    getAdminDealButtonsId(): Record<number, LookupEntry> {
        return {
            2: {
                abrv: "resumeDeal",
                display: "Resume deal",
                id: 2,
                icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation" />',
            },
            3: {
                abrv: "sendEmail",
                display: "Send email",
                id: 3,
                icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation" />',
            },
            4: {
                abrv: "createCommitment",
                display: "Create commitment",
                id: 4,
            },
            6: {
                abrv: "pauseDeal",
                display: "Pause deal",
                id: 6,
            },
            7: {
                abrv: "closeDeal",
                display: "Close deal",
                id: 7,
            },
            8: {
                abrv: "endDeal",
                display: "End deal",
                id: 8,
            },
        }
    }

    getShippingReqActionsId(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "edit",
                display: "Edit",
                id: 1,
            },
            2: {
                abrv: "del",
                display: "Delete",
                id: 2,
            },
            3: {
                abrv: "save",
                display: "Save",
                id: 3,
            },
        }
    }

    getDisbursementType(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "regular",
                display: "Regular",
                id: 1,
            },
            2: {
                abrv: "early",
                display: "Early",
                id: 2,
            },
        }
    }

    getClient(): Record<number, LookupEntry> {
        return {
            1: {
                abrv: "dealandrunner",
                display: "Deal&Runner",
                id: 1,
            },
            2: {
                abrv: "smdunlimited",
                display: "SMD Unlimited",
                id: 2,
            },
        }
    }
}
export default new LookupService()

import { forwardRef } from "react"

import { SnackbarContent, CustomContentProps } from "notistack"
import { useTranslate } from "@dnr/localization"

type Props = {
    details: string
    hideIcon?: boolean
} & CustomContentProps

export const LoadingNotification = forwardRef<HTMLDivElement, Props>((props, ref) => {
     const { t } = useTranslate()
     
    return (
        <SnackbarContent
            ref={ref}
            style={props.style}
            className="c-snackbar__container c-snackbar__container--info u-display--flex u-display--flex--fd--column"
        >
            <div className="notistack-snackbar">
                {" "}
                <div className="u-display--flex u-display--flex--gap--sml">
                    <div className="c-snackbar__spinner"></div>
                    <h3 className="c-snackbar__title c-snackbar__title--spinner c-snackbar--white">
                        {props.message ? props.message : t.common("GENERAL.EXPORTING_DATA")}
                    </h3>
                </div>
            </div>
        </SnackbarContent>
    )
})

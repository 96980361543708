import LatestDealsLoader from "./LatestDealsLoader"
import LatestCommitmentsLoader from "./LatestCommitmentsLoader"
import BalanceOverviewLoader from "./BalanceOverviewLoader"
import LatestScannedTrackingsLoader from "./LatestScannedTrackingsLoader"
import { PageLoader } from "./PageLoader"

export const AppContentLoader = () => {
    return (
        <div>
            <PageLoader />
            
            <BalanceOverviewLoader />

            <div className="c-section--dashboard u-mt--sml--8">
                <LatestDealsLoader />
                <LatestCommitmentsLoader />
            </div>

            <div className="c-section--dashboard u-mt--sml--8">
                <div className="c-section--dashboard__item">
                    <LatestScannedTrackingsLoader />
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default AppContentLoader

import { useCallback, useState } from "react"

import { useRequest } from "@dnr/hooks"

import { productsBrandService } from "@dnr/data/services"
import { FormDropdown, FormDropdownOption } from "@dnr/ui/forms"
import { FieldValues, Path } from "react-hook-form"
import { observer } from "mobx-react"
import { useTranslate } from "@dnr/localization"

export type FormProps<TForm> = {
    selectedValue?: number
    placeholder?: string
    name: Path<TForm>
    onUpdate: (value: FormDropdownOption) => void
    color?: string
    type?: string
    deps?: string | number
    reValidate?: boolean
}

export const ProductBrandsFormDropdown = <TForm extends FieldValues>(props: FormProps<TForm>) => {
    const { t } = useTranslate()
    const [productBrandsOptions, setProductBrandsOptions] = useState<FormDropdownOption[]>([])

    const { loading } = useRequest(
        async (options) => {
            const { result: brands } = await productsBrandService.getAllCached(
                {
                    pageNumber: 1,
                    pageSize: 1000,
                },
                {
                    abortController: options.abortController,
                },
                props.reValidate
            )

            if (brands?.items != null && brands.items.length > 0) {
                const brandOptions = brands.items
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((m) => {
                        return {
                            label: m.displayName || m.name,
                            key: m.brandId.toString(),
                            value: m.brandId,
                            displayText: m.displayName || m.name,
                        } as FormDropdownOption
                    })
                setProductBrandsOptions(brandOptions)
            }
        },
        [props.deps]
    )

    return (
        <div>
            {loading ? (
                <div className="c-loader">Loading...</div>
            ) : (
                <FormDropdown
                    id="select-category"
                    type={props.type == "up" ? "up" : "default"}
                    placeholder={t.common("PLACEHOLDER.SELECT_BRAND")}
                    label="Brand"
                    options={productBrandsOptions}
                    name={props.name}
                    required
                    selectedValue={props.selectedValue?.toString()}
                    onUpdate={(option) => props.onUpdate(option)}
                    color={props.color}
                />
            )}
        </div>
    )
}

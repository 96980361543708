import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"
import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"

interface UserRoleDropdownProps {
    onChange: (option: DropdownOption) => void
    reset: number
    selectedValue?: string
    isFilter?: boolean
    onReset?: () => void
}

export const UserRoleDropdown = (props: UserRoleDropdownProps) => {
    const { t } = useTranslate()

    const getUserRoleId = (abrv: string) => {
        return lookupService.rolesByAbrv[abrv].id.toString()
    }
    const roleOptions: DropdownOption[] = [
        {
            displayText: t.common("GENERAL.ANY"),
            key: "any",
            value: "any",
        },
        {
            displayText: "Dealers",
            key: getUserRoleId("dealer"),
            value: getUserRoleId("dealer"),
        },
        {
            displayText: "Runners",
            key: getUserRoleId("runner"),
            value: getUserRoleId("runner"),
        },
    ]

    return (
        <Dropdown
            resetStates={props.reset}
            selectedValue={props.selectedValue}
            onChange={props.onChange}
            options={roleOptions}
            id="user-role-dropdown"
            placeholder={t.common("GENERAL.SELECT_ROLE")}
            isFilter={props.isFilter}
            resetFilter={props.onReset}
        />
    )
}

import { useState } from "react"

import { warehouseService } from "@dnr/data/services"
import { useRequest } from "@dnr/hooks"
import { Warehouse } from "@dnr/data/models"
import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"

interface WarehouseDropdownProps {
    required?: boolean
    default?: string
    label?: string
    reset?: number
    placeholder?: string
    selectedValue?: string
    inputSize?: string
    onChange: (selected: DropdownOption) => void
    size?: string
    env?: "portal" | "backoffice"
    color?: string
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
    onReset?: () => void
}

export const WarehouseDropdown = (props: WarehouseDropdownProps) => {
    const [warehouses, setWarehouses] = useState<Warehouse[]>([])

    useRequest(async (options) => {
        const response = await warehouseService.getAllCached(
            {
                pageNumber: 1,
                pageSize: 100,
            },
            {
                abortController: options.abortController,
            }
        )

        if (response !== undefined) {
            setWarehouses(response)
        }
    }, [])

    return (
        <Dropdown
            required={props.required}
            selectedValue={props.default}
            resetStates={props.reset}
            id="warehouse-dropdown"
            label={props.label}
            type="select"
            placeholder={props.placeholder}
            options={warehouses.map((wh) => {
                return {
                    key: wh.id.toString(),
                    value: wh.warehouseTypeId.toString(),
                    displayText: wh.name,
                } as DropdownOption
            })}
            onChange={(option) => props.onChange(option)}
            size={props.size}
            env={props.env}
            width={props.width}
            color={props.color}
            fullWidth={props.fullWidth}
            isFilter={props.isFilter}
            resetFilter={props.onReset}
        />
    )
}

import React, { useState, useCallback } from "react"
import LoaderWrapper from "./LoaderWrapper"

const CommitmentsCardLoader = () => {
    const [loaderWidth, setLoaderWidth] = useState(0)

    const ref = useCallback((node: any) => {
        if (node !== null) {
            setLoaderWidth(node.getBoundingClientRect().width)
        }
    }, [])

    return (
        <div className="c-cards c-cards--latest" ref={ref}>
            <LoaderWrapper height={32} width={loaderWidth}>
                <rect x="0" y="0" rx="6" ry="6" width="32" height="32" />
                <rect x="40" y="0" rx="6" ry="6" width="340" height="10" />
                <rect x="40" y="18" rx="6" ry="6" width="100" height="10" />

                <rect x="450" y="10" rx="6" ry="6" width="60" height="10" />
                <rect x="590" y="10" rx="6" ry="6" width="60" height="10" />

                <rect x={loaderWidth - 50} y="0" rx="6" ry="6" width="50" height="30" />
            </LoaderWrapper>
        </div>
    )
}

export default CommitmentsCardLoader

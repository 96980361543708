import { DetailedHTMLProps, InputHTMLAttributes, useState } from "react"
import { FieldValues, Path } from "react-hook-form"

import { useRequest } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { storeService } from "@dnr/data/services"
import { FormDropdown, FormDropdownOption } from "@dnr/ui/forms"

export type StoresFormProps<TForm extends FieldValues> = {
    placeholder?: string
    selectedValue?: string
    onUpdate: (selected: FormDropdownOption) => void
    name: Path<TForm>
} & DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

export const StoresFormDropdown = <TForm extends FieldValues>(props: StoresFormProps<TForm>) => {
    const { t } = useTranslate()

    const [productStoresOptions, setProductStoresOptions] = useState<FormDropdownOption[]>([])
    const [selectedDisplayText, setSelectedDisplayText] = useState<string>(
        props.placeholder || t.common("PRODUCT.SELECT_STORE")
    )

    const { loading } = useRequest(async (options) => {
        const { result: storesResponse } = await storeService.getAllCached(
            {
                pageNumber: 1,
                pageSize: 1000,
            },
            {
                abortController: options.abortController,
            }
        )
        if (storesResponse?.items != null && storesResponse.items.length > 0) {
            const storeOptions = storesResponse.items.map((m) => {
                return {
                    key: m.storeId.toString(),
                    value: m.displayName,
                    displayText: m.displayName,
                } as FormDropdownOption
            })
            setProductStoresOptions(storeOptions)
            if (props.selectedValue !== undefined) {
                const selectedOption = storeOptions.filter((f) => f.key === props.selectedValue)
                if (selectedOption.length !== 0) {
                    setSelectedDisplayText(selectedOption[0].displayText)
                }
            }
        }
    }, [])

    return (
        <div>
            {loading ? (
                <div className="c-loader">Loading...</div>
            ) : (
                <FormDropdown<TForm>
                    {...props}
                    name={props.name}
                    label={t.common("GENERAL.STORE")}
                    key={props.name}
                    type="default"
                    placeholder={selectedDisplayText}
                    selectedValue={props.selectedValue}
                    onUpdate={(option) => props.onUpdate(option)}
                    options={productStoresOptions}
                    required
                />
            )}
        </div>
    )
}

import React, { useMemo } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

interface TopicPriorityDropdownProps {
    placeholder: string
    selectedValue?: string
    category?: string
    onChange: (selected: DropdownOption) => void
    label?: string
    reset?: number
    env?: "portal" | "backoffice"
    width?: string
    fullWidth?: boolean
    isFilter?: boolean
    onReset?: () => void
}

export const TopicPriorityDropdown = (props: TopicPriorityDropdownProps) => {
    const { t } = useTranslate()
    const topicPriorities = lookupService.getTopicPriorityId()

    const topicPriorityOptions = useMemo(() => {
        const lookupEntries = [] as DropdownOption[]
        for (const key in topicPriorities) {
            lookupEntries.push({
                displayText: topicPriorities[key].display,
                key: topicPriorities[key].id.toString(),
                value: topicPriorities[key].id.toString(),
                icon: topicPriorities[key].icon,
            })
        }
        return lookupEntries
    }, [])

    return (
        <Dropdown
            resetStates={props.reset}
            selectedValue={props.selectedValue}
            id="topic-priority"
            type="select"
            size="base"
            label={props.env == "backoffice" ? "Priority" : undefined}
            width={props.width}
            placeholder={props.placeholder}
            onChange={(option) => props.onChange(option)}
            options={topicPriorityOptions}
            fullWidth={props.fullWidth}
            isFilter={props.isFilter}
            resetFilter={props.onReset}
        />
    )
}

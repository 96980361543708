import React from "react"
import { Outlet } from "react-router-dom"

import { NavigationStore } from "@dnr/ui/navigation"
import { localStorageProvider } from "@dnr/util/storage"
import { IAuthenticatedUser } from "@dnr/data/models"

import { LayoutProps } from "./Layout"

export const RouteLayout: React.FC<Omit<LayoutProps, "children">> = () => (
    <Outlet context={new NavigationStore(localStorageProvider.get<IAuthenticatedUser>("user").value)} />
)

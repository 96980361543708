import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react"
import clsx from "clsx"
import { Link } from "react-router-dom"

export type BaseLinkProps = {
    size?: "xsml" | "sml" | "med" | "base" | "lrg"
    width?: "full" | ""
    hasicon?: boolean
    haslabel?: boolean
    icontype?: string
    path: string
    text: string
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const BaseLink: React.FC<BaseLinkProps> = (props) => {
    const { hasicon, haslabel, ...otherProps } = props
    return (
        <div className={`${props.width == "full" ? "u-display--width--full" : ""}`}>
            <Link
                className={`c-btn c-btn--${props.className} c-btn--${props.size} c-btn--${props.width} 
                ${hasicon && haslabel ? "c-btn--icon" : ""} 
                ${hasicon && !haslabel ? `c-btn--icon-only` : ""}
                ${props.icontype ? `c-btn--icon--${props.icontype}` : ""}
                ${props.width == "full" || props.hasicon ? "u-display--flex" : "u-display--unset"} `}
                to={props.path}
            >
                {props.text}
            </Link>
        </div>
    )
}

import { DetailedHTMLProps, InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react"
import { DeepRequired, FieldErrorsImpl, FieldValues, Path, useFormContext } from "react-hook-form"

import { useRequest } from "@dnr/hooks"
import { useTranslate } from "@dnr/localization"
import { lookupService, usersService } from "@dnr/data/services"
import { IUser } from "@dnr/data/models"
import { SearchDropdownInput } from "@dnr/ui/inputs"

import { UserDropdownOption } from "./UserSearchDropdown"

export interface UserSearchFormDropdownProps<TForm>
    extends DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    width?: string
    name: Path<TForm>
    required?: boolean
    className?: string
    placeholder?: string
    label?: string
    extraLabel?: string
    selectedValue?: string
    color?: string
    reset?: number
    onUpdate: (value: UserDropdownOption) => void
    searchRole?: "superAdmin" | "backofficeAdmin" | "dealer" | "runner"
    type?: "select" | "default" | "up"
    env?: "portal" | "backoffice"
    isFilter?: boolean
}

export const UserSearchFormDropdown = <TForm extends FieldValues>(props: UserSearchFormDropdownProps<TForm>) => {
    const { t } = useTranslate()
    const form = useFormContext<TForm>()

    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState("")
    const [selectedKey, setIsSelected] = useState(props.selectedValue)
    const [displayText, setDisplayText] = useState(props.placeholder || t.common("GENERAL.SELECT_USER"))
    const [dropdownOptions, setDropdownOptions] = useState<UserDropdownOption[]>([])

    const handleReset = useCallback(() => {
        setDisplayText(props.placeholder || t.common("GENERAL.SELECT_USER"))
        setIsSelected("")
        setSearchQuery("")
        setDropdownOptions([])
        setLoading(true)
    }, [props.placeholder])

    useEffect(() => {
        if (props.reset) {
            handleReset()
        }
    }, [props.reset])

    const handleChange = (element: UserDropdownOption) => {
        props.onUpdate(element)
        setIsSelected(element.userId)
        setDisplayText(element.displayText)
    }

    const nameFormatter = useCallback((user: IUser) => {
        if (!user) {
            return ""
        }

        return `${user.firstName} ${user.lastName} (${user.email}) CODE: ${user.code}`
    }, [])

    const { error } = useRequest(
        async (options) => {
            setLoading(true)

            const usersResponse = await usersService.find({
                roleId: props.searchRole ? lookupService.rolesByAbrv[props.searchRole].id : undefined,
                pageNumber: 1,
                pageSize: 100,
                query: searchQuery,
            })
            if (usersResponse.items != null) {
                const options = usersResponse.items.map((user) => {
                    return {
                        userId: user.userId,
                        selectedUser: user,
                        displayText: nameFormatter(user),
                    } as UserDropdownOption
                })
                setDropdownOptions(options)
            }

            setLoading(false)
        },
        [searchQuery],
        { debounce: 700 }
    )

    const nameSplitted = useMemo(() => {
        return props.name.split(".")
    }, [props.name])

    const translateErrorMessage = (err?: string) => {
        if (err != null) {
            return t.error(err)
        }

        return err
    }

    const getErrorMessage = useCallback((errors: FieldErrorsImpl<DeepRequired<TForm>>) => {
        if (Object.keys(errors).length === 0) {
            return null
        }

        if (errors[props.name]?.message !== undefined) {
            return translateErrorMessage(errors[props.name]?.message?.toString())
        }
        // In case the error is in the array
        else if (nameSplitted.length > 1) {
            let finalErrorMessage
            let currentObject = undefined as any
            nameSplitted.every((nameSection) => {
                currentObject = currentObject === undefined ? errors[nameSection] : currentObject[nameSection]
                if (currentObject?.message !== undefined) {
                    finalErrorMessage = translateErrorMessage(currentObject?.message.toString())
                    return false
                }
                return true
            })

            if (finalErrorMessage !== undefined) {
                return finalErrorMessage
            }
        }

        return null
    }, [])

    return (
        <SearchDropdownInput
            displayText={displayText}
            options={dropdownOptions}
            errorMessage={getErrorMessage(form.formState.errors)}
            handleChange={handleChange}
            handleReset={handleReset}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            selectedValue={selectedKey}
            selectedKey="userId"
            loading={loading}
            error={error}
            {...props}
            isFilter={props.isFilter ? true : false}
        />
    )
}

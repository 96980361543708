import React, { useMemo } from "react"

import { Dropdown, DropdownOption } from "@dnr/ui/dropdowns"
import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

interface ShippingReqStatusDropdownProps {
    placeholder: string
    selectedValue?: string
    category?: string
    onChange: (selected: DropdownOption) => void
    onReset?: () => void
    label?: string
    reset?: number
    fullWidth?: boolean
    isFilter?: boolean
}
export const ShippingReqStatusDropdown = (props: ShippingReqStatusDropdownProps) => {
    const { t } = useTranslate()
    const shippingStatuses = lookupService.getShippingRequestStatus()

    const shippingStatusOptions = useMemo(() => {
        const lookupEntries = [] as DropdownOption[]
        for (const key in shippingStatuses) {
            lookupEntries.push({
                displayText: shippingStatuses[key].display,
                key: shippingStatuses[key].id.toString(),
                value: shippingStatuses[key].id.toString(),
                icon: shippingStatuses[key].icon,
            })
        }
        return lookupEntries
    }, [])

    return (
        <div>
            {props.label != null ? (
                <div className="u-mb--sml--1">
                    <label className="c-input__label">{t.form(props.label)}</label>
                </div>
            ) : null}
            <Dropdown
                resetStates={props.reset}
                selectedValue={props.selectedValue}
                id="select-shipping-status"
                type="select"
                placeholder={props.placeholder}
                onChange={(option) => props.onChange(option)}
                options={shippingStatusOptions}
                fullWidth={props.fullWidth}
                isFilter={props.isFilter}
                resetFilter={props.onReset}
            />
        </div>
    )
}

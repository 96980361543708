const loggerTypes = ["console", "none"] as const

export type LoggerType = (typeof loggerTypes)[number]

export function isLoggerType(value: unknown): LoggerType {
    const v = loggerTypes.find((valid) => valid === value)

    if (v != null) {
        return v
    }

    throw new Error("Invalid logger type - possible values are " + loggerTypes.join(","))
}

//  --Child Layout - should be used ONLY as a wrapper of pages which pass props to rightAside.
// --If the page doesn't need to pass props to it's aside - don't use this Layout

import React from "react"
import { RightAside } from "./RightAside"

export type RightAsideLayoutProps = {
    rightAside?: React.ReactNode
    children: React.ReactNode
    size?: "base" | "lrg" | "xlrg" | "xxlrg" | "huge"
    isOpenByDefault?: boolean
}

export const RightAsideLayout: React.FC<RightAsideLayoutProps> = (props) => {
    return (
        <>
            <div>{props.children}</div>
            {props.rightAside != null ? <RightAside isOpenByDefault={props.isOpenByDefault? props.isOpenByDefault : false} title="">{props.rightAside}</RightAside> : null}
        </>
    )
}

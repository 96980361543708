import {
    ApiResponseBaseModel,
    ApiResponseModel,
    DefaultSettingForm,
    DefaultSettingModel,
    IFilter,
    IPagedCollection,
    IRequestOptions,
} from "@dnr/data/models"

import { ServiceBase } from "../common-services/ServiceBase"

class DefaultSettingsService extends ServiceBase {
    constructor() {
        super()
    }

    getLoggerName(): string {
        return "DefaultSettingsService"
    }

    async list(
        options?: IRequestOptions,
        query?: IFilter
    ): Promise<ApiResponseModel<IPagedCollection<DefaultSettingModel>>> {
        const response = await this.http.instance.get<ApiResponseModel<IPagedCollection<DefaultSettingModel>>>(
            "/products/default-settings",
            {
                signal: options?.abortController.signal,
                params: query,
            }
        )
        return response.data
    }

    async get(settingId: string, options?: IRequestOptions): Promise<DefaultSettingModel> {
        const response = await this.http.instance.get<DefaultSettingModel>(`/products/default-settings/${settingId}`, {
            signal: options?.abortController.signal,
        })
        return response.data
    }

    async put(settingData: DefaultSettingForm[]): Promise<ApiResponseBaseModel> {
        const response = await this.http.instance.put<ApiResponseBaseModel>("/products/default-settings", settingData)
        return response.data
    }
}

export default new DefaultSettingsService()

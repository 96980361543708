import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes } from "react"

export type TextAreaProps = {
    inputsize?: "sml" | "base" | "med" | "lrg"
    extendClassName?: string
    iserror?: boolean
    color?: string
} & DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
    const { iserror, extendClassName, ...otherProps } = props

    return (
        <textarea
            id={props.name}
            className={`c-input c-input--textarea ${props.color ? `c-input--textarea--${props.color}` : ""} ${
                iserror ? "c-input--error" : ""
            }`}
            cols={30}
            rows={10}
            ref={ref}
            {...otherProps}
        />
    )
})

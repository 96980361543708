import { useTranslate } from "@dnr/localization"

export const UnsubscribeSuccess = () => {
    const { t } = useTranslate()
    return (
        <>
            <img src="../../assets/img/empty-states/notification-confirmed.png" className="c-login__header__img" />
            <div className="c-login__title">
                <h2 className="u-mb--sml--3">{t.common("GENERAL.UNSUBSCRIBED_SUCCESSFULLY")}</h2>
                <p className="c-login__subtext u-mb--sml--6">{t.common("GENERAL.REDIRECTING")}</p>
            </div>
            <span className="c-loader--secondary u-mb--sml--2"></span>{" "}
        </>
    )
}

import { DetailedHTMLProps, InputHTMLAttributes, useMemo } from "react"

import { FieldValues, Path } from "react-hook-form"

import { FormDropdown, FormDropdownOption } from "@dnr/ui/forms"
import { lookupService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"

interface Props<TForm> extends DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    placeholder?: string
    isStatusNew?: boolean
    name: Path<TForm>
    selectedValue?: string
    onUpdate: (value: FormDropdownOption) => void
    disabled?: boolean
}

export const TopicStatusFormDropdown = <TForm extends FieldValues>(props: Props<TForm>) => {
    const topicCategories = lookupService.topicStatusIds
    const { t } = useTranslate()

    const topicCategoryOptions = useMemo(() => {
        const lookupEntries = [] as FormDropdownOption[]
        for (const key in topicCategories) {
            lookupEntries.push({
                displayText: topicCategories[key].display,
                key: topicCategories[key].id.toString(),
                value: topicCategories[key].id.toString(),
                label: topicCategories[key].display,
            })
        }
        return props.isStatusNew ? lookupEntries : lookupEntries.filter((i) => i.displayText !== "New")
    }, [])

    return (
        <FormDropdown<TForm>
            key={props.name}
            disabled={props.disabled}
            label={t.common("GENERAL.STATUS")}
            options={topicCategoryOptions}
            color="light"
            selectedValue={props.selectedValue}
            {...props}
            type="default"
            placeholder={props.placeholder}
            required={props.required}
        />
    )
}

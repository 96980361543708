import { useState } from "react"

import { SnackbarKey, closeSnackbar, enqueueSnackbar } from "notistack"

import { errorHandlingService } from "@dnr/data/services"
import { useTranslate } from "@dnr/localization"
import { defaultLogger } from "@dnr/util/logger"
import { useGlobalLoaderActions } from "@dnr/features/shared/loader"

interface UseSubmitterParams<TData, TResData> {
    /**
     * Callback to invoke
     */
    requestCallback: (data: TData) => Promise<TResData> | null
    /**
     * If present, will show passed success message, if not, default message will be displayed.
     */
    successMsg?: string
    /**
     * If present, will show passed error message, if not, default message will be displayed.
     */
    errMsg?: string
    /**
     * If present, will show passed log error message, if not, default message will be displayed.
     */
    logErrMsg?: string
    /**
     * If present, callback function will invoke on succesfull submission. If function returns true, success notification will not be shown.
     */
    onSuccess?: (response: TResData, data: TData) => any
    /**
     * If present, callback function will invoke on failed submission.
     */
    onError?: (err: any, data: TData) => void | boolean | undefined
    /**
     * If present, callback function will invoke on successful or failed submission. If function returns true, error notification will not be shown.
     */
    finalCallback?: () => void
    /**
     * If present, will show passed loading notification.
     */
    onLoadingNotification?: {
        show: boolean
        message?: string,
    }
}
/**
 * Used for submitting forms and sending requests
 * @param requestCallback Callback to invoke.
 * @param successMsg If present, will show passed success message, if not, default message will be displayed.
 * @param errMsg If present, will show passed error message, if not, default message will be displayed.
 * @param logErrMsg If present, will show passed log error message, if not, default message will be displayed.
 * @param onSuccess If present, callback function will invoke on succesfull submission. If function returns true, success notification will not be shown.
 * @param onError If present, callback function will invoke on failed submission. If function returns true, error notification will not be shown.
 * @param finalCallback If present, callback function will invoke on successful or failed submission.
 * @param onLoadingNotification If present, will show passed loading notification.
 * @returns loading, initialized, response states and onSubmit function
 */
export function useSubmitter<TData, TResData>(params: UseSubmitterParams<TData, TResData>) {
    const { requestCallback, successMsg, errMsg, finalCallback, logErrMsg, onError, onSuccess, onLoadingNotification } = params

    const { t } = useTranslate()
    const [response, setResponse] = useState<TResData>()
    const [initialized, setInitialized] = useState(false)
    const [loading, setLoading] = useState(false)

    const { setLoading: setGlobalLoading } = useGlobalLoaderActions()

    const onSubmit = async (data: TData) => {
        setLoading(true)
        setGlobalLoading(true)
        let loadingNotification: SnackbarKey | undefined

        if (onLoadingNotification) {
            // @ts-ignore: allowed
            loadingNotification = enqueueSnackbar({
                variant: "loading",
                message: onLoadingNotification?.message,
                persist: true
            })
        }

        try {
            const response = await requestCallback(data)

            if (response !== null) {
                setResponse(response)
                let suppressMsg

                if (onSuccess) {
                    const suppress = onSuccess(response, data)
                    suppressMsg = suppress
                }

                if (successMsg && !suppressMsg) enqueueSnackbar({
                    variant: "success",
                    message: successMsg,
                })

            }
        } catch (err) {
            let suppressMsg = false
            if (onError) {
                const suppress = onError(err, data) as boolean
                suppressMsg = suppress
            }

            if (!suppressMsg) {

                const isParsed = errorHandlingService.tryParseException(t.error, err, undefined, errMsg)

                if (!isParsed) {
                    defaultLogger.logError(logErrMsg || "Error on submitting", err)
                    enqueueSnackbar({
                        variant: "error",
                        message: errMsg || t.common("GENERAL.SUBMIT_FAIL"),
                    })
                }
            }
        } finally {
            setLoading(false)
            if (loadingNotification) closeSnackbar(loadingNotification)
            setGlobalLoading(false)
            setInitialized(true)
            if (finalCallback) finalCallback()
        }
    }

    return {
        initialized,
        loading,
        response,
        onSubmit,
    }
}
